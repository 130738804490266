import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import { connect } from 'react-redux';
import { withRouter, Route } from 'react-router-dom';
import Header from "./components/Header";
import Footer from "./components/Footer";
import MainContainer from "./components/MainContainer";
import AdPopup from './components/AdPopup';
import "react-toastify/dist/ReactToastify.css";
//import "./firebase"
import { toast } from "react-toastify";
import { setFormType } from './actions/user-form';
import { setCount } from "./actions/notifications";

import "./css/App.css";
import AutoLogout from "./components/AutoLogout";
import HomeNew from "./components/HomeNew";
//import PushNotifcation from "./PushNotifcation";

// New Footer
// import FooterNew from "./components/FooterNew";

import FooterNew from "./components/FooterNew";

class App extends Component {
  //  This manages the toggling of Userforms for all 3 components
  state = {
    showUserForm: false,
    showAd: false,
  };

  componentDidMount() {

    setTimeout(() => {
      this.setState({ showAd: true });
    }, 3000);
  }



  closeAd = () => this.setState({ showAd: false });

  //  Whenever the user form is opened, this method adjsuts the overflow property of html and body
  adjustBackgroundScrolling = () => {
    const state = this.state.showUserForm ? 'hidden' : 'visible';
    document.querySelector("body").style.overflow = state;
    document.querySelector("html").style.overflow = state;
  }

  //  Opens up the user form, calls the adjustBackgroundScrolling method as a callback for setState
  toggleUserForm = () => this.setState(prevState => ({
    showUserForm: !prevState.showUserForm
  }), this.adjustBackgroundScrolling);

  displayUserForm = () => {

    this.setState({
      showUserForm: true
    }, this.adjustBackgroundScrolling);
  }


  handleFeatureYourself = () => {
    // console.log("call handle")
    if (!this.props.user) {
      toast.info("Please login to feature yourself!");
      this.props.setFormType('login');
      this.setState({ showUserForm: true });
    } else if (!this.props.user.is_approved) {
      toast.error("Please verify your email id to feature yourself");
    } else {
      this.props.history.push('/feature_yourself');
    }
  };

  render() {
    const reload = () => window.location.reload();
    const { location } = this.props;
    const isHomePage = location.pathname === '/';
    // this.props.setCount(localStorage.getItem("count"))
   
    let pathname = location.pathname.split('/');
     
   // segments now contains ["", "home", "about", "contact"]
     
   // You might want to remove the empty string at the beginning
   pathname = pathname.filter(segment => segment !== '');
     
   // segments now contains ["home", "about", "contact"]
     
   console.log(pathname[0]);
    return (

      <div style={{ height: "100%" }}>
        <Route path="/robots.ext" onEnter={reload} />

        {!isHomePage && (
          <Header
            handleFeatureYourself={this.handleFeatureYourself}
            showUserForm={this.state.showUserForm}
            toggleUserForm={this.toggleUserForm}
          />
        )}

        <MainContainer
          handleFeatureYourself={this.handleFeatureYourself}
          toggleUserForm={this.toggleUserForm}
          displayUserForm={this.displayUserForm}
        />
 
       {pathname[0]!== "ai_search" && <FooterNew />}

        <ToastContainer
          position="bottom-right"
          hideProgressBar={false}
          autoClose={4000}
        />
        {/*<PushNotifcation/>*/}
        <AutoLogout />
        {/*  <AdPopup 
          show={this.state.showAd} 
          closeAd={this.closeAd} 
          displayUserForm={this.displayUserForm}
        /> */}

      </div>
    );
  }
}

const mapStateToProps = state => ({ user: state.auth.userInfo });
const mapDispatchToProps = dispatch => ({
  setFormType: formType => dispatch(setFormType({ formType: formType })),
  //setCount : count => dispatch(setCount({count : count}))
});

App = connect(mapStateToProps, mapDispatchToProps)(App);

export default withRouter(App);
