import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { withRouter } from "react-router";

import { setUserInfo } from "../actions/auth";
import { setFormType } from "../actions/user-form";
import { setCount } from "../actions/notifications"

import UserForm from "./UserForm";
import HomeSideDrawer from "./ui/HomeSideDrawer/HomeSideDrawer";
import HeaderTechnologyDrawer from "./ui/HeaderTechnologyDrawer/HeaderTechnologyDrawer";
import TechAreaDropdownNew from "./TechAreaDropdownNew";

import dsciLogo from '../img/dsc-logo/tech-sagar-logo-exploration-72.png';
import dsciLogo2 from '../img/dsc-logo/tech-sagar-logo-exploration-70@3x.png';
import techsagarLogo from '../img/TechsagarLogo.png';
import emailNotIcon from "../img/payment-error.svg";
import vectorImg from "../img/Vector.png";
import signupImg from "../img/signup.svg";
import bellIcon from '../img/notifications.svg';
import "../css/Header.css";
import "../css/HeaderNew.css";
import api from '../api';

import bellIcon2 from '../img/notifications-2.svg';
import MessageIcon from '../img/message.png';
import MessageIcon2 from '../img/email_logo.png';
import menuBar from '../img/menu.png'
import { Box, Badge } from "@material-ui/core";

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

//import firebase from "../firebase"
//import { onMessageListener, requestForToken } from "../firebase";

const generateDateString = dateTimeStamp => {

    const date = new Date(dateTimeStamp);
    const day = date.getDate();

    let month = date.getMonth();

    if (month === 0) month = 'January';
    else if (month === 1) month = 'February';
    else if (month === 2) month = 'March';
    else if (month === 3) month = 'April';
    else if (month === 4) month = 'May';
    else if (month === 5) month = 'June';
    else if (month === 6) month = 'July';
    else if (month === 7) month = 'August';
    else if (month === 8) month = 'September';
    else if (month === 9) month = 'October';
    else if (month === 10) month = 'November';
    else if (month === 11) month = 'December';

    return `${day} ${month.substring(0, 3)}`;
}




class HeaderNew extends Component {
    state = {
        selectedOption: { value: "technologies", label: "Technologies" },
        showNarrowLinks: false,
        transparentNav: false,
        showUserDropDown: false,
        showHomeSideDrawer: false,
        showTechnologyDrawer: false,
        showNotificationsDropdown: false,
        newNotificationsCount: '',
        notifications: [],
        count: 0,
        scrolled: false,
    };





    componentDidMount() {
        // TODO - Get the user notifications count if user is logged in
        if (this.props.user && this.props.user.token) {
            this.getUserNotificationsCount();
            this.getUserNotificationsRecords();
        }

        this.setTransparentNav();
        this.userDropdownRef = React.createRef();
        this.userDropdownContentRef = React.createRef();
        this.notificationsDropdownRef = React.createRef();
        this.notificationsSeeMoreRef = React.createRef();
        document.addEventListener("click", this.userDropdownClicked);
        document.addEventListener('click', this.handleNotificationsDropdownClicked);
        document.addEventListener('mousedown', this.handleSeeMoreNotificationsClicked);
        window.addEventListener("scroll", this.handleScroll);
        this.setTransparentNav();
    }


    // fetches the count of new notifications the user has
    getUserNotificationsCount = () => {
        const requestConfig = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                TCRAUTH: this.props.user.token
            }
        };
        const requestUrl = api.getNotifications + '?request_type=count';

        fetch(requestUrl, requestConfig).then(response => {
            if (!response || !response.ok) throw new Error('Status code: ' + response.status);
            return response.json();
        }).then(response => {
            // console.log('Notifications count response = ', response);
            this.setState({ newNotificationsCount: response.data });    // Store notification count in state
        }).catch(err => {
            console.log('Error fetching notifications count:', err);
        });
    }

    //  fetches only page 1 of the records
    //  when see all is clicked, redirected to activities tab of dashboard
    getUserNotificationsRecords = () => {
        const requestConfig = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                TCRAUTH: this.props.user.token
            }
        };
        const requestUrl = api.getNotifications + '?request_type=records&page=1';

        fetch(requestUrl, requestConfig).then(response => {
            if (!response || !response.ok) throw new Error('Status code: ' + response.status);
            return response.json();
        }).then(response => {
            // console.log('Notifications are = ', response);
            this.setState({ notifications: response.data });
        }).catch(err => {
            console.log('Error fetching notification records', err);
        })
    }


    componentDidUpdate(prevProps, prevState) {
        // console.log(this.props.count,"header count")
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setTransparentNav();
        }
        //console.log(prevProps)
        //console.log(prevState)
        if (prevProps.count != this.props.count) {
            //console.log("call")
            this.setState({ count: this.props.count })
        }
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.userDropdownClicked);
        document.removeEventListener("click", this.handleNotificationsDropdownClicked);
        document.removeEventListener('mousedown', this.handleSeeMoreNotificationsClicked);
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        const scrolled = window.scrollY > 0;
        if (scrolled !== this.state.scrolled) {
            this.setState({ scrolled });
        }
    };

    setTransparentNav = () => {
        if (
            this.props.location.pathname === "/" ||
            this.props.location.pathname === "/about_us" ||
            this.props.location.pathname === "/privacy_policy"
        ) {
            this.setState({ transparentNav: true });
        } else {
            this.setState({ transparentNav: false });
        }
        this.setState({ scrolled: window.scrollY > 0 });
    };

    burgerToggle = () => {
        //console.log("yes")
        this.setState(prevState => ({
            showNarrowLinks: !prevState.showNarrowLinks,
        }));
    };

    userDropdownClicked = event => {
        if (
            this.userDropdownRef.current !== null &&
            this.userDropdownRef.current.contains(event.target)
        ) {
            this.toggleUserDropDown();
        } else {
            if (
                this.userDropdownContentRef.current !== null &&
                !this.userDropdownContentRef.current.contains(event.target)
            ) {
                this.closeUserDropdown();
            }
        }
    };

    handleNotificationsDropdownClicked = event => {
        if (this.notificationsDropdownRef.current) {
            if (this.notificationsDropdownRef.current.contains(event.target)) {
                this.toggleNotificationsDropdown();
                // if (this.notificationsSeeMoreRef.current.contains(event.target)) {
                //   this.props.history.push('/my_dashboard?section=activities');
                // }
            } else {
                this.closeNotificationsDropdown();
            }
        }
    }

    handleSeeMoreNotificationsClicked = event => {
        if (this.notificationsSeeMoreRef.current) {
            if (this.notificationsSeeMoreRef.current.contains(event.target)) {
                this.viewAllNotifications();
            }
        }
    }

    toggleUserDropDown = () => this.setState(prevState => ({
        showUserDropDown: !prevState.showUserDropDown
    }));

    toggleModal = () => this.props.toggleUserForm();
    closeUserDropdown = () => this.setState({ showUserDropDown: false });

    toggleUserForm = userFormType => {
        this.props.setFormType(userFormType);
        this.toggleModal();
    };


    handleMobileLoginLink = () => {
        this.toggleUserForm();
        this.burgerToggle();
    };

    // Clear user data
    userLogout = () => {
        const requestPostConfig = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                TCRAUTH: this.props.user.token
            }
        };

        fetch(api.consumerLogout, requestPostConfig).then(response => {
            if (!response || !response.ok) throw new Error('Status code: ' + response.status);
            return response.json();
        }).then(response => {
            localStorage.removeItem("user");

            this.props.setUserInfo(null);
            this.setState(prevState => ({
                showUserDropDown: !prevState.showUserDropDown,
                showHomeSideDrawer: false,
            }));
            toast.success("You're Logged Out!", { autoClose: 1800 });
            this.props.history.push('/');
            //window.setTimeout(function () { window.location.reload() }, 1900)
        }).catch(err => {
            console.log('Error fetching notification records', err);
        })
    };

    closeHomeSideDrawerHandler = () => {
        this.setState({ showHomeSideDrawer: false });
    };
    toggleHomeSideDrawer = () => {
        this.setState(prevState => ({
            showHomeSideDrawer: !prevState.showHomeSideDrawer,
        }));
    };

    toggleTechnologyDrawer = () => {
        this.setState(prevState => ({
            showTechnologyDrawer: !prevState.showTechnologyDrawer,
        }));
    };

    closeTechnologyDrawer = () => {
        this.setState({ showTechnologyDrawer: false });
    };


    //  NOTIFICATIONS ICON DROPDOWN TOGGLE AND CLOSE
    closeNotificationsDropdown = () => this.setState({
        showNotificationsDropdown: false
    });
    toggleNotificationsDropdown = () => this.setState(prevState => ({
        showNotificationsDropdown: !prevState.showNotificationsDropdown
    }));

    viewAllNotifications = () => {
        this.setState({ showNotificationsDropdown: false });
        this.props.history.push('/my_dashboard/activities');
    }


    render() {
        const { scrolled } = this.state;
        //console.log(this.props.user)
        // console.log("Header render() called...");
        // const headerBackground = this.state.transparentNav
        //   ? "" //"rgba(0, 0, 0, 0.1)"
        //   : "";


        //console.log(this.props.count)
        // const headerStyles = {
        //     backgroundColor: this.state.transparentNav ? '#fff' : '',
        //     color: this.props.location.pathname === '/' ? '#283554' : '',
        //     backgroundImage: this.props.location.pathname === '/' ? 'none' : ''
        // };

        // const headerHeight =
        //   this.props.location.pathname === "/" ? "6.5rem" : "";

        const headerSelectClasses = [
            "header__section__part",
            "header__section__part--select",
        ];

        headerSelectClasses.push(
            this.state.transparentNav ? "header__section--select-home" : ""
        );

        const headerLogo = this.props.location.pathname === '/' ? techsagarLogo : dsciLogo;
        const bellIconLogo = this.props.location.pathname === '/' ? bellIcon2 : bellIcon;
        const MessageIconLogo = this.props.location.pathname === '/' ? MessageIcon2 : MessageIcon;

        const shouldRender = ![
            '/verified_feature/cba9b510e5b467fa185808ecd2481057',
            '/feature_yourself'
        ].includes(this.props.location.pathname);

        return (

            shouldRender && (
                <Fragment>
                    <UserForm
                        show={this.props.showUserForm}
                        toggle={this.toggleModal}
                    />
                    <HomeSideDrawer
                        {...this.props}
                        user={this.props.user} // Coming from mapStateToProps
                        userLogout={this.userLogout}
                        show={this.state.showHomeSideDrawer}
                        toggleUserDropDown={this.toggleUserDropDown}
                        toggleUserForm={formType => this.toggleUserForm(formType)}
                        closeHomeSideDrawer={this.closeHomeSideDrawerHandler}
                    />
                    <HeaderTechnologyDrawer
                        show={this.state.showTechnologyDrawer}
                        closeTechnologiesDrawer={this.closeTechnologyDrawer}
                    />
                    {this.props.user && !this.props.user.is_approved ? (
                        <div className="email-verify-prompt">
                            <img
                                src={emailNotIcon}
                                className="email-verify-prompt__img"
                                alt="Email icon"
                            />&nbsp;

                            <span className="email-verify-prompt__heading"
                            >Verify Email</span>
                            &nbsp;&nbsp;&nbsp;

                            <span className="email-verify-prompt__text"
                            >Hi {this.props.user.first_name}, please verify
                                your email to continue using the portal</span>
                        </div>
                    ) : null}

                    <div
                        className={`headerTrans ${scrolled ? 'scrolled' : ''}`}
                    >
                        <div className="headerLeft">

                            <div
                                className="home--technology-drawer-btn"
                                onClick={this.toggleTechnologyDrawer}
                            >Technologies
                            </div>
                            <TechAreaDropdownNew />

                            <Link
                                rel="canonical"
                                to="/resources"
                                className="header__reports-buttonss"

                            >Resources
                            </Link>


                            <Link
                                rel="canonical"
                                to="/about_techsagar"
                                className="header__reports-buttonss"

                            >About
                            </Link>

                            
                            <Link
                                rel="canonical"
                                to="/blogs"
                                className="header__reports-buttonss"

                            >Blogs
                            </Link>

                        </div>
                        <div className="headerMiddle">
                            {/* <img src={menuBar} width="0rem" className="header_menu-bar" onClick={this.toggleHomeSideDrawer} /> */}
                            <i className="fa fa-bars header_menu-bar" onClick={this.toggleHomeSideDrawer} />
                            <Link
                                to={"/"}
                                className="header__section__part header__section__partss header__section__part--dsci-logo"

                            >
                                <img
                                    src={headerLogo}
                                    alt="DSCI Logo"
                                    className="headerLogo"
                                    style={{}}
                                />
                            </Link>
                        </div>

                        {this.props.user ? (
                            <div className="header__user navWide">

                                <div
                                    onClick={this.props.handleFeatureYourself}
                                    className="header__section__part header__section__btn"
                                >Feature Yourself</div>

                                <Link
                                    to='/dashboard'
                                    className="header__section__part header__section__btn"
                                >Dashboard</Link>

                                <div
                                    ref={this.userDropdownRef}
                                    className="header__user-text"
                                >
                                    {this.props.user.first_name}&nbsp;
                                    {this.props.user.last_name}
                                    <i
                                        style={{ marginLeft: "1rem" }}
                                        className="fas fa-caret-down"
                                    />
                                </div>

                                {this.state.showUserDropDown && (
                                    <div
                                        className="header__dropmenu-wrapper"
                                        ref={this.userDropdownContentRef}
                                    >
                                        <span className="header__dropmenu">
                                            <div className="header__mobile-text">
                                                {this.props.user.first_name}
                                            </div>
                                            <ul className="header__dropdown-ul">
                                                <li
                                                    className="header__dropdown-li"
                                                    onClick={() => {
                                                        this.toggleUserDropDown();
                                                        this.props.history.push('/my_account');
                                                    }}
                                                >
                                                    <span className="header__dropdown-text"
                                                    >My Account</span>
                                                </li>

                                                <li
                                                    className="header__dropdown-li"
                                                    onClick={this.userLogout}
                                                >
                                                    <span className="header__dropdown-text"
                                                    >Log Out</span>
                                                </li>
                                            </ul>
                                        </span>
                                    </div>
                                )}

                                <div className='header__bell-wrapper' ref={this.notificationsDropdownRef}>



                                </div>
                                {/* 
                                <img src={bellIcon2} className='header__bell-icon' />
                                <img src={MessageIconLogo} className="header_message_img" alt="Message icon" /> */}


                            </div>
                        ) : (
                            <Fragment>
                                <div className="header__section header__sectionss navWide">
                                    <Link
                                    to={"ai_search"}
                                    className="header__section__part header__section__btn">
                                    AI Search
                                    </Link>

                                <Link
                                to={"claim"}
                                className="header__section__part header__section__btn"
                                title="Claim Your Company Profile">
                                    Claim
                                </Link>

                                    <div
                                        style={{ fontSize: '1.5rem', fontWeight: '500', cursor: 'pointer' }}
                                        onClick={this.toggleUserForm.bind(null, "login")}
                                    >
                                        <img
                                            src={vectorImg}
                                            style={{ fontSize: '1.8rem', fontWeight: '500' }}
                                            alt="Person icon"
                                        />&nbsp;
                                        Login&nbsp;&nbsp;&nbsp;</div>
                                    <div
                                        style={{ fontSize: '1.5rem', fontWeight: '500', cursor: 'pointer' }}
                                        onClick={this.toggleUserForm.bind(null, "signup")}
                                    >
                                        <img
                                            src={signupImg}
                                            style={{ height: '2.2rem', filter: 'invert(100%)', }}
                                            alt="Person icon"
                                        />&nbsp;Sign-up</div>
                                </div>

                                <div className="navNarrow">
                                    {this.state.showNarrowLinks && (
                                        <div className="narrowLinks">
                                            <Link
                                                to="#"
                                                className="header__section__part header__section__btn"
                                                onClick={this.handleMobileLoginLink}
                                            >Login</Link>


                                            <Link

                                                className="header__section__part header__section__btn--bordered"
                                                to={"/feature_yourself"}
                                                onClick={this.burgerToggle}
                                            >Feature Yourself</Link>


                                        </div>
                                    )}

                                </div>
                            </Fragment>
                        )}
                    </div>
                </Fragment>
            )
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.userInfo,
    //count : state.NotifcationCount["count"]
});

const mapDispatchToProps = dispatch => ({
    setUserInfo: userInfo => dispatch(setUserInfo({ userInfo: userInfo })),
    setFormType: formType => dispatch(setFormType({ formType: formType })),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(HeaderNew)
);

