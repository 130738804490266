 

import "../css/ProfileUpload.css"
import profile from '.././img/user.png';
import camera from '.././img/camera_icon.png'
 

import React, { Component } from 'react';

class ProfileUpload extends Component {
  
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }
  state ={
    file: null,
    base64URL: "",
    fileName: "",
}
getBase64 = file => {
  // console.log(file)
   return new Promise(resolve => {
     let fileInfo;
     let baseURL = "";
     // Make new FileReader
     let reader = new FileReader();

     // Convert the file to base64 text
     reader.readAsDataURL(file);

     // on reader load somthing...
     reader.onload = () => {
       // Make a fileInfo Object
       //console.log("Called", reader);
       baseURL = reader.result;
     //  console.log(baseURL);
       resolve(baseURL);
     };
    // console.log(fileInfo);
   });
 };

 
  handleChange=(e)=> {
  // const { input: {  } } = this.props
   let { file } = this.state;
   //console.log(e.target.files[0])
   file = e.target.files[0];
   
   this.getBase64(file)
     .then(result => {
       file["base64"] = result;
     // console.log("File Is", file);
    
    this.props.handleChange(file)
     })
     .catch(err => {
       console.log(err);
     });
     
 };

 
  render() {
    
    return (
      <div className={`Profile__wrapper ${this.props.showModal ? 'Profile__wrapper--shown' : ''}`}>
      <div className='Profile__background--hightlight' />
      <div className={`Profile ${this.props.showModal ? 'Profile--shown' : ''}`}>
          

          <div className='Profile__body'>
            <div className='profile_parent'>
              {this.props.profileImage.length >0 ?
          <img src={this.props.profileImage}  className='profile__image' alt="Submit"/>:    
          <img src={profile}  className='profile__img' alt="Submit"/>}
          <label for="file-input">
          <img src={camera} className='profile__icon'/>
          </label>
          <input id="file-input" type="file" onChange={this.handleChange}  accept="image/*"/>
          </div> 
              <div style={{ display: 'flex', alignContent: 'center', 
              justifyContent: 'flex-end',padding:'0.7rem',position:'absolute',bottom:'2rem',right:'10%' }}>
                  <button onClick={this.props.handleClose}
                      type='button' className='cancel-button' style={{ marginRight: 8 }}>
                      Cancel</button>
                  <button onClick={this.props.onSubmit}
                      type='button' className='process-button'  >
                      Upload</button>
              </div>
          </div>
      </div>
  </div>
    );
  }
}

export default ProfileUpload;