import React from "react";
import Modal from "./ui/modal/Modal";
import { setFormType } from '../actions/user-form';

import api from '../api';
import "../css/Reports.css";
import UnlockDocSvg from '../inline-svg-components/UnlockDocSvg';

import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getFormattedDateTime } from '../components/helper-functions/helper'
import "../css/Whitepapers.css";
import DOMPurify from 'dompurify';

class Reports extends React.Component {
  // TODO -- Determine user's premium status and render pages differently
  state = {
    isPremiumUser: false,
    reports: null,
    showModal: false,
    currentCredits: 0,
    reportCost: 10,
  };

  closeModal = () => this.setState({ showModal: false });
  showModal = () => {
    //  User not logged in (see this.getCredits())
    if (this.state.currentCredits === -10) {
      toast.error('You must be logged in to view reports');
      return;
    }

    if (this.state.currentCredits !== -1 && this.state.reportCost > this.state.currentCredits) {
      toast.error('You do not have enough credits to view this report');
      return;
    }

    this.setState({ showModal: true });
  }

  toggleModal = () =>
    this.setState(prevState => ({
      showModal: !prevState.showModal,
    }));

  componentDidMount() {
    this.getCurrentCredits();
    this.fetchReports();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userInfo !== this.props.userInfo) {
      this.getCurrentCredits();
      this.fetchReports();
    }
  }

  getCurrentCredits = () => {
    //  if user is not logged in, set current credits to -10
    if (!this.props.userInfo) {
      this.setState({ currentCredits: -10 });
      return;
    }

    // TODO - use loader in the component
    // this.setState({ isLoadingData: true });
    const requestConfig = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'TCRAUTH': this.props.userInfo.token
      }
    };

    fetch(api.getCredits, requestConfig).then(response => {
      if (response && response.ok) return response.json();
      throw new Error('Status code: ' + response.status);
    }).then(response => {
      this.setState({
        currentCredits: response.data.credits_remaining,
        // isLoadingData: false, 
      })
    }).catch(err => {
      console.log('Error fetching current credits', err);
    })
  }

  fetchReports = () => {
    const requestConfig = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Auth-Key': process.env.REACT_APP_AUTH_KEY
      }
    };

    // If user is logged in, also send the TCRAUTH token
    if (this.props.userInfo && this.props.userInfo.token) {
      requestConfig.headers['TCRAUTH'] = this.props.userInfo.token;
    }

    fetch(api.studyReports, requestConfig)
      .then(response => {
        if (!response || !response.ok) throw new Error(response.status);
        return response.json();
      })
      .then(data => {
         //console.log(data.data)
        this.setState({ reports: data.data });
      })
      .catch(err => {
        console.log('error fetching reports', err);
      });
  }

 
  // TODO --  Handle report clicks (if user is premium), else we only display modals

  reportClickHandler = e => {
    if (!this.props.userInfo) {
      e.preventDefault();
      toast.error('You must be logged in to view reports');
      this.props.setFormType('login');
      this.props.toggleUserForm();
      return;
    }
  };

  truncateString(myString) {
    //console.log(myString.length)
    if (myString.length > 400) {
      var myTruncatedString = myString.substring(0, 400);
      return myTruncatedString + "..."
    }
    else {
      return myString
    }
  }

  downloadPdf = (url, pdfName) => {
    console.log(url,pdfName)
    fetch(url).then((response) => {
      response.blob().then((blob) => {
       
          // Creating new object of PDF file
          const fileURL =
              window.URL.createObjectURL(blob);
               
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = pdfName;
          alink.click();
      });
  });
  };
  render() {
    let reports = null;
    let reportsCountDisplay = '';

    document.title = "Reports - Technology Capability Repository || DSCI";    //  create the report tiles
    if (this.state.reports) {
       
      const reportBgGradient = `linear-gradient(343deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.7))`;
      //console.log(this.state.reports,'CHECK 43253253')
      reports = this.state.reports.map((report, index) => {
        // console.log(report)
        const reportBgImage = `url(${report.thumbnail})`;
        const bgString = `${reportBgGradient}, ${reportBgImage}`;
        let downloadUrl = report.link;

        // #### user not logged In ####
        // if (!this.props.userInfo) downloadUrl = '#';
 //console.log(report)
        return (
          <div key={report.thumbnail} className="whitepaper">
            <div className="whitepaper__image-wrapper">
              <img
                src={report.thumbnail}
                alt="Whitepaper Thumbnail"
                className="whitepaper__image"
              />
            </div>

            <div className="whitepaper__content">
              <p className="whitepaper__title">{report.title}</p>
              {report.published_date ?
                 <p className="whitepaper__date">{report.published_date}</p> : <></>}

              <div className="whitepaper_des">
                {report.body &&
                   <div
                   className="whitepaper__description"
                   dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(report.body) }}
                 />}
              </div>
              <p
            className="whitepaper__download"
            onClick={(e) => {
             // handleClick(e);
              this.downloadPdf(downloadUrl, report.title);
            }}
          
          >Download</p>
            </div>
          </div>
        );
      });

      // report count display
      reportsCountDisplay = `(${this.state.reports.length})`;
    }

    return (
      <div>
        {/* <Modal
          show={this.state.showModal}
          toggleModal={this.toggleModal}
          width={600}
          height="550px"
        >
          <div className="reports__go-premium-modal">
            <h2 className="reports__go-premium-modal-heading"
            >Unlock This Report</h2>

            <div className="reports__go-premium-modal-image-wrapper">
              <UnlockDocSvg className='unlockDoc' />
            </div>

            <p className="reports__go-premium-modal-text"
            >{this.state.reportCost} Credits</p>

            <p className="reports__go-premium-credit-balance"
            >Total Credit Balance is {this.state.currentCredits} Credits</p>

            <button
              className="reports__go-premium-modal--cta-button"
            >UNLOCK</button>
          </div>
    </Modal>*/}

        <div className="entityview__top">
          <div className="entityview__top-desc-wrapper">
            <div className="entityview__top-breadcrumb"
            >
              {/*<nav aria-label="Breadcrumb" class="breadcrumb">
            <ul>
            <li><a href="/">Home</a></li>
              <li><a href ="/resources">Resources</a></li>
              <li><span aria-current="page">Reports</span></li>
            </ul>
  </nav>*/}</div>

            <div>
              {/*<p className="entityview__top-head--reports"
              >Reports {reportsCountDisplay}</p>*/}
              <p className="resouces__heading"
              >Reports</p>
            </div>
          </div>
        </div>
        {/* End of entityview__top */}

        <div className="whitepapers-wrapper">
          {reports}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.auth.userInfo
});

const mapDispatchToProps = dispatch => ({
  setFormType: formType => dispatch(setFormType({ formType: formType }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
