import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { toast } from "react-toastify";

import api from "../api";
import { setFormType } from '../actions/user-form';
// import UseCreditsDialog from "./ui/useCreditsDialog/UseCreditsDialog";
import AcademiaWrapper from "./entityview-components/AcademiaWrapper";


import IndividualWrapper from "./entityview-components/IndividualWrapper";
import Researcher from "./Researchers/profile/researcher_ui";


import RnDWrapper from "./entityview-components/RnDWrapper";
import CompanyWrapper from "./entityview-components/CompanyWrapper";
import Loader from "./ui/loader/Loader";
import Modal from "./ui/modal/Modal";
import ShareLink from "./ShareLink";
import UnlockDocSvg from '../inline-svg-components/UnlockDocSvg';
// import PersonAddIcon from '@mui/icons-material/PersonAdd';



const RenderEntity = ({
  entityDetails,
  path,
  liked,
  similarEntities,
  researchersList,
  toggleModal,
  actionOnEntity,
  followButton,
  value,
  onValueChange,
}) => {

  switch (entityDetails.type) {
    case "Academia":
      return (
        <AcademiaWrapper
          path={path}
          researchersList={researchersList}
          details={entityDetails}
          similarEntities={similarEntities}
          toggleModal={toggleModal}
          actionOnEntity={actionOnEntity}
          followButton={followButton}
        />
      );

    case "Individual Researcher":
      return (
        <IndividualWrapper
          path={path}
          details={entityDetails}
          similarEntities={similarEntities}
          toggleModal={toggleModal}
          actionOnEntity={actionOnEntity}
          followButton={followButton}
        />
      );

    case "R & D Centres":
      return (
        <RnDWrapper
          path={path}
          details={entityDetails}
          similarEntities={similarEntities}
          toggleModal={toggleModal}
          actionOnEntity={actionOnEntity}
          followButton={followButton}
        />
      );

    default:
      return (
        <CompanyWrapper
          path={path}
          details={entityDetails}
          similarEntities={similarEntities}
          toggleModal={toggleModal}
          actionOnEntity={actionOnEntity}
          followButton={followButton}
          value={value}            // Pass value to wrapped components if needed
          onValueChange={onValueChange}  // Pass function to wrapped components if needed
        />
      );
  }
};

class EntityView extends Component {
  state = {
    entityType: "",
    entityDetails: {},
    similarEntities: [],
    researchersList: [],
    liked: false,
    loading: true,// loading: false -> will changed after creadits available
    showShareModal: false,
    showLoadModal: false,
    loadDialogResponse: null,
    costToView: 0,
    currentCredits: 0,
    path: []
  };

  toggleModal = () => this.setState(prevState => ({
    showShareModal: !prevState.showShareModal,
  }));

  rejectUseCredits = () => this.setState({
    showLoadModal: false,
    loadDialogResponse: 'reject'
  });

  toggleLoadModal = () => this.setState(prevState => ({
    showLoadModal: !prevState.showLoadModal
  }));

  toggleLikedStatus = () => this.setState(prevState => ({
    liked: !prevState.liked
  }));


  actionOnEntity = (actionType, entityId) => {
    if (!this.props.user) {
      toast.error("You must login first to follow");
      return;
    }

    //  Request configuration objects
    const requestBody = {
      type: actionType,
      form_id: entityId,
    };
    const requestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        TCRAUTH: this.props.user.token
      },
      body: JSON.stringify(requestBody)
    };

    //  Call the API to perforrm action (like/unlike)
    fetch(api.consumer_action_on_entity, requestConfig)
      .then(response => {
        if (!response || !response.ok) {
          // console.log('Could not like the entity', response.status);
          toast.error('Error trying to ', actionType);
          return;
        }

        response.json().then(response => {
          if (response.success) this.toggleLikedStatus();
        });
      })
      .catch(err => {
        console.log("An error occurred, ", err);
      });
  };

  //  CHECK IF THIS IS NEEDED. IF IT IS, IT MUST BE UPDATED TO USE THE ASYNC ComponentDidMount()
  // componentDidUpdate(prevProps, prevState) {
  //     // re render on receiving new query params
  //     if (prevProps.match.params.id !== this.props.match.params.id) {
  //         this.getEntityDetails(this.props.match.params.id);
  //     }

  //     // if (prevState.loginStatus !== this.props.loginStatus) {
  //     //     this.setState({
  //     //         loginStatus: this.props.loginStatus,
  //     //         user: JSON.parse(localStorage.getItem("user")),
  //     //     });
  //     //     this.getCompanyDetails(this.props.match.params);
  //     // }
  // }

  async componentDidMount() {
    // if user isn't logged in, show a warning toast and redirect to home page


    // if (!this.props.user || !this.props.user.token) {
    //   toast.warning('Please login first');
    //   this.props.history.push('/');
    //   return;
    // }

    // fetch the entity credit metrics and the user's current credits
    // await this.getEntityCreditMetrics();
    // await this.getCurrentCredits();
    //await this.fetchEntityData();
    this.fetchEntityData()
  }

  getEntityCreditMetrics = async () => {
    
    var pathLocation = this.props.location.pathname.split("/")
    var entityId = pathLocation[pathLocation.length - 1].replaceAll("_", " ")

    const url = `${api.getEntityCreditMetrics}?name=${entityId}`;

    fetch(url, {
      method: 'GET',
      headers: {
        TCRAUTH: this.props.user.token,
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response && response.ok) return response.json();
      throw new Error('Status code: ' + response.status);
    }).then(response => {
      if (response.data.value) {
        this.setState({
          showLoadModal: true,
          costToView: response.data.value
        });
      } else {
        this.setState({ loadDialogResponse: 'confirm' });
      }
    }).catch(err => {
      console.log('Error fetching entity credit metrics', err);
    });
  }


  getCurrentCredits = async () => {
    fetch(api.getCredits, {
      method: 'GET',
      headers: {
        TCRAUTH: this.props.user.token,
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response && response.ok) return response.json();
      throw new Error('Status code: ' + response.status);
    }).then(response => {
      this.setState({
        currentCredits: response.data.credits_remaining,
      })
    }).catch(err => {
      console.log('Error fetching current credits', err);
    });
  }

  // componentDidMount() {
  //   if (!this.props.user || !this.props.user.token) {
  //     toast.info('Please login first');
  //     this.props.history.push('/');
  //     return;
  //   }

  //   const requestConfig = {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       TCRAUTH: this.props.user.token
  //     }
  //   };


  //   //  Get the credit metrics from API here
  //   const entityId = this.props.match.params.id;
  //   const url = `${api.getEntityCreditMetrics}?id=${entityId}`;
  //   fetch(url, requestConfig).then(response => {
  //     if (!response || !response.ok) {
  //       console.log('Could not fetch credit metrics');
  //       return;
  //     }

  //     response.json().then(response => {
  //        
  //       if (response.data.value) {
  //         this.setState({ 
  //           showLoadModal: true,
  //           costToView: response.data.value 
  //         });
  //       } else {
  //         this.setState({ loadDialogResponse: 'confirm' });
  //       }
  //     })
  //   }).catch(err => console.log('Error fetching entity credit metrics', err));
  // }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.loadDialogResponse === 'confirm' &&
      this.state.loadDialogResponse !== prevState.loadDialogResponse) {
      this.setState({ loading: true }, this.fetchEntityData);
    }
  }

  fetchEntityData = async () => {
    //  Get entity details

    try {
      var pathLocation = this.props.location.pathname.split("/")

      var entityId = pathLocation[pathLocation.length - 1].replaceAll("_", " ")


      const { entityDetails, similarEntities } = await this.getEntityDetails(entityId);
      // console.log(entityDetails)
      //  Get researchers list
      const { researchersList } = await this.getResearchersList(entityDetails.name);

      //  Determine liked status
      const liked = this.props.user && entityDetails.liked_by ?
        entityDetails.liked_by.some(userObj => {
          return userObj.username === this.props.user.email
        }) : false;

      //  Set state with the retrieved data
      this.setState({
        liked: liked,
        entityDetails: entityDetails,
        similarEntities: similarEntities,
        researchersList: researchersList,
        loading: false,
        path: this.props.location.pathname.split("/")
      });
      // console.log(this.state.researchersList)
    } catch (err) {
      console.log('An error occurred while fetching Entity data', err);
    }
  }


  getEntityDetails = async entityId => {
    //  Request configuration objects

    const requestBody = {
      name: entityId,
      area: this.props.area,
      capability: this.props.capability,
      limit: 12
      // searchQuery: this.props.searchQuery,
    };
    const requestConfig = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // TCRAUTH: this.props.user.token
      },
      body: JSON.stringify(requestBody)
    };

    try {
      let response = await fetch(api.template_detail, requestConfig);
      //  Handle case for use not having enough credits
      if (response.status === 403) {
        toast.info('Insufficient credits to access this page');
        this.props.history.push('/payments');
      }

      if (!response || !response.ok) {

        return { entityDetails: {}, similarEntities: {} };
      }

      //  Proceed for successful response
      response = await response.json();
      //console.log(response.data.entity_details)
      return {
        entityDetails: response.data.entity_details,
        similarEntities: response.data.similar_entities
      };

    } catch (err) {
      //  In case of error, log to console and return empty arra
      console.log('An error occurred while fetching entity details', err);
      return { entityDetails: {}, similarEntities: {} };
    }
  }

  getResearchersList = async academiaName => {
    const url = `${api.academia_researchers_list}?name=${academiaName}`;
    const requestConfig = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        //  TCRAUTH: this.props.user.token
      }
    };

    try {
      let response = await fetch(url, requestConfig);
      if (!response || !response.ok) {
        return { researchersList: [] };
      }

      response = await response.json();
      return { researchersList: response.data };
    } catch (err) {
      //console.log('An error occurred while fetching researchers');
      return { researchersList: [] };
    }
  }

  createFollowButton = () => {
    return (<>
      {
        
        <div
          className="entityview__subhead-btn"
          style={{ backgroundColor: "#4c96ff" }}
          onClick={() =>
            this.actionOnEntity("like", this.state.entityDetails.id)
          }
        >
          {this.state.liked ? (
            <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
              <i className="fas fa-user-minus" />
               Unfollow
            </div>
          ) : (
            <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
              <i className="fas fa-user-plus" />
               Follow
            </div>
          )}
        </div>
      }
    </>
    );
  };

  acceptUserResponse = response => {
    this.setState({
      showLoadModal: false,
      loadDialogResponse: response,
    });
  }

  render() {
    const followButton = this.createFollowButton();
    let shareModal = null;
    let loadModal = null;
    const { value } = this.props;
    console.log(this.props)

    //console.log(this.props.location.pathname)

    //  Show share modal if showShareModal is set in state

    //console.log(this.state.researchersList)
    if (this.state.showShareModal) {
      // console.log(this.state.showShareModal, 'CHECK 2');
      shareModal = (
        <Modal
          show={this.state.showShareModal}
          toggleModal={this.toggleModal}
        >
          <ShareLink
            url={`${process.env.REACT_APP_CONSUMER_LINK}` + this.props.location.pathname}
          />
        </Modal>
      );
    }

    //  If loadDialogResponse isn't available, it means modal hasn't openened
    //  and the user hasn't responded whether they want to use credits or not
    //  Hence, we show the confirmation dialog modal
    if (!this.state.loadDialogResponse) {
    

      let modalCreditsMessage = `Total Credit Balance is ${this.state.currentCredits} Credits`;
      if (this.state.currentCredits === -1) {
        modalCreditsMessage = 'Free for you, you are an Unlimited User!';
      }

      loadModal = (
        <Modal
          show={this.state.showLoadModal}
          toggleModal={this.rejectUseCredits}
          width={600}
          height="550px"
        >
          <div className="reports__go-premium-modal">
            <p className="reports__go-premium-modal-heading"
            >Unlock Entity</p>

            <div className="reports__go-premium-modal-image-wrapper">
              <UnlockDocSvg className='unlockDoc' />
            </div>

            <p className="reports__go-premium-modal-text"
            >{this.state.costToView} Credits</p>

            <p className="reports__go-premium-credit-balance"
            >{modalCreditsMessage}</p>

            <button
              onClick={this.acceptUserResponse.bind(this, 'confirm')}
              className="reports__go-premium-modal--cta-button"
            >UNLOCK</button>
          </div>
        </Modal>
      );
    }

    //  If user rejects the response, redirect back to home
    if (this.state.loadDialogResponse === 'reject') {

      toast.info('No credits deducted');
      return <Redirect to='/' />;
    }

    return (
      
      <Fragment>
        {/* {loadModal} */}

        <Loader loading={this.state.loading} height="400px">
          {shareModal}
          <RenderEntity
            path={this.state.path}
            researchersList={this.state.researchersList}
            entityDetails={this.state.entityDetails}
            similarEntities={this.state.similarEntities}
            toggleModal={this.toggleModal}
            liked={this.state.liked}
            actionOnEntity={this.actionOnEntity}
            followButton={followButton}
            value={this.props.value}
            onValueChange={this.props.onValueChange}
          />
        </Loader>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.userInfo,
  area: state.search.area,
  capability: state.search.capability,
  // searchQuery: state.headerSearch.searchQuery,
});

const mapDispatchToProps = dispatch => ({
  setFormType: formType => dispatch(setFormType({ formType: formType })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntityView);

