import React, { Fragment } from "react";
import IdleTimer from 'react-idle-timer';
import { toast } from "react-toastify";

import IdleTimeOutModal from '../modal/IdleTimeOutModal'
import api from '../api';

export default class IdleTimeOutComponent extends React.Component{
  constructor(props) {
    super(props)

    this.state = {
      timeout: 1000 * 15 * 60,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false
    }
//console.log(this.state.timeout,"idle")
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  }

  _onAction(e) {
    // console.log('user did something', e)
    this.setState({ isTimedOut: false })
  }

  _onActive(e) {
    //  console.log('user is active', e)
    this.setState({ isTimedOut: false })
  }

  _onIdle(e) {
    // console.log('user is idle', e)
    if (this.props.user) {
      const isTimedOut = this.state.isTimedOut
      if (isTimedOut) {
        this.handleLogout()
      } else {
        window.scrollTo(0,0)
        this.setState({ showModal: true })
        document.querySelector("body").style.overflow='hidden'
        document.querySelector("html").style.overflow='hidden'
        this.idleTimer.reset();
        this.setState({ isTimedOut: true })
      }
    }
  }

  handleClose() {
    this.setState({ showModal: false })
  }

  handleLogout() {
    const requestPostConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        TCRAUTH: this.props.user.token
      }
    };

    fetch(api.consumerLogout, requestPostConfig).then(response => {
      if (!response || !response.ok) throw new Error('Status code: ' + response.status);
      return response.json();
    }).then(response => {
      this.setState({ showModal: false })
      localStorage.removeItem("user");
      this.props.setUserInfo(null);
      this.props.history.push('/')
      window.location.reload();
      toast.success("You're Logged Out!");
    }).catch(err => {
      console.log('Error fetching notification records', err);
    })

  }

  render(){
    return(
      <>
      <IdleTimer
          ref={ref => { this.idleTimer = ref }}
         // element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout} />

          <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
        />

      </>
    )
  }
}