import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import Brief from '../../src/img/BriefAbout.png'
import HomeTop from "./HomeTop";
import LazyLoad from 'react-lazy-load';
import "../css/HomeNew.css";
import { Link } from "react-router-dom";
import DOMPurify from 'dompurify';

import api from '../api';

const ResourcesSection = () => {

    const [activeTab, setActiveTab] = useState('Reports');
    const [visibleItemsCount, setVisibleItemsCount] = useState(3);
    const [reportsArray, setReportsArray] = useState([]);
    const [whitepapersArray, setWhitepapersArray] = useState([]);
    const [caseStudyArray, setCaseStudyArray] = useState([]);
    const [reportsCount, setReportsCount] = useState(0);
    const [whitepaperCount,setWhitepaperCount] = useState(0);

    const downloadPdf = (url, pdfName) => {
        fetch(url).then((response) => {
          response.blob().then((blob) => {
           
              // Creating new object of PDF file
              const fileURL =
                  window.URL.createObjectURL(blob);
                   
              // Setting various property values
              let alink = document.createElement("a");
              alink.href = fileURL;
              alink.download = pdfName;
              alink.click();
          });
      });
      };

    
 

const fetchResource = () => {
    fetch(api.GetResource, {
        headers: {
            'Auth-Key': process.env.REACT_APP_AUTH_KEY
        }
    })
    .then(response => {
        if (!response || !response.ok) throw new Error(response.status);
        return response.json();
    })
    .then(data => {
         
        setReportsArray(data.data['reports']['data'])
        setWhitepapersArray(data.data['whitepapers']['data'])
        setReportsCount(data.data['reports']['count'])
        setWhitepaperCount(data.data['whitepapers']['count'])
    })
    .catch(err => {
        console.log('error fetching reports', err);
    });

}



    const getCaseStudyData = () => {
        const url = `${api.caseStudyApi}?tech_area=all&page=1&page_size=10`;
        fetch(url)
            .then(response => {
                if (!response || !response.ok) throw Error(response.status);
                return response.json();
            })
            .then(({ data }) => {
                setCaseStudyArray(data)
            })
            .catch(err => console.log('Error fetching case study data', err));
    }

    useEffect(() => {
        //fetchReports();
        //fetchPapers();
        fetchResource();
        getCaseStudyData();
    }, []);

    // console.log(caseStudyArray)
    // console.log(reportsArray)
    // console.log(whitepapersArray)

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        setVisibleItemsCount(3);
    };
    const activeData = activeTab === 'Reports' ? reportsArray :
        activeTab === 'Whitepapers' ? whitepapersArray :
            caseStudyArray;

    const formatDate = (dateString) => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        const date = new Date(dateString);
        const month = months[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        return `${month} ${day}, ${year}`;
    }

     
    return (
        <div style={{ marginTop: "15px" }} className="home__discover resoirceHOme">
            <div className="home__discover__section-left infosection resourceHeading">
                <h3>Resources</h3>
                <div className="tabsSection">
                    <div className={activeTab === 'Reports' ? 'activeTab' : 'inActiveTab'} onClick={() => handleTabClick('Reports')}><h4>Reports  ({reportsCount})</h4> <i className="fa fa-angle-right" ></i></div>
                    <div className={activeTab === 'Whitepapers' ? 'activeTab' : 'inActiveTab'} onClick={() => handleTabClick('Whitepapers')}><h4>Whitepapers ({whitepaperCount})</h4> <i className="fa fa-angle-right" ></i></div>
                    <div className={activeTab === 'Case Study' ? 'activeTab' : 'inActiveTab'} onClick={() => handleTabClick('Case Study')}><h4>Case Study ({caseStudyArray.length})</h4> <i className="fa fa-angle-right" ></i></div>
                </div>
            </div>
            <div className="resourceCardsGroup">
                <div className="resourceCardSection">
                    {activeData.slice(0, visibleItemsCount).map((item, index) => (
                        <div key={index} className="resource_card">
                            <img src={activeTab === 'Case Study' ? item.thumbnail_url : item.thumbnail} alt={item.title} />
                            <h4>{item.publish_date ? formatDate(item.publish_date) : item.published_date}</h4>
                            <h3>{item.title}</h3>
                            {
                                activeTab === 'Case Study' ? <p style={{ textAlign: 'justify' }}>{item.summary ? item.summary.slice(0, 130) : item.description.slice(0, 130)}...</p> :

                                    <div
                                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.body.slice(0, 130) + '...') }}
                                    />
                            }
    {
        activeTab === 'Case Study' ? 
    
                            <a href={item.file_url} className="downLoadResource" download><i className="fa fa-download" /> Download</a>
                            :
                            <a className="downLoadResource" onClick={(e) => {
                                // handleClick(e);
                                 downloadPdf(item.link, item.title);
                               }} download><i className="fa fa-download" /> Download</a>
        }
                        </div>
                    ))}
                </div>


                <div className="navSectionResource">
                    <Link
                        rel="canonical"
                        to={activeTab === 'Reports' ? "/resources/reports" :
                            activeTab === 'Whitepapers' ? "/resources/whitepapers" :
                                "/resources/case_study"}
                        className="downLoadResource navSectionResourceLink"

                    > Explore More <i className='fas fa-arrow-right' /></Link>
                </div>


            </div>
            <div className="navSectionResourceScroll">
                <Link
                    rel="canonical"
                    to={activeTab === 'Reports' ? "/resources/reports" :
                        activeTab === 'Whitepapers' ? "/resources/whitepapers" :
                            "/resources/case_study"}
                    className="downLoadResource navSectionResourceLink"

                > Explore More <i className='fas fa-arrow-right' /></Link>
            </div>
        </div>
    );
};

export default ResourcesSection;