import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

// import CapabilityTree from "./browsingtech-components/CapabilityTree";
import FinancialData from "./browsingtech-components/FinancialData";
import PieChart from "./browsingtech-components/PieChart";
import MapRenderer from "./map-components/MapRenderer";
import ShareLink from "./ShareLink";
import Modal from "./ui/modal/Modal";

//  Icons and Image Imports
import ShareIcon from "../img/share-dark.svg";
import FollowIcon from "../img/follow.svg";

import AcademiaSvg from '../inline-svg-components/AcademiaSvg';
import UniqueProductsSvg from '../inline-svg-components/UniqueProductsSvg';
import CompaniesSvg from '../inline-svg-components/CompaniesSvg';
import ResearchersSvg from '../inline-svg-components/ResearchersSvg';
import RnDCenterSvg from '../inline-svg-components/RnDCenterSvg';

import arrowImage from "../img/card-arrow.svg";

import noPieChartDataImg from '../img/no-pie-chart.svg';

import "../css/BrowsingTech.css";
import api from "../api";

import getBannerImage from "./helper-functions/bannerImageDecider";
import {
  updateSearchQuery,
  updateArea,
  updateCapability,
} from "../actions/search";
import Loader from "./ui/loader/Loader";
import CapabilitiesList from "./browsingtech-components/CapabilitiesList";
import EventWebinar from "./EventsWebinar";
import NewsSignal from './NewsSignal';
import Reports from './ReportsData';
//import Case from './CaseStudy';
//import Spotlight from './Spotlight';
//import Summary from './Summary';
//import Captree from "./browsingtech-components/Captree";
// import capTreeDemoData from "./browsingtech-components/captreedemo.json";
import { pieColors } from "../utils/graphColors";
//import { arraySwap } from "redux-form";
//import PolicyUpdates from "./PolicyUpdates";
//import { useContext } from "react";
import TreeView from './FormTree'




class BrowsingTech extends Component {
  // TODO: add logic for follow or replace it with like

  state = {
    fetching: true,
    area: "",
    capabilitiesCss: "browsingtech__capability-map-css",
    techareaDetails: {
      patent_count: "",
      companies: [],
      description: "",
      name: "",
      technology_capabilities: [],
      techarea_pi_areawise: [],
      techarea_pi_typewise: [],
      top_liked_capabilities: [],
    },
    techCapabilities: [],
    trendingProducts: [],
    eventsWebinars: [],
    reports:  [],
    spotlight: [],
    caseStudyDetails: [],
    summaryDetails: [],
    spotlightDetails: [],
    policyUpdates: [],
    newsSignal: [],
    locations: [],
    areaFinancialInfo: null,
    mapRegionCountList: [],
    liked: false,
    locationFilter: "",
    loadingMap: false,
    expandAbout: false,
    isCapListExpanded: false,
    showShareModal: false,
    bannerImage: null,
    topInvestorsView: "angel",
    barchartView: "year",
    capabilityViewType: "list",
    eventType: "",
    counterSpotlight: 1,
    counterReport: 1,
    counterNews: 1,
    counterPolicy: 1,
    counterSummary: 1,

  };
  prevToastId = null;



   

  
  componentDidMount() {
    this.getQueryParams();
    this.props.updateSearchQuery("");
  }

  // handleCapabilitySelected = selectedOption => {
  //   this.props.history.push(
  //     `/techcapability/browsing/?capability=${selectedOption.value}`
  //   );
  // };

  setTopInvestorsView = type => this.setState({ topInvestorsView: type });
  setBarchartView = type => this.setState({ barchartView: type });


  //* TOGGLES BETWEEN LIST AND CAPABILITY VIEW FOR CAPABILITIES
  changeCapabilityDisplay = () =>
    this.setState(prevState => ({
      capabilityViewType:
        prevState.capabilityViewType === "list" ? "tree" : "list",
    }));


  // Get Query Params
  getQueryParams = () => {
    // create new search params object from location
    // OLD code
    const urlQueryParams = new URLSearchParams(this.props.location.search);

    // NEW code
    // removing queryparam for that using direct path --- below building directh path
    //console.log(localStorage.getItem('techarea'))
    let pathList = this.props.location.pathname.split('/')
    let techareaFromlocation = pathList[2].replaceAll("_", " ")
    //console.log(techareaFromlocation)

    this.setState({
      // area: urlQueryParams.get("area"),//OLD
      area: techareaFromlocation
    }, this.initializePage);
  };

  initializePage = async () => {
   // this.getEventsWebinar(this.state.area);
    this.getReprts(this.state.area);
    this.getNews(this.state.area);
    //this.getSpotlight(this.state.area);
    //this.getPolicyUpdates(this.state.area);
    this.props.updateSearchQuery(this.state.area);
    this.props.updateArea(this.state.area);
    //this.getCaseStudy(this.state.area);
    //this.getSummary(this.state.area);
    this.props.updateCapability("");
    this.getLocations("company");
    const { likes, liked, areaDetails, isStatus500 } = await this.getTechAreaDetails();
    // Logic for if block below is explained in getTechAreaDetails()
    if (isStatus500) {
      this.props.history.push('/NotFound');
      return;
    }
    const financialInfo = await this.getFinancialInfo();
    const capabilities = await this.getCapabilities();
    const trendingProducts = await this.getTrendingProducts();
    this.setState({
      fetching: false,
      bannerImage: getBannerImage(this.state.area),
      likes: likes,
      liked: liked,
      techareaDetails: areaDetails,
      areaFinancialInfo: financialInfo,
      techCapabilities: capabilities,
      trendingProducts: trendingProducts,
    });
  }

  toggleEventType = async (e) => {
    var eventId = e.target.id
    this.setState({
      eventType: eventId
    }, () => {
      this.getEventsWebinar(this.state.area)
    })
  }
  toggleSpotlight = () => {
    var count = this.state.counterSpotlight + 1
    this.setState({
      counterSpotlight: count
    }, () => {
      this.getSpotlight(this.state.area)
    })
  }
  toggleNews = () => {
    var count = this.state.counterNews + 1
    this.setState({
      counterNews: count
    }, () => {
      this.getNews(this.state.area)
    })
  }

  toggleReport = () => {
    var count = this.state.counterReport + 1
    this.setState({
      counterReport: count
    }, () => {
      this.getReprts(this.state.area)
    })
  }
  togglePolicy = () => {
    var count = this.state.counterPolicy + 1
    this.setState({
      counterPolicy: count
    }, () => {
      this.getPolicyUpdates(this.state.area)
    })
  }
  toggleSummary = () => {
    var count = this.state.counterSummary + 1
    // console.log(count)
    this.setState({
      counterSummary: count
    }, () => {
      this.getSummary(this.state.area)
    })
  }
  getFinancialInfo = async () => {
    const url = `${api.techarea_financial_info}?keyword=${this.state.area}&limit=12`;
    const requestConfig = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      let response = await fetch(url, requestConfig);
      if (!response || !response.ok) {
        console.log('Could not fetch financial info, status code', response.status);
        return {};
      }

      //  Proceed for successful request
      response = await response.json();
      return response.data;
    } catch (err) {
      console.log('Error fetching financial info,', err);
      return {};
    }
  }

  getCapabilities = async () => {
    const url = `${api.get_tech_capabilities}?tech_area=${this.state.area}`;
    const requestConfig = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { userInfo } = this.props;
    if (userInfo && userInfo.token) {
      this.setState({ capabilitiesCss: "browsingtech__capability-map" })
    }
    else {

    }

    try {
      let response = await fetch(url, requestConfig);
      if (!response || !response.ok) {
        console.log('Could not fetch capabilities, status code', response.status);
        return {};
      }

      //  Proceed for successful request
      response = await response.json();
      return response.data;
    } catch (err) {
      console.log('Error fetching capabilities,', err);
      return {};
    }
  }

  toggleExpandAbout = () =>
    this.setState(prevState => ({
      expandAbout: !prevState.expandAbout,
    }));

  toggleCapList = () =>
    this.setState(prevState => ({
      isCapListExpanded: !prevState.isCapListExpanded,
    }));


  followToggle = () => {
    if (!this.props.userInfo || !this.props.userInfo.username) {
      toast.error("You must login first to follow");
      return;
    }

    fetch(api.action_on_technology, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        TCRAUTH: this.props.userInfo.token,
      },
      body: JSON.stringify({
        technology: this.state.area,
        action: "like",
      }),
    }).then(response => {
      if (response.status !== 200) {
        console.log("Problem liking/following the tech area");
        return;
      }

      response.json().then(response => {
        if (response.success) {
          this.setState(prevState => ({ liked: !prevState.liked }));
        }
      });
    });
  };


  getTechAreaDetails = async () => {
    const url = `${api.tech_area_browsing}?area=${this.state.area}`;
    const requestConfig = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { userInfo } = this.props;   //  Coming from redux store    

    // If user is logged in, also pass the auth token to the request
    if (userInfo && userInfo.token) requestConfig.headers['TCRAUTH'] = userInfo.token;

    try {
      let response = await fetch(url, requestConfig);
      //  Status 500 is returned when user enters a random area query param
      //  Return this, and if it is true, we redirect to the NotFound page
      if (!response || !response.ok) {
        return {
          likes: -1,
          liked: false,
          areaDetails: {},
          isStatus500: response.status === 500
        };
      }

      response = await response.json();
      // console.log(response)      
      //  Evaluate the liked status

      let liked = null;
      if (!userInfo || !userInfo.username || !response.data.liked_by) liked = false;
      else liked = response.data.liked_by.some(userObj => userObj.username === userInfo.username);

      return ({
        likes: response.data.likes,
        liked: liked,
        areaDetails: response.data,
      });
    } catch (err) {
      console.log("Error fetching tech area details,", err);
      return { likes: -1, liked: false, areaDetails: {} };
    }
  }

  getTrendingProducts = async () => {
    const url = `${api.trending_products}?request_type=tech_area&keyword=${this.state.area}&limit=12`;

    const requestConfig = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      let response = await fetch(url, requestConfig);
      if (!response || !response.ok) {
        console.log('Could not fetch trending products, status code', response.status);

      }

      response = await response.json();
      return response.data;
    } catch (err) {
      console.log('Error fetching trending products,', err);

    }
  }
  //getEventsWebinar = async (techarea) => {
//
  //  const url = `${api.eventsWebinar}?tech_area=${techarea}&event_type=${this.state.eventType}`;
  //  const requestConfig = {
  //    method: 'GET',
  //    headers: {
  //      'Content-Type': 'application/json',
  //    }
  //  };
  //  if (this.props.userInfo && this.props.userInfo.token) {
  //    requestConfig.headers['TCRAUTH'] = this.props.userInfo.token;
  //  }
  //  try {
  //    let response = await fetch(url, requestConfig);
  //    if (!response || !response.ok) {
  //      console.log('Could not fetch trending products, status code', response.status);
  //    }
  //    response = await response.json();
  //    // console.log(response)
  //    this.setState({
  //      eventsWebinars: response,
  //    });
  //    // console.log("events webinar",this.state.eventsWebinars)
  //    return response;
  //  } catch (err) {
  //    console.log('Error fetching trending products,', err);
  //  }
  //}
 // getSummary = async (techarea) => {
 //   const url = `${api.summary}?tech_area=${techarea}&page=${this.state.counterSummary}&page_size=10`;
 //   const requestConfig = {
 //     method: 'GET',
 //     headers: {
 //       'Content-Type': 'application/json',
 //     }
 //   };
 //   if (this.props.userInfo && this.props.userInfo.token) {
 //     requestConfig.headers['TCRAUTH'] = this.props.userInfo.token;
 //   }
 //   try {
 //     let response = await fetch(url, requestConfig);
 //     if (!response || !response.ok) {
 //       console.log('Could not fetch trending products, status code', response.status);
 //     }
 //     response = await response.json();
 //     this.setState({
 //       summaryDetails: response,
 //     });
//
 //     return response;
 //   } catch (err) {
 //     console.log('Error fetching trending products,', err);
 //   }
 // }
  getNews = async (techarea) => {
    const url = `${api.signalNews}?tech_area=${techarea}&page=${this.state.counterNews}&page_size=10`;
    const requestConfig = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    if (this.props.userInfo && this.props.userInfo.token) {
      requestConfig.headers['TCRAUTH'] = this.props.userInfo.token;
    }
    try {
      let response = await fetch(url, requestConfig);
      if (!response || !response.ok) {
        console.log('Could not fetch trending products, status code', response.status);

      }
      response = await response.json();
      this.setState({
        newsSignal: response,
      });
      return response;
    } catch (err) {
      console.log('Error fetching trending products,', err);

    }
  }

  getReprts = async (techarea) => {
    const url = `${api.TechAreaReports}${techarea}`;
    const requestConfig = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Auth-Key': process.env.REACT_APP_AUTH_KEY
      }
    };
    if (this.props.userInfo && this.props.userInfo.token) {
      requestConfig.headers['TCRAUTH'] = this.props.userInfo.token;
    }
    try {
      let response = await fetch(url, requestConfig);
      if (!response || !response.ok) {
        console.log('Could not fetch trending products, status code', response.status);

      }
      response = await response.json();
      //console.log(response.data['reports'])
      this.setState({
        reportsDetails: response.data['reports'],
      });
      return response;
    } catch (err) {
      console.log('Error fetching trending products,', err);
    }
  }

  //getCaseStudy = async (techarea) => {
  //  const url = `${api.caseStudy}?tech_area=${techarea}`;
  //  const requestConfig = {
  //    method: 'GET',
  //    headers: {
  //      'Content-Type': 'application/json'
  //    }
  //  };
  //  if (this.props.userInfo && this.props.userInfo.token) {
  //    requestConfig.headers['TCRAUTH'] = this.props.userInfo.token;
  //  }
  //  try {
  //    let response = await fetch(url, requestConfig);
  //    if (!response || !response.ok) {
  //      console.log('Could not fetch trending products, status code', response.status);
  //    }
//
  //    response = await response.json();
  //    this.setState({
  //      caseStudyDetails: response
  //    })
  //    return response;
  //  } catch (err) {
  //    console.log('Error fetching trending products,', err);
//
  //  }
  //}

  //getSpotlight = async (techarea) => {
//
  //  const url = `${api.spotlight}?tech_area=${techarea}&page=${this.state.counterSpotlight}&page_size=10`;
  //  const requestConfig = {
  //    method: 'GET',
  //    headers: {
  //      'Content-Type': 'application/json'
  //    }
  //  };
  //  if (this.props.userInfo && this.props.userInfo.token) {
  //    requestConfig.headers['TCRAUTH'] = this.props.userInfo.token;
  //  }
  //  try {
  //    let response = await fetch(url, requestConfig);
  //    if (!response || !response.ok) {
  //      console.log('Could not fetch trending products, status code', response.status);
  //    }
  //    response = await response.json();
  //    this.setState({
  //      spotlightDetails: response
  //    })
//
  //    return response;
  //  } catch (err) {
  //    console.log('Error fetching trending products,', err);
  //  }
  //}

 // getPolicyUpdates = async (techarea) => {
 //   const url = `${api.policyUpdates}?tech_area=${techarea}&page=${this.state.counterPolicy}&page_size=10`;
 //   const requestConfig = {
 //     method: 'GET',
 //     headers: {
 //       'Content-Type': 'application/json'
 //     }
 //   };
 //   if (this.props.userInfo && this.props.userInfo.token) {
 //     requestConfig.headers['TCRAUTH'] = this.props.userInfo.token;
 //   }
 //   try {
 //     let response = await fetch(url, requestConfig);
 //     if (!response || !response.ok) {
 //       console.log('Could not fetch trending products, status code', response.status);
 //     }
//
 //     response = await response.json();
 //     this.setState({
 //       policyUpdates: response
 //     })
 //     return response;
//
 //   } catch (err) {
 //     console.log('Error fetching trending products,', err);
 //   }
 // }
  // Fetch locations
  getLocations = async filter => {
    //  If the same filter is clicked, do nothing
    if (this.state.locationFilter === filter) return;
    this.setState({ loadingMap: true, locations: [] });
    const url = `${api.entities_locations}?search_type=tech_area&filter_type=${filter}&keyword=${this.state.area}`;
    const requestConfig = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
      let response = await fetch(url, requestConfig);
      if (!response || !response.ok) {
        console.log('Could not fetch locations, status code', response.status);
        return;
      }
      response = await response.json();
      this.setState({
        loadingMap: false,
        locationFilter: filter,
        locations: response.data
      });
    } catch (err) {
      console.log('Error fetching location,', err);
    }
  }

  shareArea = () => {
    //  Disabled the login check, share modal works without logging in
    // if (this.props.userInfo) {
    //     this.toggleShareModal();
    // } else if (!this.prevToastId || !toast.isActive(this.prevToastId)) {
    //     this.prevToastId = toast.error("Please login first!");
    // }
    this.toggleShareModal();
  };

  toggleShareModal = () => this.setState(prevState => ({
    showShareModal: !prevState.showShareModal
  }));
  render() {


   // console.log(this.state.reports.data.reports);
    const user = JSON.parse(localStorage.getItem("user"));

    let eventData = null
    //let spotlightData = null
    //let policytData = null
    //let caseData = null
    let reportData = null
    let news = null
   // let summaryData = null

    eventData = null
    {/*if (this.state.eventsWebinars && Object.keys(this.state.eventsWebinars).length > 0) {
      eventData = (
        <EventWebinar data={this.state.eventsWebinars['data']}
          activeState={this.state.eventsWebinars["event_type"]}
          eventCss="events_webinar"
          onChangeHandler={this.toggleEventType}
          eventType={this.state.eventType} />
      )
    }*/}

{/*
    if (this.state.spotlightDetails && Object.keys(this.state.spotlightDetails).length > 0) {
      spotlightData = (
        <Spotlight data={this.state.spotlightDetails['data']}
          count={this.state.spotlightDetails['total_count']}
          totalPage={this.state.spotlightDetails["page_count"]}
          title="Spotlight"
          pageCount={this.state.counterSpotlight}
          onChangeHandler={this.toggleSpotlight}
        />
      );
    }*/}
   {/* if (this.state.summaryDetails && Object.keys(this.state.summaryDetails).length > 0) {
      summaryData = (
        <Summary
          data={this.state.summaryDetails['data']}
          count={this.state.summaryDetails['total_count']}
          totalPage={this.state.summaryDetails["page_count"]}
          pageCount={this.state.counterSummary}
          onChangeHandler={this.toggleSummary}
        />
      );
    }*/}
    {/*if (this.state.policyUpdates && Object.keys(this.state.policyUpdates).length > 0) {
      policytData = (
        <PolicyUpdates data={this.state.policyUpdates['data']}
          count={this.state.policyUpdates['total_count']}
          totalPage={this.state.policyUpdates["page_count"]}
          pageCount={this.state.counterPolicy}
          onChangeHandler={this.togglePolicy} />
      );
    }*/}

   {/* if (this.state.caseStudyDetails && Object.keys(this.state.caseStudyDetails).length > 0) {

      caseData = (
        <Case data={this.state.caseStudyDetails['data']}
          count={this.state.caseStudyDetails['total_count']}
          caseStudyCss="case_study_techarea" />
      );
    }*/}

    if (this.state.reportsDetails && Object.keys(this.state.reportsDetails).length > 0) {
      reportData = (
        <Reports data={this.state.reportsDetails}
          count={this.state.reportsDetails.length}
          totalPage={this.state.reportsDetails.length}
          pageCount={this.state.counterReport}
          onChangeHandler={this.toggleReport} />
      );
    }

    if (this.state.newsSignal && Object.keys(this.state.newsSignal).length > 0) {
      news = (
        <NewsSignal
          data={this.state.newsSignal['data']}
          count={this.state.newsSignal['total_count']}
          pageCount={this.state.counterNews}
          title="signal and news"
          totalPage={this.state.newsSignal["page_count"]}
          onChangeHandler={this.toggleNews} />
      );
    }

    const capabilities = this.state.isCapListExpanded ?
      this.state.techCapabilities :
      this.state.techCapabilities.slice(0, 50);

    const capabilityDisplay =
      this.state.capabilityViewType === "list" ? (
        <CapabilitiesList
          techarea={this.state.area.replace(/ /g, "_")}
          list={capabilities}
          isListExpanded={this.state.isCapListExpanded}
          toggleCapList={this.toggleCapList}
          showToggleButton={this.state.techCapabilities.length > 50}
        />
      ) : (
        <TreeView
          techarea={this.state.area}
  />
      );

    //  Prepare data for Pie chart (Distribution by type)
    const pieArrLen = pieColors.length;

    const pieChartData = this.state.techareaDetails.techarea_pi_typewise
      ? this.state.techareaDetails.techarea_pi_typewise.map(
        (obj, index) => ({
          name: obj.name,
          value: obj.value,
          itemStyle: { color: pieColors[index % pieArrLen] },
        })
      )
      : [];

    const entityTypePieChart = pieChartData && pieChartData.length
      ? <PieChart data={pieChartData} />
      : <img alt='No data' src={noPieChartDataImg} className='no-pie-data-image' />;


    let financialDataSection = null;
    if (this.state.areaFinancialInfo && Object.keys(this.state.areaFinancialInfo).length > 0) {

      financialDataSection = (
        <FinancialData
          techarea={this.state.area}
          data={this.state.areaFinancialInfo}
          topInvestorsView={this.state.topInvestorsView}
          setTopInvestorsView={this.setTopInvestorsView}
          barchartView={this.state.barchartView}
          setBarchartView={this.setBarchartView}
          loginStatus={user ? "techarea__financial-wrapper" : "techarea__financial-wrapper-login"}
        />
      );
    }
    
    document.title = `${this.state.area} - Technology Capability Repository || DSCI`;
    document.getElementsByTagName("LINK")[0].href = `https://www.techsagar.in/${this.state.area.replace(/ /g, "-")}`
    return (
      <div className="browsingtech">
        {this.state.area && (
          <Modal
            show={this.state.showShareModal}
            toggleModal={this.toggleShareModal}
          >
            <ShareLink
              url={`${process.env.REACT_APP_CONSUMER_LINK
                }` + this.props.location.pathname}
            />
          </Modal>
        )}
        <Loader loading={this.state.fetching} height="500px">
          <div className="browsingtech__jumbotron">
            {/* Left half of jumbotron, with all the texts and links */}
            <div className="browsingtech__jumbotron-text">
              <div className="browsingtech__top">
                <div className="browsingtech__breadcrumb">
                  <Link to="/">Home</Link> / {this.state.area}
                </div>
              </div>

              <h1 className="browsingtech__jumbotron-head"
              >{this.state.area}</h1>

              {this.state.techareaDetails.description && (
                <div className="browsingtech__jumbotron-desc">
                  <div>
                    {this.state.techareaDetails.description
                      .split("\n")
                      .map((p, index) => (
                        <p key={index}>{p}</p>
                      ))}
                  </div>
                </div>
              )}

              <div className="browsingtech__top-links-wrapper">
                <div
                  className="browsingtech__top-link"
                  onClick={this.shareArea}
                >
                  <img
                    className="browsingtech__top-link-icon"
                    src={ShareIcon}
                    alt="Share"
                  />
                </div>
                <div
                  onClick={this.followToggle}
                  className="browsingtech__top-link browsingtech__top-link--follow"
                >
                  <img
                    className="browsingtech__top-link-icon"
                    src={FollowIcon}
                    alt="Follow Icon"
                  />
                  &nbsp; &nbsp;
                  {this.state.liked ? <span>Unfollow</span> : <span>Follow</span>}
                </div>
              </div>
            </div>
            {/* End of left half of jumbotron */}

            {/* Jumbotron banner image on the right */}
            <div className="browsingtech__banner-img-wrapper">
              {this.state.bannerImage && (
                <img
                  className="browsingtech__banner-img"
                  src={this.state.bannerImage}
                  alt="Banner"
                />
              )}
            </div>
          </div>

          {/* Count Cards Section */}
          <div className="browsingtech__counts-cards-container">
            <div className="browsingtech__counts-card--wrapper">
              <Link
                className="browsingtech__counts-card"
                //   to={{
                //     pathname: "/searchresults",
                //     search: `?q=${encodeURIComponent(
                //       JSON.stringify({
                //         search_type: "tech_area",
                //         keyword: this.state.area,
                //         resultType: "companies",
                //       })
                //     )}`,
                //   }}
                to={{
                  pathname: "/search/technology",
                  search: `?keyword=${this.state.area.replaceAll(/ /g, "_")}&resultType=companies`,
                }}
              >
                <div className="card-type-and-arrow-wrapper">
                  <div className="browsingtech__counts-card-type"
                  >Companies</div>
                  <div className="card-arrow-img-wrapper">
                    <img src={arrowImage} alt="Go" />
                  </div>
                </div>

                <div className="browsingtech__counts-card__icon-and-count-wrapper">
                  <div>
                    <CompaniesSvg className='statIcons' />
                  </div>
                  <p className="browsingtech__counts-card-count">
                    {this.state.techareaDetails.companies_count}
                  </p>
                </div>
              </Link>
            </div>
            <div className="browsingtech__counts-card--wrapper">
              <Link
                className="browsingtech__counts-card"
                //   to={{
                //     pathname: "/searchresults",
                //     search: `?q=${encodeURIComponent(
                //       JSON.stringify({
                //         search_type: "tech_area",
                //         keyword: this.state.area,
                //         resultType: "academia",
                //       })
                //     )}`,
                //  }}
                to={{
                  pathname: "/search/technology",
                  search: `?keyword=${this.state.area.replaceAll(/ /g, "_")}&resultType=academia`,
                }}
              >
                <div className="card-type-and-arrow-wrapper">
                  <div className="browsingtech__counts-card-type"
                  >Academia</div>
                  <div className="card-arrow-img-wrapper">
                    <img src={arrowImage} alt="Go" />
                  </div>
                </div>

                <div className="browsingtech__counts-card__icon-and-count-wrapper">
                  <div>
                    <AcademiaSvg className='statIcons' />
                  </div>
                  <div className="browsingtech__counts-card-count">
                    {this.state.techareaDetails.academia_count}
                  </div>
                </div>
              </Link>
            </div>
            <div className="browsingtech__counts-card--wrapper">
              <Link
                className="browsingtech__counts-card"

                //  to={{
                //    pathname: "/searchresults",
                //    search: `?q=${encodeURIComponent(
                //      JSON.stringify({
                //        search_type: "tech_area",
                //        keyword: this.state.area,
                //        resultType: "r_and_d",
                //      })
                //    )}`,
                //  }}
                to={{
                  pathname: "/search/technology",
                  search: `?keyword=${this.state.area.replaceAll(/ /g, "_")}&resultType=r_and_d`,
                }}
              >
                <div className="card-type-and-arrow-wrapper">
                  <div className="browsingtech__counts-card-type"
                  >R&D</div>
                  <div className="card-arrow-img-wrapper">
                    <img src={arrowImage} alt="Go" />
                  </div>
                </div>
                <div className="browsingtech__counts-card__icon-and-count-wrapper">
                  <div>
                    <RnDCenterSvg className='statIcons' />
                  </div>
                  <div className="browsingtech__counts-card-count">
                    {this.state.techareaDetails.r_and_d_count}
                  </div>
                </div>
              </Link>
            </div>

            <div className="browsingtech__counts-card--wrapper">
              <Link
                className="browsingtech__counts-card"

                //to={{
                //  pathname: "/searchresults",
                //  search: `?q=${encodeURIComponent(
                //    JSON.stringify({
                //      search_type: "tech_area",
                //      keyword: this.state.area,
                //      resultType: "individual",
                //    })
                //  )}`,
                //}}
                to={{
                  pathname: "/search/technology",
                  search: `?keyword=${this.state.area.replaceAll(/ /g, "_")}&resultType=individual`,
                }}
              >
                <div className="card-type-and-arrow-wrapper">
                  <div className="browsingtech__counts-card-type"
                  >Researchers</div>
                  <div className="card-arrow-img-wrapper">
                    <img src={arrowImage} alt="Go" />
                  </div>
                </div>
                <div className="browsingtech__counts-card__icon-and-count-wrapper">
                  <div>
                    <ResearchersSvg className='statIcons' />
                  </div>
                  <div className="browsingtech__counts-card-count">
                    {this.state.techareaDetails.individuals_count}
                  </div>
                </div>
              </Link>
            </div>

            {/* TODO: UNIQUE PRODUCTS CARD */}
            <div className="browsingtech__counts-card--wrapper">
              <Link
                className="browsingtech__counts-card"
                to={{
                  pathname: "/search/technology",
                  search: `?keyword=${this.state.area.replaceAll(/ /g, "_")}&resultType=products`,
                }}
              //  to={{
              //    pathname: "/searchresults",
              //    search: `?q=${encodeURIComponent(
              //      JSON.stringify({
              //        search_type: "tech_area",
              //        keyword: this.state.area,
              //        resultType: "products",
              //      })
              //    )}`,
              //  }}



              >
                <div className="card-type-and-arrow-wrapper">
                  <div className="browsingtech__counts-card-type"
                  >Products</div>
                  <div className="card-arrow-img-wrapper">
                    <img src={arrowImage} alt="Go" />
                  </div>
                </div>
                <div className="browsingtech__counts-card__icon-and-count-wrapper">
                  <div>
                    <UniqueProductsSvg className='statIcons' />
                  </div>
                  <div className="browsingtech__counts-card-count">
                    {this.state.techareaDetails.unique_products}
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="browsingtech__info-section-wrapper">
            <p className="browsingtech__info-head"
            >Trending Products</p>

            <div className="container-fluid browsingtech__trending-products__cards-wrapper">
              <div className="row">
                {this.state.trendingProducts &&
                  this.state.trendingProducts.map(
                    (product, index) => (
                      <Link

                        to={{
                          pathname: `/${this.state.area.replace(/ /g, "_")}/${product.name.replace("%", "percentage").replace(/ /g, "_")}`,
                        }}
                        key={index}
                        className="col-sm-6 col-md-4 col-lg-3 browsingtech__products-card"
                      >
                        <div className="browsingtech__products-card-head">
                          {product.product_name}
                        </div>
                        <div className="browsingtech__products-card-subhead">
                          {product.name}
                        </div>
                      </Link>
                    )
                  )}
              </div>
            </div>
          </div>

          {/*eventData*/}
          <div className="tree_view">
          <TreeView
          techarea={this.state.area}/>
          </div>
          {financialDataSection}
          {/* === Capability Map */}
          <div className={this.state.capabilitiesCss}>
            <div className="browsingtech__container">
              <div className="browsingtech__capability-map--head-wrapper">
                <div>
                  <p className="browsingtech__capability-map-head"
                  >Capabilities {this.state.techCapabilities ?
                    `(${this.state.techCapabilities.length})` : ''}
                  </p>
                </div>

                {/* {this.state.capabilityViewType === 'tree' && <div>
                              Drag, Zoom and Explore
                            </div>} */}

               {/*treeview code <div className="search-filter--view-toggle" style={{ marginLeft: '0' }}>
                  <p className="search-filter--view-toggle--text"
                  >Tree View</p>

                  <input
                    type="checkbox"
                    id="view-toggle-button"
                    onChange={this.changeCapabilityDisplay}
                  />
                  <label
                    className="view-toggle-label"
                    htmlFor="view-toggle-button"
                  >Toggle</label>
                          </div>*/}

                {/* <div className="browsingtech__capability-map__capability-dropdown">
                <label style={{ textAlign: "center" }}
                >Search Tech Capabilities</label>
                <Select
                  placeholder={"Search Capabilities"}
                  className="basic-single"
                  isSearchable={true}
                  styles={capabilitySearchStyles}
                  options={capabilitySearchOptions}
                  onChange={this.handleCapabilitySelected}
                />
              </div> */}
              </div>
              {capabilityDisplay}
            </div>
            {/* {this.state.techareaDetails.capability_map && (
                            <CapabilityTree
                                data={this.state.techareaDetails.capability_map}
                            />
                        )} */}
          </div>
          
          {/*caseData*/}
          {/*spotlightData*/}
          {/*policytData*/}
          {/*summaryData*/}
          {/* Geographical Distribution */}
          <div className=" browsingtech__info">
            <div className="browsingtech__info-left browsingtech__info-left--techarea">
              <div className={user ? "browsingtech__info-left-section browsingtech__map-wrapper" : "browsingtech__info-left-section browsingtech__map-wrapper-logout"}>
                <p className="browsingtech__distribution-map__head"
                >Geographical Distribution</p>

                <div className="browsingtech__map-filters-and-comp-sizes--wrapper">
                  <div className="browsingtech__map-filters-wrapper">
                    <p className="browsingtech__map-filters-head"
                    >Filter By Entity</p>

                    {/* Map filters container */}
                    <div className="browsingtech__map-filters__container">
                      <div>
                        <input
                          className="map-filter-radio"
                          name="map_filter"
                          id="company_filter"
                          type="radio"
                          onClick={this.getLocations.bind(null, "company")}
                          defaultChecked={true}
                        />
                        <label
                          htmlFor="company_filter"
                          className="browsingtech__map-filter"
                        >
                          <span className="custom-radio-btn" />
                          Companies
                        </label>
                      </div>

                      <div>
                        <input
                          className="map-filter-radio"
                          name="map_filter"
                          id="academia_filter"
                          type="radio"
                          onClick={this.getLocations.bind(null, "academia")}
                        />
                        <label
                          htmlFor="academia_filter"
                          className="browsingtech__map-filter"
                        >
                          <span className="custom-radio-btn" />
                          Academia
                        </label>
                      </div>

                      <div>
                        <input
                          className="map-filter-radio"
                          name="map_filter"
                          id="individual_filter"
                          type="radio"
                          onClick={this.getLocations.bind(
                            null,
                            "individual"
                          )}
                        />
                        <label
                          htmlFor="individual_filter"
                          className="browsingtech__map-filter"
                        >
                          <span className="custom-radio-btn" />
                          Researchers
                        </label>
                      </div>
                      <div>
                        <input
                          className="map-filter-radio"
                          name="map_filter"
                          id="rnd_filter"
                          type="radio"
                          onClick={this.getLocations.bind(null, "r_and_d")}
                        />
                        <label
                          htmlFor="rnd_filter"
                          className="browsingtech__map-filter"
                        >
                          <span className="custom-radio-btn" />
                          R & D Centres
                        </label>
                      </div>
                    </div>
                    {/* End of map filters - 2nd row */}
                  </div>
                  {/* End of map filters wrapper */}

                  {/* Display conditionally using this.state.locationFilter === 'company' && */}
                  <div className="browsingtech__comp-sizes--wrapper">
                    <p className="browsingtech__map-filters-head"
                    >Distribution Against Cluster Size</p>

                    <div className="browsingtech__comp-sizes-container">
                      <div className="browsingtech__comp-sizes__size">
                        <div className="comp-size__marker sqr--1" />
                        2-9
                      </div>

                      <div className="browsingtech__comp-sizes__size">
                        <div className="comp-size__marker sqr--2" />
                        10-99
                      </div>

                      <div className="browsingtech__comp-sizes__size">
                        <div className="comp-size__marker sqr--3" />
                        100-999
                      </div>

                      <div className="browsingtech__comp-sizes__size">
                        <div className="comp-size__marker sqr--4" />
                        1,000-9,999
                      </div>

                      <div className="browsingtech__comp-sizes__size">
                        <div className="comp-size__marker sqr--5" />
                        10,000+
                      </div>
                    </div>
                  </div>
                </div>
                <MapRenderer
                  filter={this.state.locationFilter}
                  techarea={this.state.area}
                  locations={this.state.locations}
                  loading={this.state.loadingMap}
                  height="130%"
                  mapRegionCount={
                    this.state.mapRegionCountList
                  }
                // zoom={4}
                />
              </div>
            </div>

            <div className={user ? "browsingtech__info-right" : "browsingtech__info-right"}>
              <p className={`browsingtech__pie-chart-head`}
              >Distribution Against Entity Type</p>

              {entityTypePieChart}
            </div>
          </div>
          <div className="tech_news_report">
          {news}
          {reportData}
          </div>
          {" "}
           
          {/* end of browsingtech__info  */}
        </Loader>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = dispatch => ({
  updateSearchQuery: query => dispatch(updateSearchQuery({ query: query })),
  updateArea: area => dispatch(updateArea({ area: area })),
  updateCapability: capability =>
    dispatch(updateCapability({ capability: capability })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrowsingTech);
