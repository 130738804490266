import React, { Component } from "react";
import Select from "react-select";

const customStyles = {
    control: provided => ({
        // none of react-select's styles are passed to <Control />
        ...provided,
        background: "none",
        outline: "none",
        minHeight: "4rem",
        paddingLeft: "6px",
        maxWidth: "100%",
        border: "solid 0.5px #aaa",
    }),
    option: provided => ({
        ...provided,
        cursor: "pointer",
    }),
    menuList: provided => ({
        ...provided,
        maxHeight: "20rem",
    }),
};

const normalizedValue = (input, options, valueType, isMulti) => {
    //console.log(input,"input value")
    if (!input.value || input.value === "") {
        return;
    }

    let result;
    if (valueType === "string") {
        result = isMulti
            ? options.filter(option =>
                input.value.some(
                    includedOption => includedOption === option.value
                )
            )
            : options.find(option => option.value === input.value);
    } else {
        result = isMulti
            ? options.filter(option => option === input.value)
            : options.find(option => option === input.value);
    }
        //console.log(result,"result")
    return result;
};

class FormSelect extends Component {
    componentDidMount() {
        if (this.props.updateTechCap) {
            if (this.props.input.value) {
                this.props.updateTechCap(this.props.input.value);
            }
        }
    }

    render() {
         
        const {
            children,
            input,
            options,
            isMulti,
            valueType,
            ...props
        } = this.props;
        //console.log("formselect file",this.props)
        return (
            <div
                className={`feature_form__input-field-wrapper ${
                    props.widthClass
                    }`}
            >
                <div className="feature_form__select-wrapper">
                    <label className="feature_form__input-label">
                        {props.label}
                    </label>

                    <Select
                        {...props}
                        {...input}
                        isMulti={isMulti}
                        styles={customStyles}
                        className="feature_form__select"
                        clearable={props.clearable}
                        searchable={props.searchable}
                        options={options}
                        value={normalizedValue(
                            input,
                            options,
                            valueType,
                            isMulti
                        )}
                        onChange={option => {
                           // console.log("onchange call",option)
                            if (valueType === "string") {
                                if (!isMulti) input.onChange(option ? option.value : option);
                                else input.onChange(option.map(o => o.value));
                            } else
                                input.onChange(option.value);

                            if (props.updateTechCap) {
                                props.updateTechCap(option ? option.value : option);
                            }
                            if (props.clearCapFields) {
                                props.clearCapFields();
                            }
                        }}
                        onFocus={e => {
                            input.onFocus(e);
                        }}
                        onBlur={e => {
                            e.preventDefault();
                            //input.onBlur(input.value);
                        }}
                    />
                </div>
                <div className="feature_form__field-error">
                    {props.meta && props.meta.touched ? props.meta.error : ""}
                </div>
            </div>
        );
    }
}

export default FormSelect;
