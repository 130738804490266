import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';


//  User defined components import
import EntitySelector from './EntitySelector';
import AddOrClaim from './AddOrClaim';
import ClaimEntity from './ClaimEntity';
import ClaimRequestConfirmed from './ClaimRequestConfirmed';
import RequestSubmitted from './RequestSubmitted';
import IndustryForm1 from './Industry/IndustryForm1';
import IndustryForm2 from './Industry/IndustryForm2';
import AcademiaForm1 from './Academia/AcademiaForm1';
import AcademiaForm2 from './Academia/AcademiaForm2';
import IndividualForm1 from './Individual/IndividualForm1';
import IndividualForm2 from './Individual/IndividualForm2';
import RnDForm1 from './RnD/RnDForm1';
import RnDForm2 from './RnD/RnDForm2';

import { setFormType } from '../../actions/user-form';

//  Form validator function import
import validate from './validate';

//  Asset imports
import dsciLogo from '../../img/dsc-logo/tech-sagar-logo-exploration-72.png';
import currentImg from "../../img/wizard-current.svg";
import pendingImg from "../../img/wizard-pending.svg";
import completeImg from "../../img/wizard-complete.svg";

//  CSS Import
import "../../css/FeatureForm.css";

//  API import
import api from '../../api';


class FormWrapper extends React.Component {
  state = {
    formPart: 1,
    totalParts: 2,
    completedPages: [],
    selectedType: 'company',
    shouldRedirect: false,
    isClaiming: false,
    isAdding: false,
    claimingEntity: null,

    cities: [],
    companyTypes: [],
    companySizes: [],
  };

  componentDidMount() {
    
    //  Fetch the required data that will be needed for forms
    this.getCompanyTypes();
    this.getCompanySizes();
    this.getCitiesList();
  }

  componentWillUnmount() {
    this.props.reset();
  }


  //  Fetch company types options for select
  getCompanyTypes = () => {
    const requestConfig = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    fetch(api.company_types, requestConfig).then(response => {
      if (!response || !response.ok) {
        console.log('Could not fetch company types', response.status);
        return;
      }

      response.json().then(response => {
        this.setState({ companyTypes: response.data });
      });
    }).catch(err => {
      console.log('Error fetching company types', err);
    });
  }


  //  Fetch companys sizes options for select 
  getCompanySizes = () => {
    const requestConfig = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    fetch(api.company_sizes, requestConfig).then(response => {
      if (!response || !response.ok) {
        console.log('Could not fetch company sizes', response.status);
        return;
      }

      response.json().then(response => {
        this.setState({ companySizes: response.data });
      })
    }).catch(err => {
      console.log('Error fetching company sizes', err);
    })
  }

  //  Fetch list of cities 
  getCitiesList = () => {
    const requestConfig = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    fetch(api.list_of_cities + '?country=India', requestConfig).then(response => {
      if (!response || !response.ok) {
        console.log('Could not fetch companies list', response.status);
        return;
      }

      response.json().then(response => {
        this.setState({ cities: response.data });
      })
    }).catch(err => {
      console.log('Error fetching cities list', err);
    });
  }

  handleLogoClick = () => {
    this.props.history.push('/');
  }

  isActiveOrComplete = partNo => {
    return (
      this.state.formPart === partNo ||
      this.state.completedPages.includes(partNo)
    );
  }

  getIndicatorImg = partNo => {
    if (this.state.formPart === partNo) return currentImg;
    if (this.state.completedPages.includes(partNo)) return completeImg;
    return pendingImg;
  }

  //  Increments FormPart by 1, adds the previous formPart number to complete pages array
  nextPage = () => {
    // console.log("Called nextPage");
    this.setState(prevState => ({
      formPart: prevState.formPart + 1,
      completedPages: prevState.completedPages.concat(prevState.formPart)
    }));
  }

  //  Decrements FormPart by 0
  prevPage = () => {
    this.setState(prevState => {
      //  Form part cannot be lesser than 1
      const updatedFormPart = prevState.formPart - 1 > 0 ?
        prevState.formPart - 1 :
        prevState.formPart;

      return { formPart: updatedFormPart };
    });
  }

  changeEntityType = value => {
    this.setState({ selectedType: value });
  }

  switchToClaimEntity = (entity) => {
    // console.log('entity = ', entity);
    this.setState(prevState => ({
      formPart: prevState.formPart + 1,
      completedPages: prevState.completedPages.concat(prevState.formPart),
      totalParts: 3,
      isAdding: false,
      isClaiming: true,
      claimingEntity: entity
    }));
  }

  switchToAddEntity = () => {
    this.setState(prevState => ({
      formPart: prevState.formPart + 1,
      completedPages: prevState.completedPages.concat(prevState.formPart),
      totalParts: 4,
      isAdding: true,
      isClaiming: false,
    }));
  }

  claimEntity = values => {
    const requestBody = {
      id: this.state.claimingEntity.id,
      first_name: values['claimer__firstName'],
      last_name: values['claimer__lastName'],
      designation: values['claimer__designation'],
      contact_number: values['claimer__contactNo']
    };

    const requestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'TCRAUTH': this.props.userInfo.token
      },
      body: JSON.stringify(requestBody)
    };

    fetch(api.claimEntity, requestConfig).then(response => {
      if (!response || !response.ok) {
        console.log('Could not submit claim request', response.status);
        toast.error('Could not submit claim request, please try later');
        return;
      }

      this.nextPage();
    }).catch(err => {
      console.log('Error submitting feature request', err);
    });
  }

  submitForm = values => {
    console.log('submitForm called, values = ', values);
    const requestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        TCRAUTH: this.props.userInfo.token
      },
      body: JSON.stringify(values)
    };

    fetch(api.feature_yourself, requestConfig).then(response => {
      if (!response || !response.ok) {
        console.log('Could not submit feature request', response.status);
        toast.error('Could not submit feature request. Please try again later');
        this.setState({ shouldRedirect: true });
      }

      this.setState(prevState => ({
        formPart: prevState.formPart + 1,
        completedPages: prevState.completedPages.concat(prevState.formPart),
      }));

      console.log("Request submitted successfully!");
    }).catch(err => {
      console.log('Error submitting feature request', err);
    })
  }

  render() {
    let redirect = null;
    let rightSideContent = null;

    if (!this.props.userInfo) {
      toast.info('Please login to feature yourself');
      this.props.setFormType('login');
      this.props.displayUserForm();
      return <Redirect to='/' />;
    }


    if (this.state.formPart === 1) {
      rightSideContent = <EntitySelector
        nextPage={this.nextPage}
        totalParts={this.state.totalParts}
        selectedType={this.state.selectedType}
        changeEntityType={this.changeEntityType}
      />;
    } else if (this.state.formPart === 2) {
      rightSideContent = <AddOrClaim
        nextPage={this.nextPage}
        prevPage={this.prevPage}
        totalParts={this.state.totalParts}
        selectedType={this.state.selectedType}
        switchToAddEntity={this.switchToAddEntity}
        switchToClaimEntity={this.switchToClaimEntity}
      />;
    }
    //  FORM PART = 3 
    else if (this.state.formPart === 3) {
      if (this.state.isClaiming) {
        rightSideContent = <ClaimEntity
          selectedType={this.state.selectedType}
          claimingEntity={this.state.claimingEntity}
          claimEntity={this.claimEntity}
        />;
      } else if (this.state.isAdding) {
        if (this.state.selectedType === 'company') {
          rightSideContent = <IndustryForm1
            onSubmit={this.nextPage}
            prevPage={this.prevPage}
            totalParts={this.state.totalParts}
            companySizes={this.state.companySizes}
            companyTypes={this.state.companyTypes}
          />;
        } else if (this.state.selectedType === 'academia') {
          rightSideContent = <AcademiaForm1
            onSubmit={this.nextPage}
            totalParts={this.state.totalParts}
            prevPage={this.prevPage}
          />;
        } else if (this.state.selectedType === 'individual') {
          rightSideContent = <IndividualForm1
            onSubmit={this.nextPage}
            totalParts={this.state.totalParts}
            prevPage={this.prevPage}
          />;
        } else if (this.state.selectedType === 'r_and_d') {
          rightSideContent = <RnDForm1
            onSubmit={this.nextPage}
            totalParts={this.state.totalParts}
            prevPage={this.prevPage}
          />;
        }
      }
    }
    //  FORM PART = 4
    else if (this.state.formPart === 4) {
      if (this.state.isClaiming) {
        rightSideContent = <ClaimRequestConfirmed />;
      } else if (this.state.isAdding) {
        if (this.state.selectedType === 'company') {
          rightSideContent = <IndustryForm2
            cities={this.state.cities}
            totalParts={this.state.totalParts}
            prevPage={this.prevPage}
            onSubmit={this.submitForm}
          />;
        } else if (this.state.selectedType === 'academia') {
          rightSideContent = <AcademiaForm2
            cities={this.state.cities}
            totalParts={this.state.totalParts}
            prevPage={this.prevPage}
            onSubmit={this.submitForm}
          />;
        } else if (this.state.selectedType === 'individual') {
          rightSideContent = <IndividualForm2
            cities={this.state.cities}
            totalParts={this.state.totalParts}
            prevPage={this.prevPage}
            onSubmit={this.submitForm}
          />;
        } else if (this.state.selectedType === 'r_and_d') {
          rightSideContent = <RnDForm2
            cities={this.state.cities}
            totalParts={this.state.totalParts}
            prevPage={this.prevPage}
            onSubmit={this.submitForm}
          />;
        }
      }
    } else if (this.state.formPart === 5) {
      rightSideContent = <RequestSubmitted />;
    }

    //  Redirect when state variable is set
    if (this.state.shouldRedirect) {
      redirect = <Redirect to='/' />;
    }


    return (
      <div className="feature_form">
        {redirect}

        <div className="feature_form__leftbar">
          <div className="feature_form__leftbar-top">
            <div className='feature_form__dsci-logo' onClick={this.handleLogoClick}>
              <img className='feature_form__dsci-logo-img' src={dsciLogo} alt="DSCI LOGO" />
            </div>
            <p className="feature_form__leftbar-top-text"
            >Feature Yourself</p>
          </div>

          <div className="feature_form__indicators-wrapper">
            <div
              className={`feature_form__part-indicator ${
                this.isActiveOrComplete(1) ? 'feature_form__part-indicator--active' : ''
                }`}
            >
              <img
                src={this.getIndicatorImg(1)}
                className="feature_form__part-indicator-img"
                alt="Current Part"
              />Type
            </div>

            <div
              className={`feature_form__part-indicator ${
                this.isActiveOrComplete(2) ? 'feature_form__part-indicator--active' : ''
                }`}
            >
              <img
                src={this.getIndicatorImg(2)}
                className="feature_form__part-indicator-img"
                alt="Current Part"
              />Details
            </div>

            {this.state.isClaiming && <div
              className={`feature_form__part-indicator ${
                this.isActiveOrComplete(3) ? 'feature_form__part-indicator--active' : ''
                }`}
            >
              <img
                src={this.getIndicatorImg(3)}
                className="feature_form__part-indicator-img"
                alt="Current Part"
              />Confirm
            </div>}

            {this.state.isAdding && <div
              className={`feature_form__part-indicator ${
                this.isActiveOrComplete(3) ? 'feature_form__part-indicator--active' : ''
                }`}
            >
              <img
                src={this.getIndicatorImg(3)}
                className="feature_form__part-indicator-img"
                alt="Current Part"
              />About
            </div>}

            {this.state.isAdding && <div
              className={`feature_form__part-indicator ${
                this.isActiveOrComplete(4) ? 'feature_form__part-indicator--active' : ''
                }`}
            >
              <img
                src={this.getIndicatorImg(4)}
                className="feature_form__part-indicator-img"
                alt="Current Part"
              />Contact
            </div>}
          </div>
        </div>

        {rightSideContent}
      </div>
    )
  }
}

FormWrapper = reduxForm({
  form: 'feature_yourself',
  destroyOnUnmount: false,
  validate: validate,
  initialValues: {
    contact_person: {
      persons: [{ name: "", number: "", email: "" }],
    },
    address: { line1: "", pin_code: "", city: "" },
    // technology_heads: [{}],
    // business_heads: [{}],
    // technology_type: [{}],
    // products: [
    //     { competitors: [{ name: "", url: "" }], technology_type: [{}] },
    // ],
    // services: [
    //     { competitors: [{ name: "", url: "" }], technology_type: [{}] },
    // ],
    // solutions: [
    //     { competitors: [{ name: "", url: "" }], technology_type: [{}] },
    // ],
    // research_papers: [
    //     {
    //         title: "",
    //         url: "",
    //         technology_type: [{}],
    //         authors: [{ name: "", url: "" }],
    //     },
    // ],
    // blogs: [{ title: "", url: "" }],
    // patents: [{ title: "", url: "", technology_type: [{}] }],
    // key_global_competitors: [{ name: "", url: "" }],
    // key_local_competitors: [{ name: "", url: "" }],
    // recognitions: [{ content: "", year: "" }],
  },
})(FormWrapper);

const mapStateToProps = state => ({
  userInfo: state.auth.userInfo
});
const mapDispatchToProps = dispatch => ({
  setFormType: formType => dispatch(setFormType({ formType: formType })),
});


FormWrapper = connect(mapStateToProps, mapDispatchToProps)(FormWrapper);

export default withRouter(FormWrapper);