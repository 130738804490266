import React, {useEffect, useState} from 'react'
import ClaimEmail from './claimemail';
import CliamAbout from './claimabout'
import Claimprofile from './claimprofile'
import ClaimProfile from './claimprofile';

function claimmain({value, onValueChange}) {
    const [claimStatus, setClaimStatus] = useState(1);
    const [organizationName, setOrganizationName] = useState('');
    const [email, setEmail] = useState('');
    const [companyUrl, setCompanyUrl] = useState('');
    console.log(value)

    useEffect(() => {
      if (value) {
        setCompanyUrl(value.url)
        setOrganizationName(value.name)

        setClaimStatus(2)
        onValueChange(null)
      }
    },[value])
  return (
    <div>
        {
            claimStatus === 1 ? 
            <ClaimProfile setClaimStatus={setClaimStatus} setOrganizationName={setOrganizationName} setCompanyUrl={setCompanyUrl}/> :
            claimStatus === 2 ?
            <ClaimEmail setClaimStatus={setClaimStatus} setEmail={setEmail} companyUrl={companyUrl}/>  :
            <CliamAbout setClaimStatus={setClaimStatus} organizationName={organizationName} email={email} companyUrl={companyUrl}/>
        }
        {/* <claimemail setClaimStatus={setClaimStatus}/>
        <Claimprofile setClaimStatus={setClaimStatus}/>     */}
    </div>
  )
}

export default claimmain