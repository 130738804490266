import React, { Component } from 'react';
import "../css/BrowsingTech.css";
import { httpfyUrl } from "../../src/utils/helpers";
import '../css/Cards.css';
import { getFormattedDateTime } from '../components/helper-functions/helper'
import DOMPurify from 'dompurify';


class Report extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dateTime: "",

        }

    }
    truncateString(myString) {
        console.log(myString.length)
        if (myString.length > 400) {
            var myTruncatedString = myString.substring(0, 400);
            return myTruncatedString + "..."
        }
        else {
            return myString
        }
    }

    downloadPdf = (url, pdfName) => {
        fetch(url).then((response) => {
            response.blob().then((blob) => {
                // Creating new object of PDF file
                const fileURL =
                    window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = pdfName;
                alink.click();
            });
        });
    };
    ;

    render() {

        console.log(this.props.data)
        return (
            <>

                <div>
                    <p className='techarea_heading'>REPORTS({this.props.count})</p>
                    {this.props.data && this.props.data.length > 0 ?
                        <div>
                            {this.props.data &&
                                this.props.data.map(
                                    (obj, index) => (
                                        <div
                                            className="techarea__report_desc-card"

                                        >
                                            <div className="techarea__desc-card-top">

                                                <div className="techarea__desc-card-left">

                                                    <div className="techarea__desc-card-head">{obj.title}</div>


                                                    {obj.body &&
                                                        <div
                                                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(obj.body) }}
                                                        />}

                                                    <p className='techarea__desc-card-date'>{getFormattedDateTime(obj.publish_date)}</p>
                                                </div>
                                                <div>
                                                    <a
                                                        // href={httpfyUrl(obj.link)}
                                                        className="techarea__desc-card-link"
                                                        onClick={(e) => {
                                                            // handleClick(e);
                                                            this.downloadPdf(obj.link, obj.title);
                                                        }}
                                                    >
                                                        <i className="fas fa-file-pdf" style={{ color: "#F40F02", fontSize: "2rem" }} />
                                                    </a></div>
                                            </div>
                                        </div>
                                    )
                                )}
                        </div> : <div
                            className="techarea__desc-card"

                        >
                            <div className='techarea__card'>
                                <div>
                                    <p className='techarea__no_data'>
                                        The Report Information is coming soon!
                                    </p>
                                    <p>
                                        Once available, you can view the Report
                                        information here.
                                        <br />
                                        Stay Tuned!
                                    </p>
                                </div>
                            </div>
                        </div>}
                    {this.props.count > 10 && this.props.pageCount != this.props.totalPage ?
                        <div>
                            <button className='button-spotlight' id="1" onClick={this.props.onChangeHandler}>SEE MORE</button>
                        </div> : ''}
                </div>

            </>
        );
    }
}

export default Report;