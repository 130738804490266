import React, { Component } from "react";
 
import { withRouter } from 'react-router-dom';
import { toast } from "react-toastify";
import { setUserInfo } from "../../actions/auth";
import { connect } from 'react-redux';
import api from "../../api";
import Contributor1 from "../Contributor/Contributor1";
import Contributor2 from "../Contributor/Contributor2";
import Contributor3 from "../Contributor/Contributor3";

import "../../css/UserForm.css";
import "../../css/Form.css";

class BeContributor extends Component {
  state = {
    formPart: 1,
    uploaded: false
  };

  componentDidMount() {
    // if user isn't logged in, show a warning toast and redirect to home page
    const user = JSON.parse(localStorage.getItem("user"));   

    if (!user || !user.token) {
      toast.warning('Please login first');
      this.props.history.push('/');
      return;
    }
  }

  onSubmit = (formData) => {
    
    //console.log(formData.file.base64)
    //  console.log(localStorage.getItem("image"))
    let profileimagefile= ""
    let profileimagefilename=""
    if ("picture" in formData){
       profileimagefile= formData.picture.base64
       profileimagefilename= formData.picture.name
    }
    const requestBody = {

      fullname: formData.full_name,
      organization: formData.organization,
      email: formData.email,
      designation: formData.designation,
      contact_number: formData.contributor_number,
      profile_image_file: profileimagefile,
      file: formData.file.base64,
      techarea: formData.tech_area.value,
      type: formData.contribution.value,
      file_name: formData.file.name,
      profile_image_file_name: profileimagefilename
    };
    const url = `${api.becomeContributorApi}`;
    const requestConfig = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "TCRAUTH": this.props.userInfo.token,
      },
      body: JSON.stringify(requestBody),
    };


    fetch(url, requestConfig)
      .then(response => {
        if (response && response.ok) return response.json();
        throw new Error("failed");
         
      })
      .then(data => {
        if(data.data===true){
          this.setState({
          uploaded:true
          })
        }
      })
      .catch(err => {
        console.log(err);
      });

  }

  nextPage = () => {
    this.setState(prevState => ({
      formPart: prevState.formPart + 1,
    }));
  };

  prevPage = () => {
    this.setState(prevState => ({
      formPart:
        prevState.formPart > 1
          ? prevState.formPart - 1
          : prevState.formPart,
    }));
  };

  // Check for contact number and send OTP if present, otherwise submit form directly

  render() {
  //  console.log(Object.keys(this.props.userInfo).length)
    return (
      <div>
        {/* Left half of signup-form */}
        <div>
          <div>
          </div>

        </div>
        {/* End of left half of signup-form */}

        {this.state.formPart === 1 && (
          <Contributor1
            onSubmit={this.nextPage}
          />
        )}
        {this.state.formPart === 2 && (
          <Contributor2
            // onSubmit={this.nextPage}
            onSubmit={this.nextPage}
            prevPage={this.prevPage}
          />
        )}
        {this.state.formPart === 3 && (
          <Contributor3
            prevPage={this.prevPage}
            onSubmit={this.onSubmit}
            uploaded={this.state.uploaded}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.auth.userInfo
});

const mapDispatchToProps = dispatch => ({
  setUserInfo: userInfo => dispatch(setUserInfo({ userInfo: userInfo })),
});

BeContributor = connect(mapStateToProps, mapDispatchToProps)(BeContributor);
export default withRouter(BeContributor);
 
