import React from 'react';

//  User defined components import
import EntitySelectorRadio from './EntitySelectorRadio';

//  Asset imports  - Import the radio button images here
import industryIcon from '../../img/industry.svg';


//  Array to map over and generate EntitySelectorRadio components
const entityTypeOptions = [
  { 
    title: 'Industry',
    value: 'company',
    'description': 'Companies, Startups, NGOs, Govt. Organisations etc.',
  },
  { 
    title: 'Academia',
    value: 'academia',
    'description': 'Educational and Academic Institutions like IIT, IIM etc.',
  },
  { 
    title: 'Individual',
    value: 'individual',
    'description': 'Professors, Researchers, Academicians etc.',
  },
  { 
    title: 'R&D',
    value: 'r_and_d',
    'description': 'Research and forensic labs like FSSAI, CRL etc.',
  },
];



const EntitySelector = props => {

  const entitySelectors = entityTypeOptions.map(option => {
    return <EntitySelectorRadio
      key={option.title}
      name='entityType'
      title={option.title}
      value={option.value}
      description={option.description}
      image={industryIcon}
      checked={props.selectedType === option.value}
      changeEntityType={props.changeEntityType}
    />;
  });


  return (
    <div className="feature_form__wrapper">
      <p className="feature__form__subhead"
      >Step 1 of {props.totalParts}</p>

      <p className="feature_form__head"
      >Select An Entity Type</p>

      <div className="feature_form__inner-wrapper">
        {entitySelectors}
      </div>

      <div className="feature_form__btn-wrapper">
        <button
          onClick={props.nextPage}
          className="feature_form__btn"
        >CONTINUE</button>
      </div>

    </div>
  );
}

export default EntitySelector;