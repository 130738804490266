import React from "react";

import EntityItemCard from "./EntityItemCard";

class OfferingsSection extends React.Component {
    componentDidMount() {
        if (this.props.items && this.props.items.length > 0) {
            this.setState({ showCount: Math.min(3, this.props.items.length) });
        }
        console.log('props of section = ', this.props.websiteUrl);
    }

    render() {
        const {
            items,
            setExpandedItem,
            expandedItem,
            title,
            filteredCount,
            showingAll,
        } = this.props;

        let itemsToShow = null;
        let showMoreButton;

        if (items && items.length > 0) {
            itemsToShow = items.map((item, index) => (
                <EntityItemCard
                    key={item.name + index}
                    item={item}
                    title="Product"
                    onClick={() => setExpandedItem(item.name)}
                    setExpandedItem={setExpandedItem}
                    isExpandedItem={item.name === expandedItem}
                    websiteUrl={this.props.websiteUrl}
                />
            ));
        }

        showMoreButton = (
            <div
                onClick={this.props.toggleShowAllOfferings}
                className="entitview__cards--show-more-btn"
            >
                {showingAll ? "Show Less" : "Show More"}
            </div>
        );

        //  Hide the button if offerings are less than 3
        if (filteredCount < 4) showMoreButton = null;
        // if (showingAll && itemsToShow.length <= totalCount) showMoreButton = null;

        return (
            <div>
                <p className="entityview__desc-head--offerings">
                    {title}s
                    <span className="entityview__desc-head--offerings-subtext">
                        Showing {itemsToShow.length} out of {filteredCount}{" "}
                        {title}s
                    </span>
                </p>

                <div className="entityview__desc-cards-wrapper">
                    {itemsToShow}
                </div>

                {showMoreButton}
            </div>
        );
    }
}

export default OfferingsSection;
