import React from 'react';
import { reduxForm, Field } from 'redux-form';

//  Redux form field component imports
import FormInputField from '../../feature_yourself-components/form-elements/FormInputField';
import FormSelect from '../../feature_yourself-components/form-elements/FormSelect';
import FormSelectAsyncCreatable from '../../feature_yourself-components/form-elements/FormSelectAsyncCreatable';
import FormTextAreaField from '../../feature_yourself-components/form-elements/FormTextAreaField';
 
import validate from '../validate';

class IndustryForm1 extends React.Component {

  getSimilarCompanies = (input, cb) => {
    let options = [];

    if (input.length > 2) {
      fetch(
        `${
        process.env.REACT_APP_API_URL
        }/api/ra/search/company_lookup/`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: input,
          }),
        }
      )
        .then(response => {
          // console.log(response);
          if (response.status !== 200) {
            console.log(
              "Looks like there was a problem. Status Code: " +
              response.status
            );
            return;
          }

          // Examine the text in the response
          response.json().then(response => {
            options = response.data.map(company => ({
              value: company.name,
              label: company.name,
              id: company.id,
              className: "select-option",
            }));
            // console.log(options);
            cb(options);
            return;
          });
        })
        .catch(err => {
          console.log("Cannot retrieve Data:", err);
        });
    } else {
      cb(options);
    }
  };

  render() {
    // console.log('if1 props = ', this.props);
    const { totalParts, handleSubmit, onSubmit, companySizes, companyTypes } = this.props;

    return (
      <div className="feature_form__wrapper">
        <p className="feature__form__subhead"
        >Step 3 of {totalParts}</p>

        <p className="feature_form__head"
        >About Company</p>

        <div className="feature_form__inner-wrapper">
          <form
            autoComplete='off'
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="feature_form__inner-wrapper">
              <Field
                name="name"
                component={FormSelectAsyncCreatable}
                label="Company’s Legal Name"
                placeholder="E.g Reliance Industries Limited"
                selectConfig={{
                  isClearable: true,
                  isOptionDisabled: option => {
                    if (option.label.indexOf("Create"))
                      return true;
                  },
                }}
                loadOptions={this.getSimilarCompanies}
              />

              <Field
                name="alias"
                // widthClass="col-md-6"
                component={FormInputField}
                type="text"
                placeholder="Eg. Reliance"
                label="Company’s Alias"
                tabable={true}
              />

              <Field
                name="url"
                // widthClass="col-md-6"
                component={FormInputField}
                type="text"
                placeholder="Enter the URL of the company"
                label="Company’s Website"
                tabable={true}
              />

              <Field
                name="type"
                component={FormSelect}
                placeholder="Select company type"
                label="Type of company"
                valueType="string"
                //type="text"
                 
                options={companyTypes.map(type => ({
                  label: type,
                  value: type,
                }))}
              />

              <Field
                name="size"
                placeholder="Select"
                label="Number of Employees"
                component={FormSelect}
                options={companySizes.map(type => ({
                  label: type,
                  value: type,
                }))}
                valueType="string"
              />

              <Field
                name="about"
                // widthClass="col-md-6"
                component={FormTextAreaField}
                type="text"
                placeholder="Describe company does, its vision etc."
                label="About"
                tabable={true}
              />

              <Field
                name="offerings_description"
                // widthClass="col-md-6"
                component={FormTextAreaField}
                type="text"
                placeholder="Please give additional details of the top products, services and other offerings, the technologies involved etc."
                label="Top Offerings"
                tabable={true}
              />
            </div>
            <div className="feature_form__btn-wrapper">
              <button
                type='submit'
                className="feature_form__btn"
              >Continue</button>

              <button
                onClick={this.props.prevPage}
                className="feature_form__btn feature_form__btn--back"
              >Previous Page</button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'feature_yourself',
  destroyOnUnmount: false,
  validate,
})(IndustryForm1);