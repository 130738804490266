import React from 'react';
import Empty from '../img/empty_view.svg';
import { withRouter, Redirect } from 'react-router-dom';
import "../css/Message.css";
import { useLocation } from "react-router-dom";
import api, { getGETConfig, getAuthGETConfig } from "../api";
import { connect } from 'react-redux';
import NewsSignal from './NewsSignal';
import Case from './CaseStudy';
import Summary from './Summary'
import Spotlight from './Spotlight';
import EventsWebinar from './EventsWebinar';
import { getFormattedDateTime } from '../components/helper-functions/helper'
import PolicyUpdates from './PolicyUpdates';
import { object } from 'prop-types';
import ExternalContent from './ExternalContent'
class ResourceContent extends React.Component {

  state = {
    newsSignal: [],
    caseStudyDetails: [],
    spotlightDetails: [],
    summaryDoc: [],
    eventWebinarData: [],
    eventType: "",
    policyData: [],
    articleData: [],
    externalData: [],
    counterNews:1,
    counterArticle:1,
    breadCumbName : "",
  }

  componentDidMount() {
    var pathList = this.props.location.pathname.split('/')
   // console.log(pathList[2])
    const user = JSON.parse(localStorage.getItem("user"));
    if (user != null) {
      var token = user.token
    }

    if (pathList[2] === "signal_news") {
      this.setState({breadCumbName:"Signals and News"})
      this.getNews(token)
    }
    if (pathList[2] === "case_study") {
      this.setState({breadCumbName:"Case study"})
      this.getCaseStudy(token)
    }
    if (pathList[2] === "spotlight") {
      this.setState({breadCumbName:"Spotlight"})
      this.getSpotlight(token)
    }
    if (pathList[2] === "summary_documents") {
      this.setState({breadCumbName:"Summary Documents"})
      this.getSummaryDocs(token)
    }
    if (pathList[2] === "events_and_webinar") {
      this.setState({breadCumbName:"Event and Webinar"})
      this.getEventWebinar(token)
    }

    if (pathList[2] === "policy_updates") {
      this.setState({breadCumbName:"Policy Updates"})
      this.getPolicy(token)
    }
    if (pathList[2] === "featured_article") {
      this.setState({breadCumbName:"Featured Article"})
      this.getArticleData(token)
    }

    if (pathList[2] === "external_content") {
      this.setState({breadCumbName:"External Content"})
      this.getTrendingContent(token)
    }
  }
  toggleEventType = async (e) => {
    var eventId = e.target.id
    this.setState({
      eventType: eventId
    }, () => {
      this.getEventWebinar()
    })
  }

  toggleArticle = async (e) => {
    var  count= this.state.counterArticle + 1
    this.setState({
      counterArticle: count
    }, () => {
      this.getArticleData()
    })
  }
  toggleNews=()=>{
    var  count= this.state.counterNews + 1
      this.setState({
        counterNews:count
      },()=>{
        this.getNews()
      })  
    }
  getTrendingContent = (token) => {
    const url = `${api.externalContent}`;
    fetch(url, getAuthGETConfig(token))
      .then(response => {
        if (!response || !response.ok) throw Error(response.status);
        return response.json();
      })
      .then((data) => {

        this.setState({ trendingData: data })
      })
      .catch(err => console.log('Error occured while fetching trending list', err));
  }

  getArticleData = (token) => {
    //const pageSize = window.btoa(10)
    const pageSize = 10
    const url = `${api.articleApi}?tech_area=all&page=${this.state.counterArticle}&page_size=${pageSize}`;
    fetch(url, getAuthGETConfig(token))
      .then(response => {
        if (!response || !response.ok) throw Error(response.status);
        return response.json();
      })
      .then((data) => {

        this.setState({ articleData: data })
      })
      .catch(err => console.log('Error fetching all artical data', err));
  }

  getSummaryDocs = (token) => {
    const url = `${api.summary}?tech_area=all&page=1&page_size=10`;
    fetch(url, getAuthGETConfig(token))
      .then(response => {
        if (!response || !response.ok) throw Error(response.status);
        return response.json();
      })
      .then((data) => {

        this.setState({ summaryDoc: data })
      })
      .catch(err => console.log('Error fetching all recently add data', err));
  }

  getPolicy = (token) => {
    const url = `${api.policyUpdates}?tech_area=all&page=1&page_size=10`;
    fetch(url, getAuthGETConfig(token))
      .then(response => {
        if (!response || !response.ok) throw Error(response.status);
        return response.json();
      })
      .then((data) => {

        this.setState({ policyData: data })
      })
      .catch(err => console.log('Error fetching all recently add data', err));
  }

  getEventWebinar = (token) => {
    const url = `${api.eventsWebinar}?tech_area=all&event_type=${this.state.eventType}`;
    fetch(url, getAuthGETConfig(token))
      .then(response => {
        if (!response || !response.ok) throw Error(response.status);
        return response.json();
      })
      .then((data) => {

        this.setState({ eventWebinarData: data })
      })
      .catch(err => console.log('Error fetching all recently add data', err));
  }

  getNews = (token) => {
    const requestConfig = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'TCRAUTH': token
      }
    };

    const url = `${api.signalNews}?tech_area=all&page=${this.state.counterNews}&page_size=10`;
    fetch(url, requestConfig).then(response => {
      if (!response || !response.ok) {
        console.log('Could not fetch current credits', response.status);
        return;
      }
      response.json().then(response => {
        //  console.log(response);
        this.setState({
          newsSignal: response
        });
      })

    }).catch(err => {
      console.log('Error fetching current credits', err);

    });

  }

  getCaseStudy = (token) => {
    const requestConfig = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'TCRAUTH': token
      }
    };

    const url = `${api.caseStudy}?tech_area=all&page=1&page_size=10`;
    fetch(url, requestConfig).then(response => {
      if (!response || !response.ok) {
        console.log('Could not fetch current credits', response.status);
        return;
      }
      response.json().then(response => {
        // console.log(response);
        this.setState({
          caseStudyDetails: response
        });
      })

    }).catch(err => {
      console.log('Error fetching current credits', err);

    });

  }

  getSpotlight = (token) => {
    const requestConfig = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'TCRAUTH': token
      }
    };

    const url = `${api.spotlight}?tech_area=all&page=1&page_size=10`;
    fetch(url, requestConfig).then(response => {
      if (!response || !response.ok) {
        console.log('Could not fetch current credits', response.status);
        return;
      }
      response.json().then(response => {
        //  console.log(response);
        this.setState({
          spotlightDetails: response
        });
      })

    }).catch(err => {
      console.log('Error fetching current credits', err);

    });

  }

  render() {
    
     
    let news = null
    let caseData = null
    let spotlightData = null
    let summaryData = null
    let eventData = null
    let policyData = null
    let featurearticleData = null
    let externalContentData = null
    if (this.state.newsSignal && Object.keys(this.state.newsSignal).length > 0) {
      news = (
        <div>
          <NewsSignal
            data={this.state.newsSignal['data']}
            count={this.state.newsSignal['total_count']}
            pageCount={this.state.counterNews}
            title="signal and news"
            totalPage = {this.state.newsSignal["page_count"]}
            onChangeHandler={this.toggleNews} />
        </div>
      );
    }
    if (this.state.eventWebinarData && Object.keys(this.state.eventWebinarData).length > 0) {
      eventData = (
        <EventsWebinar 
          data={this.state.eventWebinarData["data"]}
          activeState = {this.state.eventWebinarData["event_type"]}
          eventCss="resource_events_webinar"
          onChangeHandler={this.toggleEventType}
          eventType={this.state.eventType} />
      )
    }
    if (this.state.trendingData && Object.keys(this.state.trendingData).length > 0) {
      externalContentData = (
        <ExternalContent
          data={this.state.trendingData['data']}
          count={this.state.trendingData['data'].length }
        />
      )
    }
    if (this.state.policyData && Object.keys(this.state.policyData).length > 0) {
      policyData = (
        <PolicyUpdates data={this.state.policyData["data"]}
          count={this.state.policyData['total_count']}
          pageCount={this.state.counterPolicy}
          onChangeHandler={this.togglePolicy} />
      );
    }

    if (this.state.caseStudyDetails && Object.keys(this.state.caseStudyDetails).length > 0) {
      // console.log(this.state.caseStudyDetails)
      caseData = (
        <Case data={this.state.caseStudyDetails['data']}
          count={this.state.caseStudyDetails['total_count']}
          caseStudyCss="caseStudy" />
      );
    }
    if (this.state.summaryDoc && Object.keys(this.state.summaryDoc).length > 0) {

      summaryData = (
        <Summary data={this.state.summaryDoc["data"]}
          count={this.state.summaryDoc["total_count"]}
        />
      );
    }
    if (this.state.spotlightDetails && Object.keys(this.state.spotlightDetails).length > 0) {
      spotlightData = (
        <Spotlight data={this.state.spotlightDetails['data']}
          count={this.state.spotlightDetails['total_count']}
          title="SPOTLIGHT"
          pageCount={this.state.counterSpotlight}
          totalPage = {this.state.spotlightDetails["page_count"]}
          onChangeHandler={this.toggleSpotlight}
        />
      );
    }
    if (this.state.articleData && Object.keys(this.state.articleData).length > 0) {

      featurearticleData = (
        <NewsSignal data={this.state.articleData["data"]}
          count={this.state.articleData['total_count']}
          title="Feature Article"
          pageCount={this.state.counterArticle}
          totalPage = {this.state.articleData["page_count"]}
          onChangeHandler={this.toggleArticle}
        />
      );
    }
    document.title =`${this.state.breadCumbName} - Technology Capability Repository || DSCI`;
    document.getElementsByTagName("LINK")[0].href=  `https://www.techsagar.in/resouces`

    return (
      <div>
        <div className="entityview__top">
          <div className="entityview__top-desc-wrapper">
        {/*  <nav aria-label="Breadcrumb" class="breadcrumb">
              <ul>
                <li><a href="/">Home</a></li>
                <li><a href ="/resources">Resources</a></li>
                <li><span aria-current="page">{this.state.breadCumbName}</span></li>
              </ul>
    </nav>*/}
            <p className='resouces__heading'>{this.state.breadCumbName}</p>
          </div>
        </div>
        <div className='resource_container'>
          {news}
          {caseData}
          {spotlightData}
          {summaryData}
          {eventData}
          {policyData}
          {featurearticleData}
          {externalContentData}
        </div>
      </div>


    )
  }
}


const mapStateToProps = state => ({
  userInfo: state.auth.userInfo
});
ResourceContent = connect(mapStateToProps, null)(ResourceContent);

export default withRouter(ResourceContent);