
import React, { useEffect, useRef, useState } from "react";
import { fetchFoamData } from "./tree-view-asset/js/tree-view-1.js";
import { deepClone, initialData } from "./common/common.js";
import FoamTree from '../components/FormTree/TreeView.js';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import ReplayIcon from '@mui/icons-material/Replay';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import usePreventZoom from "./UseZoomPrevention.js"; 
import InfoIcon from '@mui/icons-material/Info';
import { FontDownloadOffTwoTone } from "@mui/icons-material";

function FoamTreeView({ techarea }) {
   
  const foamtree = useRef();
  const [modelData, setModelData] = useState(deepClone(initialData)); // initialLoadingData(mutable)
  const [loadingData, setLoadingData] = useState({}); // initialLoadingData(immutable)
  const [isFullscreen, setFullscreen] = useState(false);
  const [technology, setTechnology] = useState(techarea.toLowerCase())
  const [isWheelScroll, setWheelScroll]=useState(false);

  useEffect(() => {
    let promise = [];
    promise[0] = fetchFoamData(technology, "researcher", 1); // initial company data loading
    Promise.all(promise).then((aggregatedData) => {
      Object.entries(aggregatedData).map(([keyAgg, valueAgg]) => {
        let k = 0;
        Object.entries(valueAgg.data).map(([keyValAgg, valueValAgg]) => {
          modelData.groups[0].groups[k] = {};
          modelData.groups[0].groups[k].label = valueValAgg;
          modelData.groups[0].groups[k].weight = 8;
          modelData.groups[0].groups[k].level = 2;
          modelData.groups[0].groups[k].groups = [];
          k++;
        });
      });

      if (aggregatedData[0].data.length > 15) {
        modelData.groups[0].weight = 50;
      } else {
        modelData.groups[0].weight = 20;
      }
      modelData.open = true;
      modelData.exposed = true;
      setModelData(modelData);
      let updatedData = deepClone(modelData);
      setLoadingData(updatedData);

      // foamtree.current.set("dataObject", modelData);
    });
  }, []);

  // foamtree double click event handler
  const handleGroupDClick = (e) => {
    e.preventDefault();
    let selectedArea = "";
    selectedArea = e.group.label;
    let fetchLabel = e.group.fetchLabel;
    let level = e.group.level;
    let capabilites = [];
    var promise = [];
    var pindex = 0;
    var researcherPromise = [];
    /*Level 1 Start*/
    if (level == 1 && e.group.groups.length == 0) {
      e.group.label = "Loading...";
      foamtree.current.update();
      promise[0] = fetchFoamData(technology, fetchLabel.toLowerCase(), 1);
      Object.entries(modelData.groups).map(([key, value]) => {
        value.open = false;
        value.exposed = false;
        value.selected = false;
        value.weight = 10;
        Object.entries(value.groups).map(([key2, value2]) => {
          value2.open = false;
          value2.exposed = false;
          value2.selected = false;
        });
      });
      Promise.all(promise).then((aggregatedData) => {
        //console.log("aggregatedData", aggregatedData);
        Object.entries(aggregatedData).map(([keyAgg, valueAgg]) => {
          let k = 0;
          Object.entries(valueAgg.data).map(([keyValAgg, valueValAgg]) => {
            e.group.groups[k] = {};
            e.group.groups[k].label = valueValAgg;
            e.group.groups[k].weight = 8;
            e.group.groups[k].level = 2;
            e.group.groups[k].groups = [];
            k++;
          });
        });
        if (aggregatedData[0].data.length > 15) {
          e.group.weight = 50;
        } else {
          e.group.weight = 20;
        }
        e.group.label = selectedArea;
        e.group.open = true;
        e.group.exposed = false;
        e.group.selected = true;
        foamtree.current.update();
        //console.log("modelData", modelData);
        foamtree.current.set("dataObject", modelData);
      });
    } else if (level == 1 && e.group.groups.length > 0) {
      Object.entries(modelData.groups).map(([key, value]) => {
        value.open = false;
        value.exposed = false;
        value.selected = false;
        value.weight = 10;
        Object.entries(value.groups).map(([key2, value2]) => {
          value2.open = false;
          value2.exposed = false;
          value2.selected = false;
        });
      });

      if (e.group.groups.length > 15) {
        e.group.weight = 50;
      } else {
        e.group.weight = 20;
      }
      e.group.open = true;
      e.group.exposed = true;
      e.group.selected = true;

      foamtree.current.update();
      foamtree.current.set("dataObject", modelData);
    }
    /*Level 1 Ended here*/
    /*Level 2 Start*/
    if (level == 2 && e.group.groups.length == 0) {
      e.group.label = "Loading...";
      var techarea = e.bottommostOpenGroup.fetchLabel;
      var parentLabel = e.bottommostOpenGroup.fetchLabel;
      foamtree.current.update();

      promise[0] = fetchFoamData(
        technology,
        parentLabel,
        2,
        selectedArea.toLowerCase()
      );

      Object.entries(modelData.groups).map(([key, value]) => {
        value.open = false;
        value.exposed = false;
        value.selected = false;

        Object.entries(value.groups).map(([key2, value2]) => {
          value2.open = false;
          value2.exposed = false;
          value2.selected = false;
          value2.weight = 2;
        });
      });
      Promise.all(promise).then((aggregatedData) => {
        let k = 0;
        Object.entries(aggregatedData).map(([keyAgg, valueAgg]) => {
          let k = 0;
          Object.entries(valueAgg.data).map(([keyValAgg, valueValAgg]) => {
            e.group.groups[k] = {};
            e.group.groups[k].label = valueValAgg.name;
            e.group.groups[k].weight = 1;
            e.group.groups[k].level = 3;
            e.group.groups[k].groups = [];
            e.group.groups[k].parentLabel = parentLabel;
            k++;
          });
        });

        if (aggregatedData[0].data.length > 10) {
          e.group.weight = 40;
        } else {
          e.group.weight = 15;
        }
        e.group.label = selectedArea;
        e.group.open = true;
        e.group.exposed = true;
        e.group.selected = true;
        foamtree.current.update();
        foamtree.current.set("dataObject", modelData);
      });
    } else if (level == 2 && e.group.groups.length > 0) {
      Object.entries(modelData.groups).map(([key, value]) => {
        value.open = false;
        value.exposed = false;
        value.selected = false;

        Object.entries(value.groups).map(([key2, value2]) => {
          value2.open = false;
          value2.exposed = false;
          value2.selected = false;
          value2.weight = 2;
        });
      });

      if (e.group.groups.length > 10) {
        e.group.weight = 40;
      } else {
        e.group.weight = 15;
      }
      e.group.open = true;
      e.group.exposed = true;
      e.group.selected = true;
      foamtree.current.update();
      foamtree.current.set("dataObject", modelData);
    }
    /*Level 2 Ended Here*/
    /*Level 3 start*/
    if (level == 3) {
      console.log(techarea);
      let name = e.group.label;
      let parentLabel = e.group.parentLabel;
      if (parentLabel == "academia") {
        parentLabel = "institute";
      } else if (parentLabel == "r_and_d") {
        parentLabel = "rd-centre";
      }
      let url =
      "https://www.techsagar.in/" +
        technology.replace(/ /g, "_") +
        "/" +
        e.group.label.replace(/ /g, "_");
      window.open(url, "_blank").focus();
    }
    /*Level 3 Ended Here*/
  };

  // foamtree zooomout handler
  const ZoomOutHandler = () => {
    foamtree.current.reset();
  };

  // foamtree reload handler 
  const ReloadHandler = () => {
    setModelData(deepClone(loadingData));
  };

  // Event handler callback for zoom in
  function handleZoomIn() {
    let element = document.getElementById('mainbox');

    let isFullScreen = document.fullscreenElement;

    if (isFullScreen) {
      document.exitFullscreen();
      setFullscreen(false);
    } else {
      element.requestFullscreen();
      setFullscreen(true);
    }
  }

  let wheelContainer=document.getElementById('wheel_scroll');
  let setTimerId=null;
  function wheelEvent(){
    clearTimeout(setTimerId)
    if(!isWheelScroll){
      wheelContainer.setAttribute("class","wheel_scroll");
      setWheelScroll(true);
    }
    setTimerId = setTimeout(onMouseLeave, 5000);
  }

// onmouseleave event handle
  function onMouseLeave(){
    setWheelScroll(false);
    wheelContainer.style.display='block';
    wheelContainer.classList.remove('wheel_scroll');
    wheelContainer.classList.add('shadow_box');
  }
// onkeypress event handle
document.onkeydown = function (event) {
  if(event.ctrlKey){
    wheelContainer.style.display='none';
    setWheelScroll(false);
  }
};
// onkeyup event handle
document.onkeyup = function () {
    wheelContainer.style.display='block';
    wheelContainer.classList.remove('wheel_scroll');
    wheelContainer.classList.add('shadow_box');
    setWheelScroll(false);
};
// double click event handle
function onDoubleClick(){
  setWheelScroll(false);
  wheelContainer.setAttribute("class","");
}


  return (
    
    <div style={{position:'relative'}}  tabIndex={3} onMouseLeave={onMouseLeave} onDoubleClick={onDoubleClick} onWheel={wheelEvent}>
    {isWheelScroll && <p id="scroll_message" style={styles.mapfoamtreeSugestionStyle}><InfoIcon className="info-icon"
    style={{width:"2.1rem", height:"2.1rem", marginRight:"0.2rem",fontSize:"1.8rem"}} />&nbsp;Use ctrl + scroll to zoom foamtree</p>}
    <div id="wheel_scroll" className="shadow_box" >
    </div>
    <div className="mainBox" id="mainbox" >
        {(modelData && modelData['groups'][0].groups.length>0) ? (<FoamTree handleGroupDClick={handleGroupDClick} onMouseLeave={onMouseLeave}  zoomMouseWheel={()=>{}} modelData={modelData} foamtree={foamtree} />) : null}
      <div className="zoom_button_box" style={styles.iconAlignmentStyle}>
      {isFullscreen ? <span title="Exit"><FullscreenExitIcon className="fullscreen_exit_icon" style={styles.iconStyle} onClick={()=>handleZoomIn()} /></span>
        : <span title="Fullscreen"><FullscreenIcon className="fullscreen_icon" style={styles.iconStyle} onClick={() => handleZoomIn()}/></span>}
        <span title="Zoom out"><ZoomInMapIcon className="zoom_icon" onClick={(e) => ZoomOutHandler(e)} style={styles.iconStyle} /></span>
        <span title="Reload"><ReplayIcon className="replay_icon" onClick={(e) => ReloadHandler(e)} style={styles.iconStyle} /></span>
       
      </div>
    </div>
  </div>
  );
}


export default FoamTreeView;



const styles={
  iconStyle:{
    width:"4rem", 
    height:"3.8rem"
  },
  iconAlignmentStyle:{
    display:"flex",
    flexDirection:"column",
    zIndex:"100",
  }, 
  mapfoamtreeSugestionStyle:{
    zIndex:99,
    background:"transparent",
    color:"#fff", 
    fontFamily:'sans-serif',
    position:"absolute",
    top:"1%",
    left:"0%",
    
    boxSizing:"border-box",
    padding:"0.8% 1.6%",
    display:"flex",
    alignItems:"center",
    fontSize : "1.8rem",
  }
}

