import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../css/claimprofile.css";
import api from '../../api';

function ClaimAbout(props) {
  const { organizationName, authToken, email: initialEmail, companyUrl } = props; // Get the email from props
  const [name, setName] = useState('');
  const [designation, setDesignation] = useState('');
  const [contact, setContact] = useState('');
  const [organization, setOrganization] = useState('');
  const [email, setEmail] = useState(initialEmail); // Initialize the email state with the passed email
  // const [domain, setDomain] = useState('');
  const [nameError, setNameError] = useState('');
  const [designationError, setDesignationError] = useState('');
  const [urlError, setUrlError] = useState('');
  const history = useHistory();

  // useEffect(() => {
  //   if (companyUrl.length > 0) {
  //     setDomain(companyUrl);
  //   }
  // },[companyUrl])

  useEffect(() => {
    setOrganization(organizationName);
  }, [organizationName]);
  console.log(props.organizationName)
  console.log(props.email)
  console.log(props.companyUrl)

  const isUrlValid = (string) => {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    let hasError = false;
    if (!name) {
      setNameError('Please enter the Name.');
      hasError = true;
    } else {
      setNameError('');
    }
  
    if (!designation) {
      setDesignationError('Please enter the Designation.');
      hasError = true;
    } else {
      setDesignationError('');
    }
  
    if (!isUrlValid(companyUrl)) {
      setUrlError('The Company URL is incorrect.');
      hasError = true;
    } else {
      setUrlError('');
    }
  
    if (hasError) {
      return;
    }
  
    try {
      // Fetch the CSRF token
      const csrfResponse = await fetch(`${process.env.REACT_APP_API_URL_CMS}/session/token`);
      if (!csrfResponse.ok) throw new Error('Failed to fetch CSRF token');
      const csrfToken = await csrfResponse.text();
  
      let domainName = new URL(companyUrl).hostname;
      const payload = {
        name,
        email,
        designation,
        organization,
        domain: domainName
      };
  
      const requestConfig = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Auth-Key': process.env.REACT_APP_AUTH_KEY,
          'TCRAUTH': authToken,
          'X-CSRF-Token': csrfToken // Include the CSRF token here
        },
        body: JSON.stringify(payload)
      };
  
      const response = await fetch(api.claim_profile_detail, requestConfig);
      if (!response.ok) throw new Error(response.status);
      const data = await response.json();
  
      console.log('Successfully submitted the claim request:', data);
      toast.success('Successfully submitted the claim request', {
        position: "bottom-right",
        autoClose: 5000
      });
      setTimeout(() => {
        history.push('/');
      }, 2000); // Redirect after 2 seconds
    } catch (error) {
      console.error('Error submitting the claim request:', error);
      toast.error('Error submitting the claim request', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  

  return (
    <div className='claimabout__container'>
      <ToastContainer />
      <div className='claimprofile_about_form'>
        <p className='claimprofile_form__head'>About Company</p>
        <form style={{ display: 'flex', flexDirection: 'column', gap: '10px' }} onSubmit={handleSubmit}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>
              Organization:
            </label>
            <input
              style={{ width: '400px' }}
              className='feature_form__input-field'
              placeholder='Eg. TechSagar'
              type="text"
              value={organization}
              readOnly
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>
              Name:
            </label>
            <input
              className='feature_form__input-field'
              placeholder='Eg. John'
              style={{ width: '400px' }}
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          {nameError && <span style={{color: '#e05151'}} className='error-message'>{nameError}</span>}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>
              Designation:
            </label>
            <input
              style={{ width: '400px' }}
              className='feature_form__input-field'
              placeholder='Eg. CEO'
              type="text"
              value={designation}
              onChange={(e) => setDesignation(e.target.value)}
            />
          </div>
          {designationError && <span style={{color: '#e05151'}} className='error-message'>{designationError}</span>}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>
              Email:
            </label>
            <input
              style={{ width: '400px' }}
              className='feature_form__input-field'
              placeholder='Eg. john@example.com'
              type="text"
              value={email}
              readOnly
            />
          </div>
          {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>
              Domain:
            </label>
            <input
              style={{ width: '400px' }}
              className='feature_form__input-field'
              placeholder='TechSagar.in'
              type="text"
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
            />
          </div> */}
          <button className="feature_form__btn" style={{marginTop: '10px'}} type="submit" >Confirm And Claim</button>
          {urlError && <span style={{ color: '#e05151' }} className='error-message'>{urlError}</span>}
        </form>
      </div>
    </div>
  );
}

export default ClaimAbout;
