import React, { Fragment } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { setUserInfo } from "../actions/auth"

// Components
import ScrollToTopRoute from "./ScrollToTopRoute";
import ErrorBoundary from "./ErrorBoundary";
import Home from "./Home";
// import { ReDesignHome } from "./ReDesignHome";

import SearchResults from "./SearchResults";
import NotFound from "./NotFound";
import BrowsingTech from "./BrowsingTech";
import BrowsingCapability from "./BrowsingCapability";
import EntityView from "./EntityView";
import AboutUs from "./AboutUs";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";
import Disclaimer from "./Disclaimer";
import ContactUs from "./ContactUs";
import ResetPassword from "./ResetPassword";
import FormWrapperOld from "./feature_yourself-components/FormWrapperOld";
import FormWrapper from './feature_yourself-new/FormWrapper';
import PrivacyPolicyMobile from './PrivacyPolicyMobile'
import Dashboard from './Dashboard';
import Payment from "./Payment";
import Notifications from "./dashboard-components/Notifications";
import TermsAndConditions from "./TermsCondition"
import InvestorsView from "./InvestorsView";
import Reports from "./Reports";
import Whitepapers from './Whitepapers';
import MyAccount from "./MyAccount";
import Message from "./Message";
import Resource from "./Resource";
import ResourceContent from "./ResourceContent";
import IdleTimeOutComponent from './IdleTimeOutComponent'
import BecomeContributor from "./BecomeContributor";
import BeContributor from "./user-form-components/BeContributor"
import UserTrack from "./UserTrack";
import { ReDesignHome } from "./ReDesignHome";
import HomeNew from './HomeNew'
import Blog from './Blogs'
import BlogDetail from "./BlogDetail";
import AISearch from "./ai-search/AISearch";
import ClaimProfile from "./claim-profile/claimprofile";
import claimmain from "./claim-profile/claimmain";
import ClaimEmail from "./claim-profile/claimemail"
import ClaimAbout from "./claim-profile/claimabout"


class MainContainer extends React.Component {
  constructor(props) {
    super(props)
    // Initialize state
    this.state = {
      companyDetail: null // You can set an initial value here
    };
    this.updateCompanyDetail = this.updateCompanyDetail.bind(this);
  }
  updateCompanyDetail(newValue) {
    this.setState({ companyDetail: newValue });
  }

  render() {
    console.log(this.state.companyDetail)

    return (
      <div
        style={{
          height: "100%",
        }}
      >

        <IdleTimeOutComponent {...this.props} />
        <UserTrack {...this.props} />

        <ErrorBoundary>
          <Switch>
            <ScrollToTopRoute exact path="/home" component={ReDesignHome} />

            <ScrollToTopRoute exact path="/about_techsagar" component={AboutUs} />
            <ScrollToTopRoute exact path="/privacy_policy" component={PrivacyPolicy} />
            <ScrollToTopRoute exact path="/mobile_privacy_policy" component={PrivacyPolicyMobile} />
            <ScrollToTopRoute exact path="/contact_us" component={ContactUs} />
            <ScrollToTopRoute exact path="/terms_of_service" component={TermsOfService} />
            <ScrollToTopRoute exact path="/mobile_terms_of_service" component={TermsAndConditions} />
            <ScrollToTopRoute exact path="/disclaimer" component={Disclaimer} />
            <ScrollToTopRoute exact path='/NotFound' component={NotFound} />
            <ScrollToTopRoute exact path='/resources' component={Resource} />
            <ScrollToTopRoute exact path='/blogs' component={Blog} />
            <ScrollToTopRoute exact path='/blogs/:link' component={BlogDetail}/>
            <ScrollToTopRoute exact path='/ai_search' component={AISearch}/>
            <ScrollToTopRoute exact path='/claim' component={claimmain}
              compProps={{ value: this.state.companyDetail,
                onValueChange: this.updateCompanyDetail }}/>
            <ScrollToTopRoute exact path='/:type/claim' component={ClaimEmail}/>
            
            <ScrollToTopRoute
              exact
              path="/resources/reports"
              component={Reports}
              compProps={{ toggleUserForm: this.props.toggleUserForm }}
            />

            <ScrollToTopRoute
              exact
              path="/resources/whitepapers"
              component={Whitepapers}
              compProps={{ toggleUserForm: this.props.toggleUserForm }}
            />
            <ScrollToTopRoute exact path='/resources/:resources_type' component={ResourceContent} />



            <ScrollToTopRoute
              exact
              path="/"
              // component={Home}
              // component={ReDesignHome}
              component={HomeNew}
              compProps={{
                handleFeatureYourself: this.props.handleFeatureYourself,
                toggleUserForm: this.props.toggleUserForm,
                displayUserForm: this.props.displayUserForm,
                // showHeader: false,
              }}

            />

            <ScrollToTopRoute
              exact
              path="/resetpassword/:token/:uidb64/:userType/:requestNo"
              component={ResetPassword}
            />

            <ScrollToTopRoute
              exact
              path="/verified_feature/cba9b510e5b467fa185808ecd2481057"
              component={FormWrapperOld}
            />

            <ScrollToTopRoute
              exact
              path={["/search", "/search/technology", "/search/technology/:techarea/capability"]}
              component={SearchResults}
              compProps={{ toggleUserForm: this.props.toggleUserForm }}
            />
            <Route
              exact
              path="/verify_email/:token"
              component={Home}
              compProps={{
                handleFeatureYourself: this.props.handleFeatureYourself,
              }}
            />

            <ScrollToTopRoute
              exact
              path="/technology/:techarea"
              component={withRouter(BrowsingTech)}
              compProps={{
                toggleUserForm: this.props.toggleUserForm
              }}
            />
            <ScrollToTopRoute exact path="/technology/:techareaname/:techcap" component={withRouter(BrowsingCapability)} />

            <ScrollToTopRoute
              // exact
              path={["/:type/:name", "/:techarea/:name", "/:techarea/:capability/:name"]}
              component={withRouter(EntityView)}
              compProps={{
                toggleUserForm: this.props.toggleUserForm,
                displayUserForm: this.props.displayUserForm,
                value: this.state.companyDetail,
                onValueChange: this.updateCompanyDetail
              }}
            />

            <ScrollToTopRoute exact path="/investors/:id" component={InvestorsView} />
            <ScrollToTopRoute exact path="/payments" component={Payment} />


            <ScrollToTopRoute exact path='/become_contributor' component={BeContributor} />

            <ScrollToTopRoute
              exact
              path="/feature_yourself"
              component={FormWrapper}
              compProps={{ displayUserForm: this.props.displayUserForm }}
            />

            {/* <ScrollToTopRoute exact path="/feature_yourself" component={FormWrapper} /> */}


            {this.props.user && (

              <Fragment>
                <ScrollToTopRoute exact path='/notifications' component={Notifications} />
                {/* <ScrollToTopRoute exact path='/my_dashboard/:activities' component={Dashboard} />*/}
                <ScrollToTopRoute exact path='/dashboard' component={Dashboard} />
                <ScrollToTopRoute exact path='/my_account' component={MyAccount} />
                <ScrollToTopRoute exact path='/message' component={Message} />

              </Fragment>

            )}

            <Redirect from='/' to='/NotFound' />
          </Switch>
        </ErrorBoundary>


      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.userInfo
});

const mapDispatchToProps = dispatch => ({
  setUserInfo: userInfo => dispatch(setUserInfo({ userInfo: userInfo })),
});

MainContainer = connect(mapStateToProps, mapDispatchToProps)(MainContainer);
export default withRouter(MainContainer);
