import React, { Component } from "react";
import { reset } from "redux-form";
import { toast } from "react-toastify";

import SignupPart1 from "./SignupPart1";
import SignupPart2 from "./SignupPart2";
import SignupPart3 from "./SignupPart3";

import api from "../../api";

import checkIcon from "../../img/done.svg";
import signupBg from "../../img/signup-bg.svg";

import "../../css/UserForm.css";
import "../../css/Form.css";
import {encryptAES} from "../../utils/helpers"

class Signup extends Component {
  state = {
    formPart: 1,
    userTypes: [],
    countries: [],
    cities: [],
    selectedCountry: { name: "India", code: "91", id: 95 },
    contactNumber: "",
    session_id: "",
    keyword: "",
    resendButton : false
  };

  componentDidMount() {
    this.getUserTypes();
    this.getCountriesList();
  }

  getUserTypes = () => {
    fetch(api.consumer_user_types, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      if (response && response.ok) return response.json();
      throw new Error('Status code: ' + response.status);
    }).then(response => {
      this.setState({ userTypes: response.data });
    }).catch(err => {
      console.log('Error fetching user types:', err);
    });
  }

  getCitiesList = () => {
    const country = this.state.selectedCountry.name;
    fetch(`${api.list_of_cities}?country=${country}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" }
    }).then(response => {
      if (response && response.ok) return response.json();
      throw new Error('Status code: ' + response.status);
    }).then(response => {
      this.setState({ cities: response.data });
    }).catch(err => {
      console.log('Error fetching cities:', err);
    });
  }

  getCountriesList = () => {
    const keyword = this.state.keyword
    fetch(`${api.list_of_countries}?keyword=${keyword}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" }
    }).then(response => {
      if (response && response.ok) return response.json();
      throw new Error('Status code: ' + response.status);
    }).then(response => {
    //  console.log(response.data, 'country data')
      this.setState({ countries: response.data });
    }).catch(err => {
      console.log('Error fetching countries', err);
    });
  }

  onCountryChange = v => {
   // console.log(v, 'country name changing')
    let country = this.state.countries.find(
      country => country.name === v.name
    );

    this.setState({ selectedCountry: country }, this.getCitiesList);
  };

  nextPage = () => {
    this.setState(prevState => ({
      formPart: prevState.formPart + 1,
    }));
  };

  prevPage = () => {
    this.setState(prevState => ({
      formPart:
        prevState.formPart > 1
          ? prevState.formPart - 1
          : prevState.formPart,
    }));
  };
  
  // Check for contact number and send OTP if present, otherwise submit form directly
  onSubmit = (formData, dispatch) => {
    
    if (formData.contact_number && formData.contact_number !== "") {
      let contact_no = formData.contact_number
        ? `+${this.state.selectedCountry.code}${formData.contact_number
        }`
        : "";
    if (formData.email && formData.email!=""){
      var email = formData.email
    }   
 

      this.setState({
        formLoader: true,
        contactNumber: contact_no,
      });

      fetch(`${api.send_otp}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "mobile": encryptAES(contact_no),
          "email":encryptAES(email)
        })   
      })
        .then(response => {
          this.setState({ formLoader: false });

          if (response.status !== 200) {
            console.log(
              "Looks like there was a problem. Status Code: " +
              response.status
            );
            toast.error("Looks like there was a problem!!");
            dispatch(reset("signup_form"));
            return;
          }

          // Examine the text in the response
          response.json().then(response => {
              //console.log("Response of OTP API", response);
            if (response.success === true) {
              this.setState({
                session_id: response.data.session_id,
                formPart: 3,
                resendButton:false
              });
            }
            if (response.success === false) {
              toast.error(`You have exceeded the rate limit for sending OTPs. Please try again later.`);
              dispatch(reset("signup_form"));
            }
          });
        })
        .catch(err => {
          this.setState({
            formLoader: false,
          });
          toast.error("There was some error. Try Again!!");
          dispatch(reset("signup_form"));
        });
    } else {
      this.submitForm(formData, dispatch);
    }
  };


 
  submitForm = (formData, dispatch) => {
   // console.log(formData)
    const encryptedData = encryptAES(formData.password);
    this.setState({
      formLoader: true,
    });
    if(this.state.resendButton === false){    
    fetch(api.consumer_signup, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...formData,
        session_id: this.state.session_id,
        contact_number: this.state.contactNumber,
        username: formData.email,
        password : encryptedData,
        user_type: formData.user_type.value,
        city: formData.city.value,
        country: this.state.selectedCountry.name,
      }),
    })
      .then(response => {
        this.setState({
          formLoader: false,
        });
        // Reset all reCaptcha elements
        window.grecaptcha.reset(0);

        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " +
            response.status
          );
          toast.error("Looks like there was a problem!!");
          dispatch(reset("signup_form"));
          return;
        }

        // Examine the text in the response
        response.json().then(response => {
          // console.log(response);
          if (response.success === true) {
            toast.success(
              "Thanks for registering! Please verify your email through the link sent to you."
            );
            dispatch(reset("signup_form"));
            this.props.toggle();
            this.setState({
              redirectToMain: true,
            });
          }
          if (response.success === false) {
          //  console.log(response)
            toast.error(`${response.data}`);
            dispatch(reset("signup_form"));
          }
          if (response.data === "OTP Expire"){
            this.setState({resendButton:true})     
          }    
        });
       
      })
      .catch(err => {
        // Reset all reCaptcha elements
        window.grecaptcha.reset(0);
        // console.log('There was some error:', err);
        this.setState({
          formLoader: false,
          showOtpModal: false,
        });
        toast.error("There was some error. Try Again!!");
        dispatch(reset("signup_form"));
      });
    }
    else{
      this.onSubmit(formData,dispatch)
    }
      
  };

  render() {
   // console.log(this.state.resendButton)
    return (
      <div className="user-form">
        {/* Left half of signup-form */}
        <div className="user-form__left">
          <div className="user-form__left--text-wrapper">
            <div className="close-modal--mobileOnly">
              <i
                className="fa fa-caret-left"
                onClick={this.props.toggle}
                aria-hidden="true"
                
              />
            </div>
            <div className="user-form__left-head">Register</div>
            <div className="user-form__left-desc">
              <ul className="user-form__left-ul">
                <li>
                  <img
                    src={checkIcon}
                    className="check-icon"
                    alt="icon"
                  />
                  &nbsp; Follow companies & individuals
                </li>
                <li>
                  <img
                    src={checkIcon}
                    className="check-icon"
                    alt="icon"
                  />
                  &nbsp; Instant updates
                </li>
                <li>
                  <img
                    src={checkIcon}
                    className="check-icon"
                    alt="icon"
                  />
                  &nbsp; Stats & reports
                </li>
                <li>
                  <img
                    src={checkIcon}
                    className="check-icon"
                    alt="icon"
                  />
                  &nbsp; Industry news
                </li>
              </ul>
            </div>
          </div>
          <div className="user-form__left-img-wrapper">
            <img
              className="user-form__left-img"
              src={signupBg}
              alt="Lock background"
            />
          </div>
        </div>
        {/* End of left half of signup-form */}

        {this.state.formPart === 1 && (
          <SignupPart1
            onSubmit={this.nextPage}
            userTypes={this.state.userTypes}
            changeType={this.props.changeType}
          />
        )}
        {this.state.formPart === 2 && (
          <SignupPart2
            onSubmit={this.onSubmit}
            prevPage={this.prevPage}
            countries={this.state.countries}
            selectedCountry={this.state.selectedCountry}
            onCountryChange={this.onCountryChange}
            cities={this.state.cities}
            formLoader={this.state.formLoader}
            changeType={this.props.changeType}
          />
        )}
        {this.state.formPart === 3 && (
          <SignupPart3
            onSubmit={this.submitForm}
            resendOtp = {this.onSubmit}
            prevPage={this.prevPage}
            contactNumber={this.state.contactNumber}
            formLoader={this.state.formLoader}
            resendBtn = {this.state.resendButton}
          />
        )}
      </div>
    );
  }
}
 

export default Signup;
