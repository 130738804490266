import React, { Component } from 'react';
import "../css/BrowsingTech.css";

import download from "../img/download.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import caseImg from '../img/casestudy.png'
import '../css/Home.css'
import { getFormattedDateTime } from '../components/helper-functions/helper'
import EventsWebinar from './EventsWebinar';
import { httpfyUrl } from "../../src/utils/helpers";
import DateCountdown from 'react-date-countdown-timer';

class Trending extends Component {


    render() {
        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: 1
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 1
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 1
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1
            }
        };
        
        const data = this.props.data
        const finalData = []
        for (let i = 0; i < data.length; i++) {
           // console.log(data[i].type)
            if (data[i].type==="blogs" || data[i].type === "events_webinars" || data[i].type === 'whitepapers' || data[i].type === 'reports' || data[i].type === "summary_docs") {
                finalData.push(data[i])
            }
        }

        return (
            <div className='home__trending_container'>
                <div className="home__trending__container">
                    <p className='case_heading'>Trending</p>
                    <Carousel responsive={responsive}>
                        {this.props.data && finalData.map((obj, i) => {
                           // console.log(obj,"trending")
                            return (
                                <div className='home_trending_carousel'>
                                    <div className='home_trending__carousel_conatiner'>
                                        <img src={obj.type === "blogs" || obj.type ==="summary_docs" ?obj.thumbnail_url:obj.trending_thumbnail_url} className='home__trending_img' alt="trending thumbnail image"/>
                                        {obj.schedule_date &&
                                        <div className='home_bottom-right-image'>
                                                <DateCountdown dateTo={getFormattedDateTime(obj['schedule_date'])} />
                                                </div>}
                                        <div className="home__previewCard-bottom">
                                            <div className="home__previewCard-contentSec">
                                                <div className="home__trending_previewCard-text">{obj.title}</div>
                                                {obj.type === "events_webinars" ?
                                                <div className="home__previewCard-time">
                                                {getFormattedDateTime(obj['schedule_date'])}
                                                </div>:
                                                <div className="home__previewCard-time">
                                                {getFormattedDateTime(obj['publish_date'])}
                                                </div>}
                                            </div>
                                            {obj.type === "events_webinars" ?
                                                <a  
                                                href={httpfyUrl(obj.url)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                    >
                                                    <div className="home__trending__register_button">Register</div>
                                                </a> : <a
                                                    className="home__trending__button"
                                                    download
                                                   // target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={obj.url}
                                                    //onClick={handleClick}
                                                >Download</a>}
                                        </div>
                                    </div>
                                </div>)

                        })
                        }
                    </Carousel>
                </div>
                <div className="home__socialNews">
                    <div className="home__socialNews-heading">SOCIAL FOOTPRINT</div>

                    <iframe
                        className="home__iframe"
                        srcdoc="<!DOCTYPE html><html>
                          <body >
                            <a class='twitter-timeline'style='display: block !important' data-theme='light' href='https://twitter.com/TechSagar_DSCI?ref_src=twsrc%5Etfw'>Tweets by DSCI_Connect</a>
                            <script async src='https://platform.twitter.com/widgets.js' charset='utf-8'></script>                
                            </body> </html>"

                        style={{ width: '100%', height: '46.3rem' }}
                        frameborder="0" allowfullscreen>
                    </iframe>


                </div>
            </div>
        );
    }
}

export default Trending;