import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";
import { toast } from "react-toastify";

import Captcha from "../form-components/Captcha";
import FormInputField from "../form-components/FormInputField";
import FormButton from "../form-components/FormButton";

import { setUserInfo } from "../../actions/auth";

import api from "../../api";
//import firebase, { requestForToken } from "../../firebase"
import "../../css/UserForm.css";
import "../../css/Form.css";

//  Image imports
import loginBg from "../../img/login-bg.svg";
import { setCount } from "../../actions/notifications";


import { encryptAES } from "../../utils/helpers"
// Validate Form

const validate = values => {
    const errors = {};

    // Errors
    const reqError = "This field is required";
    const emailError = "Enter a valid email";
    const passwordError = "A password must be eight characters"
    // Regex
    /* eslint-disable no-useless-escape */
    const emailRegex = /^([a-z A-Z 0-9 _\.\-])+\@(([a-z A-Z 0-9\-])+\.)+([a-z A-z 0-9]{2,})$/;
    const passwordRegex = /^(?=.*\d)(?=.*[#$@!%&._*])[A-Za-z\d#$@!%&._*]{8,}$/
    /* eslint-enable no-useless-escape */

    // email
    if (!values.email) {
        errors.email = reqError;
    }
    if (values.email && !values.email.match(emailRegex)) {
        errors.email = emailError;
    }

    // password
    if (!values.password) {
        errors.password = reqError;
    }

    if (values.password && !values.password.match(passwordRegex)) {
        errors.password = passwordError;
    }
    //type
    if (!values.user_type) {
        errors.user_type = reqError;
    }

    //recaptcha
    if (!values.recaptcha) {
        errors.recaptcha = reqError;
    }
    return errors;
};

class Login extends Component {
    constructor() {
        ///   console.log("login page ")
        super();
        this.state = {
            formLoader: false,
        };
    }

    submitForm = (formData, dispatch) => {
        const deviceToken = localStorage.getItem('fcm_token')

        const encryptedData = encryptAES(formData.password);

        //console.log(formData)
        this.setState({
            formLoader: true,
        });

        fetch(api.login, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...formData,
                email: formData.email,
                password: encryptedData,
                recaptcha: formData.recaptcha,
                user_type: "CONSUMER",
                deviceToken: deviceToken
            }),
        })
            .then(response => {
                // window.grecaptcha.reset(0);

                // Reset all reCaptcha elements
                // for (var i = 0; i < 2; i++) {
                // }
                // console.log(response);
                this.setState({
                    formLoader: false,
                    toHome: true,
                });

                if (response.status !== 200) {
                    console.log(
                        "Looks like there was a problem. Status Code: " +
                        response.status
                    );
                    toast.error("Looks like there was a problem!!");
                    dispatch(reset("login_form"));
                    return;
                }

                // Examine the text in the response
                response.json().then(response => {
                    // console.log(response);
                    if (response.success === true) {
                        this.props.toggle();

                        let session = {
                            ...response.data,
                        };
                        if (response.data.first_name) {
                            toast.success(
                                `Welcome Back ${response.data.first_name}`, { autoClose: 1700 }
                            );
                        }
                        // console.log(session);

                        localStorage.setItem("user", JSON.stringify(session));
                        dispatch(reset("login_form"));

                        this.props.setUserInfo(session);
                        window.setTimeout(function () { window.location.reload() }, 1000)
                    }
                    if (response.success === false) {
                        toast.error(`${response.data}`);
                        // window.grecaptcha.reset();
                    }
                });
            })
            .catch(err => {
                // console.log('There was some error:', err);
                this.setState({
                    formLoader: false,
                });
                toast.error("There was some error. Try Again!!");
                dispatch(reset("login_form"));
                // Reset all reCaptcha elements
                // window.grecaptcha.reset(0);

                // for (let i = 0; i < 2; i++) {
                //     window.grecaptcha.reset(i);
                // }
            });
    };


    render() {

        const { handleSubmit, changeType } = this.props;

        return (
            <div className="formModal">
                <div className="user-form">
                    {/* Form left side */}
                    <div className="user-form__left">
                        <div className="user-form__left--text-wrapper">
                            <div className="close-modal--mobileOnly">
                                <i
                                    className="fa fa-caret-left"
                                    onClick={this.props.toggle}
                                    aria-hidden="true"
                                />
                            </div>
                            <p className="user-form__left-head">Login</p>
                            <div className="user-form__left-desc">
                                Welcome Back!
                                <br />
                                Good to see you again
                            </div>
                        </div>

                        <div className="user-form__left-img-wrapper">
                            <img
                                className="user-form__left-img"
                                src={loginBg}
                                alt="Lock background"
                            />
                        </div>
                    </div>
                    {/* End of left side of form */}

                    {/* Form right side */}
                    <div className="user-form__right">
                        <p className="user-form__right-head">Enter Details</p>
                        <form onSubmit={handleSubmit(this.submitForm)}>
                            <div className="user__form-container">
                                <div className="user__form-row">
                                    <Field
                                        name="email"
                                        component={FormInputField}
                                        type="text"
                                        placeholder="Email Address"
                                        tabable={true}
                                    />
                                </div>
                                <div className="user__form-row">
                                    <Field
                                        name="password"
                                        component={FormInputField}
                                        type="password"
                                        placeholder="Password"
                                        tabable={true}
                                        changeType={changeType}
                                    />
                                </div>
                                <div className="row user__form-row">
                                    <div className="login__form-captcha">
                                        <Field
                                            name="recaptcha"
                                            component={Captcha}
                                            errorMargin={"45px"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form__login-btn-and-signup--wrapper">
                                <FormButton
                                    label="LOGIN"
                                    formLoader={this.state.formLoader}
                                />

                                <p>
                                    New Member?&nbsp;
                                    <span
                                        className="form__signup-span"
                                        onClick={() => changeType("signup")}
                                    >
                                        &nbsp;Register
                                    </span>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

Login = reduxForm({
    form: "login_form",
    validate,
})(Login);

const mapDispatchToProps = dispatch => ({
    setUserInfo: userInfo => dispatch(setUserInfo({ userInfo: userInfo })),

});

export default connect(
    null,
    mapDispatchToProps

)(Login);
