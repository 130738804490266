import React, { Component, Fragment } from "react";
import { Field, FieldArray, change } from "redux-form";
import { connect } from "react-redux";

// Components
import FormSelect from "./FormSelect";

import saperatorImg from "../../../img/form-imgs/object-separator.svg";

class TechAreaCapTag extends Component {
   state = {
      techCapabilities: Array(25).fill({
         techCapabilities: [],
         techCapIsLoading: false
      })
   };

   clearCapFields = (currFieldName) => {
      if (this.props.dispatch && currFieldName) {
         this.props.dispatch(change(
            'feature_yourself_old',
            `${currFieldName}.tech_capability`,
            []
         ));
      }
   };

   adjustTechCapArray = (index) => {
      const { techCapabilities } = this.state;
      const newEmptyObj = { techCapabilities: [], techCapIsLoading: false };
      const updatedArray = techCapabilities
         .filter((_, i) => i !== index)
         .concat(newEmptyObj);

      this.setState({ techCapabilities: updatedArray });
   };

   updateTechCap = (index, tech) => {
      let newTechCap;
      newTechCap = this.state.techCapabilities.map((cap, capIndex) => {
         if (capIndex === index) {
            return {
               techCapabilities: [],
               techCapIsLoading: true,
            };
         } else {
            return cap;
         }
      });
      this.setState({
         techCapabilities: newTechCap,
      });

      // Fetch technology capalities list based on tech area
      fetch(
         `${
         process.env.REACT_APP_API_URL
         }/bulletin/technology_capability_list/?tech_area=${tech}`,
         {
            method: "GET",
            headers: {
               Accept: "application/json",
               // "Content-Type": "application/json",
            },
         }
      )
         .then(response => {
            if (response.status !== 200) {
               newTechCap = this.state.techCapabilities.map(
                  (cap, capIndex) => {
                     if (capIndex === index) {
                        return {
                           techCapabilities: [],
                           techCapIsLoading: false,
                        };
                     } else {
                        return cap;
                     }
                  }
               );
               this.setState({
                  techCapabilities: newTechCap,
               });
               console.log(
                  "Looks like there was a problem. Status Code: " +
                  response.status
               );
               return;
            }

            // Examine the text in the response
            response.json().then(response => {
               // console.log(response.data);
               newTechCap = this.state.techCapabilities.map(
                  (cap, capIndex) => {
                     if (capIndex === index) {
                        return {
                           techCapabilities: response.data,
                           techCapIsLoading: false,
                        };
                     } else {
                        return cap;
                     }
                  }
               );
               this.setState({ techCapabilities: newTechCap });
            });
         })
         .catch(err => {
            console.log("Cannot retrieve Data:", err);
            console.log('utc - error');
         });
   };

   testDisabled = (fields, formValues, area) => {
      if (fields.name === "technology_type") {
         return formValues[fields.name].some(type => type.name === area);
      } else {
         const splitArray = fields.name.split("[");
         const name = splitArray[0];
         const index = splitArray[1].split("]")[0];
         return formValues[name][index]["technology_type"].some(
            type => type.name === area
         );
      }
   };

   render() {
      return (
         <div className="form__overview-tag-entity">
            <FieldArray
               name={this.props.arrayName}
               // title={this.props.title}
               component={renderFields}
               singular={this.props.singular}
               technologyAreas={this.props.technologyAreas}
               techCapabilities={this.state.techCapabilities}
               updateTechCap={this.updateTechCap}
               clearCapFields={this.clearCapFields}
               setNextTechCap={this.setNextTechCap}
               adjustTechCapArray={this.adjustTechCapArray}
               formValues={
                  this.props.entityFormData
                     ? this.props.entityFormData.values
                     : {}
               }
               testDisabled={this.testDisabled}
            />
         </div>
      );
   }
}

const renderFields = ({
   singular,
   fields,
   // title,
   technologyAreas,
   techCapabilities,
   updateTechCap,
   clearCapFields,
   formValues,
   testDisabled,
   adjustTechCapArray
}) => {
   return (
      <Fragment>
         {fields.map((field, index) => {
            // Generate options for technology area select dropdown
            const areaOptions = technologyAreas.map(area => ({
               label: area,
               value: area,
               className: "select-option",
               disabled: testDisabled(fields, formValues, area),
            }));

            // See whether the capabilities of the selected area are still loading
            const areCapabilitiesLoading = techCapabilities[index]['techCapIsLoading'];

            // Placeholder for the technology capability dropdown
            const capPlaceholder = areCapabilitiesLoading ?
               "Please wait, loading capabilities..." :
               "Select capabilities";

            // the noOptionsMessage is a function, which should return the text to
            // be displayed in case no options are available in the dropdown to be selected
            // We're setting this up for technology capabilities in two cases:
            // 1. The capabilities are still loading
            // 2. The user has not yet selected any tech area
            const noOptionsMessage = () => {
               return areCapabilitiesLoading ?
                  'Please wait, loading capabilities...' :
                  'Please select a technology area first';
            };

            // Generate the capability options array
            const capOptions = techCapabilities[`${index}`]["techCapabilities"]
               .map(cap => ({ 
                  label: cap, 
                  value: cap, 
                  className: 'select-option' 
               }));


            return (
               <div key={index} className="form__row">
                  <Field
                     valueType="string"
                     isClearable={true}
                     name={`${field}.name`}
                     component={FormSelect}
                     label="Technology Area"
                     placeholder="Select a technology area"
                     isOptionDisabled={option => option.disabled}
                     options={areaOptions}
                     updateTechCap={tech => updateTechCap(index, tech)}
                     clearCapFields={() => clearCapFields(field)}
                  />

                  <Field
                     valueType="string"
                     name={`${field}.tech_capability`}
                     component={FormSelect}
                     isMulti
                     widthClass="form__field-wrapper--60"
                     label="Capabilities"
                     placeholder={capPlaceholder}
                     selectConfig={{
                        isClearable: true,
                        closeOnSelect: false,
                        disabled: false,
                        multi: true,
                        simpleValue: true,
                        removeSelected: true,
                        noResultsText: "Select Technology Area First",
                        isLoading: areCapabilitiesLoading
                     }}
                     noOptionsMessage={noOptionsMessage}
                     options={capOptions}
                  />
                  
                  {fields.length > 1 && (
                     <div className="feature_form__field-array-remove">
                        <span onClick={() => {
                           fields.remove(index);
                           adjustTechCapArray(index);
                        }}>
                           Remove Technology
                        </span>
                     </div>
                  )}
                  {fields.length > index + 1 && (
                     <div className="feature_form__saperator-img-wrapper">
                        <img src={saperatorImg} alt="Seperator" />
                     </div>
                  )}
               </div>
            )
         }
         )}
         <div className="feature_form__field-array-add">
            <span
               onClick={() => {
                  // setNextTechCap(fields.length);
                  fields.push({});
               }}
            >
               + Add Technology
            </span>
         </div>
      </Fragment>
   );
}

const mapStateToProps = state => ({
   entityFormData: state.form.feature_yourself_old,
});

export default connect(mapStateToProps)(TechAreaCapTag);
