import React, { Component, Fragment, lazy } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import '../css/inline-svgs.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import AreaCard from "./home-components/AreaCard";
import { Link } from "react-router-dom";
import { setUserInfo } from "../actions/auth";
import { setFormType } from "../actions/user-form";
import { updateArea, updateCapability } from "../actions/search";
import { getSearchPlaceholder } from '../utils/helpers';
import api, { getGETConfig, getAuthGETConfig } from "../api";
//import bannerLogo from '../img/banner-new/banner-logo.png';
//import sprite from '../img/sprite.svg';
import EventsWebinar from "./EventsWebinar";

import "../css/Home.css";
import CaseStudy from "./CaseStudy";
import { searchDropdownData, allAreas, features } from '../staticData';
import HomeContributor from "./HomeContributor";
import { httpfyUrl } from "../../src/utils/helpers";
import LazyLoad from 'react-lazy-load';
// Inline SVGs
import Trending from "./Trending";
import SummaryGlance from "./home-components/SummaryGlance";

import Slider from "react-slick";
import { getFormattedDateTime } from '../components/helper-functions/helper';
//import footerFeatureSelf from '../img/footer_img.png'



import {lazyLoader} from "./lazyLoader"
//const CaseStudy = lazyLoader(() => import("./CaseStudy"));
//const EventsWebinar = lazyLoader(()=> import("./EventsWebinar"))
//const SummaryGlance = lazyLoader(()=> import("./home-components/SummaryGlance"))
//const Trending = lazyLoader(()=> import("./Trending"))
//const HomeContributor = lazyLoader(()=> import("./HomeContributor"))
//const AreaCard = lazyLoader(()=> import("./home-components/AreaCard"))

class Home extends Component {
  state = {
    showAllAreas: false,
    searchQuery: "",
    CarouselImages: [],
    showSuggestions: false,
    searchRedirect: false,
    resultType: "companies",
    suggestedEntities: [],
    suggestedLocations: [],
    suggestedTechAreas: [],
    suggestedProducts: [],
    eventData: [],
    eventState: '',
    signalNewsData: [],
    articleData: [],
    recentlyAddData: [],
    entitySummaryData: {},
    caseStudyData: [],
    contributorData: [],
    trendingData: [],
    contributorEmailId: "",
    liked: false,
    isloading: true,
    token: '',
  };

  componentDidMount() {
    window.scrollTo(0, 0)
    const user = JSON.parse(localStorage.getItem("user"));
    if (user != null) {
      var token = user.token
    }
    this.setState({ token: token })


    setTimeout(this.getCarouselImages, 10)
    // If user is coming from a verify_email link from their email, execute email verification
    if (this.props.match.path === "/verify_email/:token") {
      this.verifyEmail();
    }


    //window.addEventListener('scroll', this.handleScroll);
    document.addEventListener("click", this.handleClickOutside);
    setTimeout(this.getTrendingContent, 1500)
    setTimeout(this.getEntitySummaryData, 1000)
    setTimeout(this.getAllEventData, 1600)
    setTimeout(this.getSignalNewsData, 1800)
    setTimeout(this.getArticleData, 1800)
    setTimeout(this.getRecentlyAddData, 1900)
    setTimeout(this.getCaseStudyData, 1700)
    setTimeout(this.getContributorList, 1900)

    // document.getElementById("homeVideo").play()      
  }

  toggleEventType = async (e) => {
    var eventId = e.target.id
    this.setState({
      eventState: eventId
    }, () => {
      this.getAllEventData()
    })
  }
  componentWillUnmount() {

    document.removeEventListener("click", this.handleClickOutside);
  }
  // Handles the click outside of the suggestions box to close it
  handleClickOutside = event => {
    let searchBox = document.getElementById("search-suggestions-box");
    if (searchBox && !searchBox.contains(event.target)) {
      this.hideSearchSuggestions();
    }
  };
  toggleModal = () => this.props.toggleUserForm();
  toggleUserForm = userFormType => {
    this.props.setFormType(userFormType);
    this.toggleModal();
  };
  verifyEmail = () => {
    const requestConfig = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: this.props.match.params.token })
    };

    fetch(api.verifyEmail, requestConfig)
      .then(response => {
        if (!response || !response.ok) throw Error(response.status);
        return response.json();
      })
      .then(data => {
        if (!data.success) {
          toast.error('Unable to verify email. Please try again!');
          return;
        }
        toast.success("Thanks for verifying your email. Please login to continue.");
        localStorage.removeItem("user");
        this.props.setUserInfo(null);
        this.props.history.push("/");
        return;
      })
      .catch(err => console.log('Error verifying email', err));
  };
  followToggle = () => {
    //   console.log(this.state.contributorEmailId)
    if (!this.props.userInfo || !this.props.userInfo.username) {
      toast.error("You must login first to follow");
      return;
    }
    fetch(api.consumerActionOnContributor, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        TCRAUTH: this.props.userInfo.token,
      },
      body: JSON.stringify({
        email: this.state.contributorEmailId,
      }),
    }).then(response => {
      if (response.status !== 200) {
        console.log("Problem liking/following the tech area");
        return;
      }
      response.json().then(response => {
        if (response.success) {
          this.setState(prevState => ({ liked: !prevState.liked }));
        }
      });
    });
  };

  getAllEventData = () => {
    const url = `${api.allEvent}?tech_area=all&event_type=${this.state.eventState}`;
    fetch(url, getAuthGETConfig(this.state.token))
      .then(response => {
        if (!response || !response.ok) throw Error(response.status);
        return response.json();
      })
      .then((data) => {
        // console.log(data)
        this.setState({
          eventData: data,
        })
      })
      .catch(err => console.log('Error fetching all event', err));
  }
  getCarouselImages = () => {
    const url = `${api.bannerImages}`;
    fetch(url)
      .then(response => {

        if (!response || !response.ok) throw Error(response.status);
        return response.json();
      })
      .then((data) => {
        this.setState({
          CarouselImages: data.data,
        })
      })
      .catch(err => console.log('Error fetching all event', err));
  }

  getSignalNewsData = () => {
    const url = `${api.signalNewsApi}?tech_area=all&page=1`;
    fetch(url, getAuthGETConfig(this.state.token))
      .then(response => {
        if (!response || !response.ok) throw Error(response.status);
        return response.json();
      })
      .then(({ data }) => {
        //  console.log(data, 'signal news data')
        this.setState({ signalNewsData: data })
      })
      .catch(err => console.log('Error fetching all signal news data', err));
  }
  getArticleData = () => {
    const url = `${api.articleApi}?tech_area=all&page=1`;
    fetch(url, getAuthGETConfig(this.state.token))
      .then(response => {
        if (!response || !response.ok) throw Error(response.status);
        return response.json();
      })
      .then(({ data }) => {
        //  console.log(data, 'article data')
        this.setState({ articleData: data })
      })
      .catch(err => console.log('Error fetching all artical data', err));
  }

  getRecentlyAddData = () => {
    const url = `${api.recentlyAddApi}?page=1&page_size=10`;
    fetch(url, getAuthGETConfig(this.state.token))
      .then(response => {
        if (!response || !response.ok) throw Error(response.status);
        return response.json();
      })
      .then(({ data }) => {
        //console.log(data, 'recently add data')
        this.setState({ recentlyAddData: data })
      })
      .catch(err => console.log('Error fetching all recently add data', err));
  }

  getEntitySummaryData = () => {
    const url = `${api.entitySummaryApi}?keyword=count_info&page=1&page_size=10`;
    fetch(url, getAuthGETConfig(this.state.token))
      .then(response => {
        if (!response || !response.ok) throw Error(response.status);
        return response.json();
      })
      .then(({ data }) => {
        //console.log(data, 'entity data')
        this.setState({ entitySummaryData: data })
      })
      .catch(err => console.log('Error fetching all entity data', err));
  }
  getCaseStudyData = () => {
    const url = `${api.caseStudyApi}?tech_area=all&page=1&page_size=10`;
    fetch(url, getAuthGETConfig(this.state.token))
      .then(response => {
        if (!response || !response.ok) throw Error(response.status);
        return response.json();
      })
      .then(({ data }) => {
        // console.log(data, 'case study data')
        this.setState({ caseStudyData: data })
      })
      .catch(err => console.log('Error fetching case study data', err));
  }

  getContributorList = () => {
    const url = `${api.contributorApi}?tech_area=all&page=1&page_size=10`;
    fetch(url, getAuthGETConfig(this.state.token))
      .then(response => {
        if (!response || !response.ok) throw Error(response.status);
        return response.json();
      })
      .then(({ data }) => {
        //   console.log(data, 'contributor data')
        this.setState({ contributorData: data })
      })
      .catch(err => console.log('Error occured while fetching contributor list', err));
  }

  getTrendingContent = () => {
    //console.log(this.state.token)
    const url = `${api.trendingContentApi}`;
    fetch(url, getAuthGETConfig(this.state.token))
      .then(response => {
        if (!response || !response.ok) throw Error(response.status);
        return response.json();
      })
      .then(({ data }) => {
        //  console.log(data, 'trending data')
        this.setState({ trendingData: data })
      })
      .catch(err => console.log('Error occured while fetching trending list', err));
  }
  //* Master function that manages the fetching of suggestions
  //* For search queries >= 3 letters, decides the API endpoint to hit and calls the respective function
  getSuggestions = () => {
    // Do nothing if query is less than 3 letters
    if (this.state.searchQuery.replace(/\s+/, "").length < 2) return;
    // Query is 3 or more letters, fetch suggestions
    const { resultType } = this.state;
    if (resultType === 'tech_area') this.getTechAreaSuggestions();
    else if (resultType === 'products') this.getProductSuggestion();
    else this.getEntitySuggestions();
  }
  // Fetches the product suggestions
  getProductSuggestion = () => {
    const url = `${api.get_suggestion_product}?filter_type='products'&keyword=${this.state.searchQuery}`;
    fetch(url, getGETConfig())
      .then(response => {
        if (!response || !response.ok) throw Error(response.status);
        return response.json();
      })
      .then(({ data }) => {
        this.setState({ suggestedProducts: data.suggested_entities });
      })
      .catch(err => console.log('Error fetching product suggestions', err));
  };
  // Fetches the tech area suggestions
  getTechAreaSuggestions = () => {
    const url = `${api.tech_area_search}?keyword=${this.state.searchQuery}`;
    // console.log(this.state.searchQuery, "keyword")
    fetch(url, getGETConfig())
      .then(response => {
        if (!response || !response.ok) throw Error(response.status);
        return response.json();
      })
      .then(({ data }) => {
        this.setState({ suggestedTechAreas: data });
      })
      .catch(err => console.log('Error fetching tech area suggestions', err));
  };
  // Fetches entity suggestions
  getEntitySuggestions = () => {
    const url = `${api.get_suggestions}?keyword=${this.state.searchQuery}&filter_type=${""}`;
    fetch(url, getGETConfig())
      .then(response => {
        if (!response || !response.ok) throw Error(response.status);
        return response.json();
      })
      .then(({ data }) => {
        this.setState({
          suggestedEntities: data.suggested_entities,
          suggestedLocations: data.suggested_locations,
          correctedWord: data.corrected_word
        });
      })
      .catch(err => console.log('Error fetching entity suggestions', err));
  };

  //  Expands or collapses the technology areas shown on home page (See Less/More)
  toggleShowAll = () =>
    this.setState(prevstate => ({
      showAllAreas: !prevstate.shFowAllAreas,
    }));
  //  Clear search query
  clearSearchQuery = () => this.setState({ searchQuery: "" });
  //  Handles query change, and calls the debounced get suggestions method
  onChangeQuery = event =>
    this.setState({ searchQuery: event.target.value }, this.dGetSuggestion);
  //  Show and Hide the search suggestions
  showSearchSuggestions = () => this.setState({ showSuggestions: true });
  hideSearchSuggestions = () => this.setState({ showSuggestions: false });

  //  Click handler when user clicks on a search suggestion
  suggestionClickedHandler = (entity, isLocation) => {
    const companyName = entity.name.replace("%", "percentage").replace(/ /g, "-").replace("---", "_")

    if (isLocation) return; //  Return if clicked entity was a location (no redirection needed)
    if (!entity.keyword_type) {
      if (entity.type === "Individual Researcher") {
        this.props.history.push(
          {
            pathname: `/researcher/${companyName}`,
            state: `${entity.id}`
          })
        return;
      }
      else if (entity.type === "R & D Centres") {
        this.props.history.push(
          {
            pathname: `/rd-centre/${companyName}`,
            state: `${entity.id}`
          })
        return;
      }
      else if (entity.type === "Academia") {
        this.props.history.push(
          {
            pathname: `/institute/${companyName}`,
            state: `${entity.id}`
          })
        return;
      }
      else {
        this.props.history.push(
          {
            pathname: `/company/${companyName}`,
            state: `${entity.id}`
          })
        return;
      }
    }
    //  For Technologies search
    let entityName = entity['name'].replace("%", "percentage").replace(/ /g, "-")
    let techAreaName = entity['name']
    if (entity.keyword_type === "tech_area") {

      this.props.history.push(
        {
          pathname: `/${entityName}`,
          state: { techAreaName }
        });
    } else {

      this.props.history.push({
        pathname: `/${entityName}/${entityName}`,
        state: { techAreaName }
      }
      );
    }
  };

  setResultType = type => {
    this.setState(
      {
        resultType: type,
        suggestedEntities: [],
        suggestedLocations: [],
        suggestedTechAreas: [],
        suggestedProducts: [],
      },
      this.dGetSuggestion
    );
  };

  sendSearchQuery = event => {
    event.preventDefault();
    if (this.state.searchQuery.trim().length) {
      this.props.updateArea("");
      this.props.updateCapability("");
      this.setState({
        searchRedirect: true,
      });
    }
  };
  capitalize = () => {
    var word = this.state.eventState
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  }

  //  Handles resultType change for mobile view dropdown
  handleDropdownChanged = option => this.setResultType(option.value);

  debounced = (delay, fn) => {
    let timerId;
    return function (...args) {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        fn(...args);
        timerId = null;
      }, delay);
    };
  };

  dGetSuggestion = this.debounced(400, this.getSuggestions);

  onHandleEventState = (name) => {
    const user = JSON.parse(localStorage.getItem("user"));
    var token = user.token


    this.setState({ eventState: name }, () => {
      this.getAllEventData(token)
    })

  }
  techIcon = (techarea) => {
    var techAreaIcon
    for (let i = 0; i < allAreas.length; i++) {
      if (allAreas[i].name === techarea) {
        techAreaIcon = allAreas[i].image
      }
    }
    return (
      <img src={techAreaIcon} alt='ai'
        className="home__previewRecentCard-top-imglogo" />
    )
  }
  toggleContributor = async (e) => {
    this.setState({
      contributorEmailId: e.target.id
    }, () => {
      this.followToggle()
    })
  }
  handleImageClick = (targetUrl) => {
    console.log("click")
    window.open(targetUrl, '_blank');
  };

  render() {

    document.title = "Home - Technology Capability Repository || DSCI";
    document.getElementsByTagName("LINK")[0].href = `https://www.techsagar.in`
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 3
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 560, min: 0 },
        items: 1
      }
    };
    var recentlySlider = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      responsive: [
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            centerMode: false,
            dots: false
          }
        }
      ],
      // slidesToScroll={ 1}
      initialSlide: 0,
      // rows={ 2}
      slidesPerRow: 1,
      className: "center",
      centerMode: true
    }

    const placeholder = getSearchPlaceholder(this.state.resultType);
    let searchSuggestions = null;
    //  Product search suggestions
    if (this.state.resultType === "products") {
      searchSuggestions = this.state.suggestedProducts.map(
        (product, index) => (
          <div
            key={index}
            className="home__search-suggestions-item"
            onClick={this.suggestionClickedHandler.bind(this, product, false)}
          >
            {product.name}
          </div>
        )
      );
    }
    //  Technologies search suggestions
    else if (this.state.resultType === "tech_area") {
      //  For Technologies
      searchSuggestions = this.state.suggestedTechAreas.map(
        (techarea, index) => (
          <div
            key={index}
            className="home__search-suggestions-item"
            onClick={this.suggestionClickedHandler.bind(
              this,
              techarea,
              false
            )}
          >
            {techarea.name}
          </div>
        )
      );
    }
    //  Company, Academia, Researcher and RnD suggestions
    //  Also includes Location suggestions
    else {
      searchSuggestions = (
        <Fragment>
          {this.state.suggestedEntities.map((entity, index) => (
            <div
              key={index}
              className="home__search-suggestions-item"
              onClick={this.suggestionClickedHandler.bind(this, entity, false)}
            >{entity.name}</div>
          ))}
        </Fragment>
      );
    }

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      // slidesToScroll: 1,
      initialSlide: 0,
      // rows: 2,
      slidesPerRow: 1,
      className: "center",
      centerMode: true,
    };


    var resultType = this.state.resultType
    if (this.state.searchQuery === "Academia" || this.state.searchQuery === "academia") {
      var resultType = "academia"
    }
    else if (this.state.searchQuery === "R&D Centers" || this.state.searchQuery === "r&d centers") {
      var resultType = "r_and_d"
    }
    else if (this.state.searchQuery === "Researchers" || this.state.searchQuery === "researchers") {
      var resultType = "individual"
    }
    else if (this.state.searchQuery === "Unique products" || this.state.searchQuery === "unique products") {
      var resultType = "products"
    }
    else {
      var resultType = this.state.resultType
    }
    return (
      <>
        <div className="container home">
          {this.state.searchRedirect && (
            <Redirect
              to={{
                pathname: "/search/",
                search: `?keyword=${["academia", "r&d centers", "researchers", "unique products", "companies"].includes(this.state.searchQuery) ? this.state.searchQuery[0].toUpperCase() + this.state.searchQuery.slice(1) : this.state.searchQuery.replace(/ /g, "_").replace("&", "")}&resultType=${resultType}`,
              }}
            />
          )}
          <div className="home__jumbotron__search__wrapper" id="search-suggestions-box">
            <form className="home__jumbotron__search__form" onSubmit={this.sendSearchQuery} tabIndex="-1" onFocus={this.showSearchSuggestions}
              // onBlur={this.hideSearchSuggestions}
              autoComplete="off"
            >
              <i className="home__jumbotron__search__icon fas fa-search" />
              <input
                type="text"
                name="searchQuery"
                className="home__jumbotron__search"
                placeholder={placeholder}
                value={this.state.searchQuery}
                onChange={this.onChangeQuery}
                onKeyDown={this.searchKeyDownHandler}
              />
              {this.state.searchQuery !== "" && (
                <div
                  className="search__query-clear-btn search__query-clear-btn--home"
                  onClick={this.clearSearchQuery}
                >
                  <svg className='home-search__clear-icon'>
                    <use href={"https://tcr-documents.s3.ap-south-1.amazonaws.com/home_screen/sprite.svg" + "#clear"} />
                  </svg>
                </div>
              )}
              <button type="submit" className="home__jumbotron-search-btn">
                SEARCH
              </button>
            </form>

            {(this.state.suggestedEntities.length > 0 ||
              this.state.suggestedLocations.length > 0 ||
              this.state.suggestedTechAreas.length > 0 ||
              this.state.suggestedProducts.length > 0) && (
                <div
                  className={`${this.state.showSuggestions
                    ? "home__search-suggestions--open"
                    : "home__search-suggestions"
                    }`}
                >
                  {searchSuggestions}
                </div>
              )}
          </div>
          <Carousel
            showThumbs={false}
            showStatus={false}>

            <div className="home__container">
              <img className="home__jumbotron" src={"https://tcr-phase2-documents.s3.ap-south-1.amazonaws.com/images/jumbotron-background.svg"} />
              {/*    <img rel="preload"  className="home__jumbotron" alt="banner image" src={obj.banner_url} />*/}


              <div className="home__img">
                <img src={'https://tcr-documents.s3.ap-south-1.amazonaws.com/home_screen/banner-logo.png'} alt='Tech Sagar' className='home__banner-logo' />
              </div>
              {/* <BannerPatternTopSvg className='home__top-pattern' />*/}
              <div className="home__banner_content">
                <p className='home__jumbotron-ncsc'>Supported by the office of National Cyber Security Coordinator</p>
                <p className="home__jumbotron__head">Repository of India’s Cybertech Capabilities</p>
                <p className="home__jumbotron__subhead">Discover Start-ups, Companies, R&D Labs, Academia and Individuals
                  Building India’s Cyber Capabilities</p>
              </div>
            </div>
            {/*<div  className="home__container_banner" onClick={() => window.open( "https://in.explara.com/e/technation2023", "_blank")}>
           <img
            className="home__banner"
            alt="banner image"
            src={
              "https://tcr-documents.s3.ap-south-1.amazonaws.com/banner_images/Technation_Banner+1600x540px.png"
            }
          />
                 
                  
                 
          </div>   */}
          </Carousel>
          {Object.keys(this.state.entitySummaryData).length ?
            <div className="home__summaryGlance">
              {[
                { 'url': 'Companies', 'search_name': 'companies', 'short_name': 'company', 'name': 'Companies', 'imgLink': 'https://tcr-documents.s3.ap-south-1.amazonaws.com/svg/companies.svg' },
                { 'url': 'Academia', 'search_name': 'academia', 'short_name': 'academia', 'name': 'Academia', 'imgLink': 'https://tcr-documents.s3.ap-south-1.amazonaws.com/svg/academia.svg' },
                { 'url': 'R_and_D_Centers', 'search_name': 'r_and_d', 'short_name': 'r_and_d', 'name': 'R&D Centers', 'imgLink': 'https://tcr-documents.s3.ap-south-1.amazonaws.com/svg/r-d-center.svg' },
                { 'url': 'Researchers', 'search_name': 'individual', 'short_name': 'individuals', 'name': 'Researchers', 'imgLink': 'https://tcr-documents.s3.ap-south-1.amazonaws.com/svg/researchers.svg' },
                { 'url': 'Unique_products', 'search_name': 'products', 'short_name': 'products', 'name': 'Unique products', 'imgLink': 'https://tcr-documents.s3.ap-south-1.amazonaws.com/svg/unique-products.svg' }
              ]
                .map((item, i) => {
                  return <SummaryGlance
                    key={i}
                    name={item['name']}
                    logo={item['imgLink']}
                    search={item['search_name']}
                    url={item["url"]}
                    data={this.state.entitySummaryData[item.short_name]} />
                })}
            </div>
            : ''}
            
          {this.state.trendingData && this.state.trendingData.length > 0 && this.state.isloading ?
            <Trending
              data={this.state.trendingData}
            />
            : ""}
          {this.state.isloading &&
            // <div className="container home__browse">
            //   <div className="home__browse__head"
            //   >Browse by Technology</div>
            //   <div className="row home__browse__container">
            //     {this.state.showAllAreas
            //       ? allAreas.map((area, index) => (
            //         <AreaCard
            //           key={index}
            //           area={area}
            //           animDelay={(index - 17) * 0.1}
            //         />
            //       ))
            //       : allAreas
            //         .slice(0, 16)
            //         .map((area, index) => (
            //           <AreaCard
            //             key={index}
            //             area={area}
            //             animDelay={(index + 1) * 0.1}
            //           />
            //         ))}
            //   </div>
            //   <div className="home__browse__more">
            //     {this.state.showAllAreas ? (
            //       <div className="home__browse__more-link" onClick={this.toggleShowAll}
            //       >SEE LESS</div>
            //     ) : (
            //       <div className="home__browse__more-link" onClick={this.toggleShowAll}
            //       >SEE MORE</div>
            //     )}
            //   </div>
            // </div>
            <div className="home__browse__more">
              {this.state.showAllAreas ? (
                <div className="home__browse__more-link" onClick={this.toggleShowAll}
                >SEE LESS</div>
              ) : (
                <div className="home__browse__more-link" onClick={this.toggleShowAll}
                >SEE MORE</div>
              )}
            </div>
          }
          
          {this.state.eventData && this.state.isloading &&
            <EventsWebinar
              eventCss="events_webinar_home"
              data={this.state.eventData['data']}
              activeState={this.state.eventData["event_type"]}
              onChangeHandler={this.toggleEventType}
              eventType={this.state.eventState} />}

          {this.state.caseStudyData.length > 0 && this.state.isloading &&
            <CaseStudy data={this.state.caseStudyData}
              count={this.state.caseStudyData.length}
              caseStudyCss="caseStudyHome" />
          }

          {this.state.articleData.length > 0 && this.state.isloading &&
            <div className="home__cardContiner">
              <div className="home__previewCard2">
                <div className="home__previewCard2-heading">FEATURE ARTICLE ({this.state.articleData.length})</div>
                <div className="home__previewContainerCard">
                  <div className="home__previewRecentCard-bottom">
                    {this.state.articleData.map((data, i) => {
                      return (
                        <div className="home__previewCard2-row"
                          key={i + data['title']}>
                          <div className="home__previewCard2-row-left">
                            <div className="home__previewCard2-row-heading">
                              {data['title']}
                            </div>
                            {data['publish_date'] &&
                              <div className="home__previewCard2-row-time">
                                {getFormattedDateTime(data['publish_date'])}
                              </div>}
                            <div className={`home__previewCard2-row-publisher ${data['publish_by'] === 'DSCI' && 'home__previewCard2-row-publisher--hidden'}`}>
                              {data['publish_by'] !== 'DSCI' ? `Publisher By: ${data['publish_by']}` : 'Publisher By: DSCI'}

                            </div>
                          </div>
                          <div>
                            <a
                              href={httpfyUrl(data["url"])}
                              className="home__previewCard2-row-imgLink"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fas fa-link" style={{ fontSize: "1.8rem", color: "#0096FF" }} />
                            </a></div>
                        </div>
                      )
                    })}</div>

                </div>
              </div>
              {this.state.signalNewsData.length > 0 &&
                <div className="home__previewCard2">
                  <div className="home__previewCard2-heading">SIGNAL & NEWS ({this.state.signalNewsData.length})</div>
                  <div className="home__previewContainerCard">
                    <div className="home__previewRecentCard-bottom">
                      {this.state.signalNewsData.map((data, i) => {
                        return (
                          <div className="home__previewCard2-row">
                            <div className="home__previewCard2-row-left">
                              <div className="home__previewCard2-row-heading">
                                {data['title']}
                              </div>

                              {data['publish_date'] &&
                                <div className="home__previewCard2-row-time">
                                  {getFormattedDateTime(data['publish_date'])}
                                </div>}
                              <div className={`home__previewCard2-row-publisher ${data['publish_by'] === 'DSCI' && 'home__previewCard2-row-publisher--hidden'}`}>
                                {data['publish_by'] !== 'DSCI' ? `Publisher By: ${data['publish_by']}` : 'Publisher By: DSCI'}
                              </div>
                            </div>
                            <div>
                              <a
                                href={httpfyUrl(data["url"])}
                                className="home__previewCard2-row-imgLink"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fas fa-link" style={{ fontSize: "1.8rem", color: "#0096FF" }} />
                              </a></div>
                          </div>)
                      })}</div>
                  </div>
                </div>}
            </div>}
          {this.state.recentlyAddData.length > 0 && this.state.isloading &&
            <div className="home__previewRecentCardSec">
              <div className="home__previewRecentCardSec-heading">Recently Added</div>
              <Slider {...recentlySlider}
              >
                {this.state.recentlyAddData.map((data, i) => {
                  //   console.log(this.state.recentlyAddData, 'wewqewq')
                  return (
                    <div className={`home__previewRecentCard ${!(this.state.recentlyAddData.length > 1) && 'home__previewRecentCard-widthAlign'} `}
                      key={data['total_count'] + i}>
                      <div className="home__previewRecentCard-top">
                        <div className="home__previewRecentCard-top-flex">
                          {this.techIcon(data['techarea'])}
                          <div className="home__previewRecentCard-top-headingSec">
                            <div className="home__previewRecentCard-top-heading1">
                              {data['total_count']} companies added in ({data['techarea']})
                            </div>
                            <div className="home__previewRecentCard-top-heading2">
                              {getFormattedDateTime(data['created_at'])}
                            </div>
                          </div>
                        </div>
                        <div className="home__previewRecentCard-bottom">
                          {data.data.map((data, i) => {
                            // console.log(data)
                            return (
                              <div className="home__previewRecentCard-bottom-row"
                                key={data['name'] + i}>
                                <div className="home__previewRecentCard-bottom-row-name">
                                  <Link
                                    to={{
                                      pathname: `/company/${data['name'].replace("%", "percentage").replace(/ /g, "-").replace("---", "_")}`,
                                    }}
                                  >
                                    {data['name']}
                                  </Link>
                                </div>
                                <div className="home__previewRecentCard-bottom-row-text">
                                  {data['type']}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>}
          <div>
            <div className="home__contributor-heading">
              Contributor
            </div>
            <HomeContributor
              contributorData={this.state.contributorData}
              onChangeHandler={this.toggleContributor}
              email={this.state.contributorEmailId}
              liked={this.state.liked} />
            <div className="home__become_contributor">
              {this.props.userInfo ?
                <button className="home__contributor_btn" onClick={() => this.props.history.push("/become_contributor")}>BECOME A CONTRIBUTOR</button>
                : <button className="home__contributor_btn" onClick={this.toggleUserForm.bind(null, "login")}>BECOME A CONTRIBUTOR</button>}
            </div>
          </div>
          {/* Feature Yourself Section */}
        </div>

        {this.state.isloading &&
          <div className="home__cta">
            <div className="home__cta__section-left">
              {/* <TechIllustrationSvg className='home__techIllus' /> */}
              <LazyLoad offset={300}>
                <img src={"https://tcr-documents.s3.ap-south-1.amazonaws.com/home_screen/footer_img.png"} alt='feature yourself' className='home__cta_img' />
              </LazyLoad>
            </div>
            <div className="home__cta__section-right">
              <div className="home__cta__desc"
              >Evergrowing network of companies,<br />
                startups, individuals, researchers, <br />
                academia and R&D Institutes</div>
              <br />

              <div
                onClick={this.props.handleFeatureYourself}
                className="home__cta__join__btn">FEATURE YOURSELF</div>
            </div>
          </div>}
      </>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.auth.userInfo
});

const mapDispatchToProps = dispatch => ({
  setUserInfo: userInfo => dispatch(setUserInfo({ userInfo: userInfo })),
  setFormType: formType => dispatch(setFormType({ formType: formType })),
  updateArea: area => dispatch(updateArea({ area: area })),
  updateCapability: capability =>
    dispatch(updateCapability({ capability: capability })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
