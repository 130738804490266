import React, { Component } from "react";

import FormPart2 from "./industry-components/FormPart2";
import FormPart3 from "./industry-components/FormPart3";
import FormPart4 from "./industry-components/FormPart4";
import FormPart5 from "./industry-components/FormPart5";
import FormPart6 from "./industry-components/FormPart6";
import FormPart7 from "./industry-components/FormPart7";
import FormPart8 from "./industry-components/FormPart8";
import FormPart9 from "./industry-components/FormPart9";
import FormPart10 from "./industry-components/FormPart10";

import api, { getGETConfig } from '../../api';

class IndustryWrapper extends Component {
    state = {
        companyTypes: [],
        sectorsServed: [],
        companySizes: [],
        geographiesServed: [],
        technologyAreas: [],
        cities: [],
        applicationAreas: []
    };

    componentDidMount() {
        this.getCompanyTypes();
        this.getCompanySizes();
        this.getCitiesList();
        this.getSectorsServed();
        this.getGeographiesServed();
        this.getTechnologyAreas();
        this.getApplicationAreas();
    }

    // Fetch entity types list
    getCompanyTypes = () => {
        fetch(`${process.env.REACT_APP_API_URL}/bulletin/company/type/`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then(response => {
                if (response.status !== 200) {
                    console.log(
                        "Looks like there was a problem. Status Code: " +
                        response.status
                    );
                    return;
                }

                // Examine the text in the response
                response.json().then(response => {
                    // console.log(response.data);
                    this.setState({
                        companyTypes: response.data,
                    });
                });
            })
            .catch(err => {
                console.log("Cannot retrieve Data:", err);
            });
    };

    // Fetch sectors served list
    getSectorsServed = () => {
        fetch(`${process.env.REACT_APP_API_URL}/bulletin/sectors/`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then(response => {
                if (response.status !== 200) {
                    console.log(
                        "Looks like there was a problem. Status Code: " +
                        response.status
                    );
                    return;
                }

                // Examine the text in the response
                response.json().then(response => {
                    // console.log(response.data);
                    this.setState({
                        sectorsServed: response.data,
                    });
                });
            })
            .catch(err => {
                console.log("Cannot retrieve Data:", err);
            });
    };

    // Fetch company size list
    getCompanySizes = () => {
        fetch(`${process.env.REACT_APP_API_URL}/bulletin/company/size/`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then(response => {
                if (response.status !== 200) {
                    console.log(
                        "Looks like there was a problem. Status Code: " +
                        response.status
                    );
                    return;
                }

                // Examine the text in the response
                response.json().then(response => {
                    // console.log(response.data);
                    this.setState({
                        companySizes: response.data,
                    });
                });
            })
            .catch(err => {
                console.log("Cannot retrieve Data:", err);
            });
    };

    // Fetch geographies served list
    getGeographiesServed = () => {
        fetch(`${process.env.REACT_APP_API_URL}/bulletin/geography/`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then(response => {
                if (response.status !== 200) {
                    console.log(
                        "Looks like there was a problem. Status Code: " +
                        response.status
                    );
                    return;
                }

                // Examine the text in the response
                response.json().then(response => {
                    // console.log(response.data);
                    this.setState({
                        geographiesServed: response.data,
                    });
                });
            })
            .catch(err => {
                console.log("Cannot retrieve Data:", err);
            });
    };

    // Fetch technology areas list
    getTechnologyAreas = () => {
        fetch(api.tech_areas, getGETConfig())
            .then(response => {
                if (!response || !response.ok) throw Error(response.status);
                return response.json();
            })
            .then(({ data }) => this.setState({ technologyAreas: data }))
            .catch(err => console.log('Error fetching technology areas', err));
    };

    getApplicationAreas = () => {
        fetch(`${process.env.REACT_APP_API_URL}/bulletin/application_areas`, {
            method: "GET",
            headers: { accept: "application/json" },
        })
            .then(response => {
                //  Unsuccessful response => Log to console and return
                if (response.status !== 200) {
                    console.log(
                        "Looks like there was a problem. Status code " +
                        response.status
                    );
                    return;
                }

                //  Proceed for successful response
                response.json().then(response => {
                    this.setState({ applicationAreas: response.data });
                });
            })
            .catch(err => {
                console.log("An error occurred, error = ", err);
            });
    };

    getCitiesList = () => {
        const url = `${api.list_of_cities}?country=India`;

        fetch(url, getGETConfig())
            .then(response => {
                if (!response || !response.ok) throw Error(response.status);
                return response.json();
            })
            .then(({ data }) => this.setState({ cities: data }))
            .catch(err => console.log('Error fetching cities', err));
    };

    abortController = new window.AbortController();

    componentWillUnmount = () => this.abortController.abort();



    render() {
        return (
            <div className="feature_form__wrapper">
                <div className="feature_form__subhead">
                    STEP {this.props.formPart} OF {this.props.totalParts}
                </div>
                {this.props.formPart === 2 && (
                    <FormPart2
                        onSubmit={this.props.nextPage}
                        prevPage={this.props.prevPage}
                        companyTypes={this.state.companyTypes}
                        sectorsServed={this.state.sectorsServed}
                        companySizes={this.state.companySizes}
                        geographiesServed={this.state.geographiesServed}
                        applicationAreas={this.state.applicationAreas}
                    />
                )}
                {this.props.formPart === 3 && (
                    <FormPart3
                        prevPage={this.props.prevPage}
                        onSubmit={this.props.nextPage}
                        cities={this.state.cities}
                    />
                )}
                {this.props.formPart === 4 && (
                    <FormPart4
                        prevPage={this.props.prevPage}
                        onSubmit={this.props.nextPage}
                    />
                )}
                {this.props.formPart === 5 && (
                    <FormPart5
                        prevPage={this.props.prevPage}
                        onSubmit={this.props.nextPage}
                        technologyAreas={this.state.technologyAreas}
                    />
                )}
                {this.props.formPart === 6 && (
                    <FormPart6
                        prevPage={this.props.prevPage}
                        onSubmit={this.props.nextPage}
                        technologyAreas={this.state.technologyAreas}
                    />
                )}
                {this.props.formPart === 7 && (
                    <FormPart7
                        prevPage={this.props.prevPage}
                        onSubmit={this.props.nextPage}
                        technologyAreas={this.state.technologyAreas}
                    />
                )}
                {this.props.formPart === 8 && (
                    <FormPart8
                        prevPage={this.props.prevPage}
                        onSubmit={this.props.nextPage}
                        technologyAreas={this.state.technologyAreas}
                    />
                )}
                {this.props.formPart === 9 && (
                    <FormPart9
                        prevPage={this.props.prevPage}
                        onSubmit={this.props.nextPage}
                        technologyAreas={this.state.technologyAreas}
                    />
                )}
                {this.props.formPart === 10 && (
                    <FormPart10
                        prevPage={this.props.prevPage}
                        onSubmit={this.props.submitForm}
                    />
                )}
            </div>
        );
    }
}

export default IndustryWrapper;
