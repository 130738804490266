import React from 'react'

const Claimprofilecard= ({ text }) => (
    <div className="claimprofile_card_ai">
    <div 
      className='claimprofile__card__text' 
      dangerouslySetInnerHTML={{ __html: text }} // This allows HTML rendering
    />
  </div>
);

export default Claimprofilecard