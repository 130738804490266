import React, { useState } from 'react'

import "../css/ReDesignEventWebinar.css"
import { useEffect } from 'react'
import calendarImg from "../../src/img/redesign-home/redesign-events-webinars/calendar.svg"
import activeCalendarIcon from "../../src/img/redesign-home/redesign-events-webinars/calendarwhite.svg"
import BlogSlider from './BlogSlider'
import api from '../api';
const ReDesignEventWebinar = () => {
    const [selectedWebinar, setSelectedWebinar] = useState("0")
    const [selectedImage, setSelectedImage] = useState("")
    const [eventWebinarsListArray, setEventWebinarsListArray] = useState([])
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    useEffect(() => {
        eventAndWebinarDataApi()
    }, [])

    useEffect(() => {
        if (eventWebinarsListArray.length > 0) {
            setSelectedImage(eventWebinarsListArray[0].image_url);
            setSelectedWebinar(0)
        }
    }, [eventWebinarsListArray]);

    const eventAndWebinarDataApi = async () => {
        try {
            const response = await fetch(api.FocusArea, {
                headers: {
                    'Auth-Key': process.env.REACT_APP_AUTH_KEY
                }
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch file: ${response.status} ${response.statusText}`);
            }
            const { data } = await response.json();
            
            const updatedData = data.map((item, index) => {
                
                const link = item.link.split("/").slice(2).join("/");
 
                return {
                    ...item,
                    image_url: item.thumbnail,
                    heading_1: "",
                    heading_2: item.title,
                    description: item.body,
                    cta: `<a href="${link}" target="_blank" class="blog-slider__button">Click Here</a>`,
                    redirect_url: link
                };
            });
            setEventWebinarsListArray(updatedData);
        } catch (error) {
            console.error('Error fetching file:', error);
        }
    }

    const eventWebinarClickHandler = (data_id) => {
        setSelectedWebinar(data_id)
        setSelectedImage(eventWebinarsListArray[data_id]["image_url"])
    }

    const handleImgClick = (id) => {
        const link = eventWebinarsListArray[id].redirect_url;
        if (link) {
            window.open(link, '_blank'); // Redirect to the specified URL
        }
    }
    return (
        <div
            // className='redesignEventWebinar-Container'
            className='redesignEventWebinarss'
        >
            <div className='Events-Heading'>
                <h2>Focus Area</h2>
                <p>Stay tuned for the latest updates</p>
            </div>
            {/* <div className='redesignEventWebinar reDesignHomeTech-Heading'>
                <p>Focus Area</p>
            </div>
            <div className='redesignEventWebinar-Container-Elem'>
                <div className='redesignEventWebinar-Container-Img'>
                    <img style={{ cursor: 'pointer' }} onClick={() => handleImgClick(selectedWebinar)} src={selectedImage || (eventWebinarsListArray.length > 0 && eventWebinarsListArray[0].image_url)} alt="news" />
                </div>
                <div className='redesignEventWebinar-Container-Content'>
                    {
                        eventWebinarsListArray.map((data, i) => (

                            <div onClick={() => (eventWebinarClickHandler(i))} 
                                    className={selectedWebinar === i ? "redesignEventWebinar-Container-Cards redesignEventWebinar-Container-Cards-active" : "redesignEventWebinar-Container-Cards"} key={i}>
                                

                                {data.heading_1.length > 0 &&
                                    <div className='redesignEventWebinar-Cards-Date'>
                                        <img src={selectedWebinar === i ? activeCalendarIcon : calendarImg} alt="calendar" />
                                        <span>
                                            {
                                                monthNames.some(month => data.heading_1.trim().startsWith(month)) ?
                                                    data.heading_1.split('|')[0].trim() :
                                                    data.heading_1
                                            }

                                        </span>
                                    </div>
                                }

                                <div className='redesignEventWebinar-Cards-Heading'>
                                    <span>
                                        {
                                            monthNames.some(month => data.heading_2.trim().startsWith(month)) ?
                                                data.heading_2.split('|')[0].trim() :
                                                data.heading_2
                                        }
                                    </span>
                                </div>
                                <div className='redesignEventWebinar-Cards-Content'>
                                    <span>{data.description}</span>
                                </div>

                       

                                <div 
                                className={selectedWebinar === i ? "focus-cta-btn focus-cta-btn-active" : "focus-cta-btn focus-cta-btn-outline-primary"}
                               
                                dangerouslySetInnerHTML={{ __html: data.cta }}>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div> */}

            <BlogSlider eventWebinarsListArray={eventWebinarsListArray} />

        </div>
    )
}

export default ReDesignEventWebinar