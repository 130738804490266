import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../api';
import "../../css/claimprofile.css";

function ClaimEmail({ setClaimStatus, setEmail, companyUrl }) { // Add setEmail as prop
    const [localEmail, setLocalEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [loadingOtp, setLoadingOtp] = useState(false); // Separate loading state for OTP generation
    const [loadingVerify, setLoadingVerify] = useState(false); // Separate loading state for OTP verification
    const [emailError, setEmailError] = useState('');
    const [otpError, setOtpError] = useState('');
    const [urlError, setUrlError] = useState('');
    const [otpGenerated, setOtpGenerated] = useState(false); 

    const isUrlValid = (string) => {
        try {
            new URL(string);
            return true;
        } catch (err) {
            return false;
        }
    };

    const handleGenerateOtp = async () => {
        if (!localEmail) {
            setEmailError('Please enter your Email.');
            return;
        }

        if (!isUrlValid(companyUrl)) {
            setUrlError('The Company URL is incorrect.');
            return;
        } else {
            setUrlError('');
        }

        setLoadingOtp(true);
        try {
            const response = await fetch(api.send_email_verify_domain, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: localEmail,
                    // company_url: "http://valiancesolutions.com/"
                    company_url: companyUrl
                })
            });
            const data = await response.json();
            console.log('Generate OTP response:', data);
            if (!response.ok) {
                setEmailError(data.detail || 'Failed to generate OTP.');
                throw new Error(data.detail || 'Failed to generate OTP.');
            }
            else {
                setEmailError('');
                setOtpGenerated(true); // OTP successfully generated
            }
            toast.success("OTP generated successfully");
        } catch (err) {
            console.error('Error generating OTP:', err);
            setEmailError("The email domain does not match the company's URL.")
            toast.error('Failed to generate OTP. Please try again.');
        } finally {
            setLoadingOtp(false);
        }
    };

    const handleVerifyOtp = async () => {
        if (!otp) {
            setOtpError('Please enter the OTP.');
            return;
        }
        setLoadingVerify(true);
        try {
            const response = await fetch(api.verify_claim_otp, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: localEmail,
                    otp: otp
                })
            });
            const data = await response.json();
            console.log('Verify OTP response:', data);
            if (!response.ok) {
                setOtpError(data.detail || 'Failed to verify OTP.')
                throw new Error(data.message || 'Failed to verify OTP.');
            }
            else {
                setOtpError('');
            }
            if (data.message === "OTP verified successfully.") {
                toast.success("OTP verified successfully");
                setEmail(localEmail); // Update the email state in claimmain.js
                setClaimStatus(3);
            }
            else if (data.message === "OTP expired.") {
                setOtpError('OTP expired.');
            }
            else {
                toast.error('Invalid OTP. Please try again.');
            }
        } catch (err) {
            console.error('Error verifying OTP:', err);
            if (err.message === 'OTP expired.') {
                setOtpError('OTP expired.');
            } else {
                setOtpError('Incorrect OTP.');
            }
            toast.error('Invalid OTP. Please try again.');
        } finally {
            setLoadingVerify(false);
        }
    };

    return (
        <div className='claimemail__container'>
            <ToastContainer position="bottom-right" />
            <div className='claimprofile_email_register'>
                <p className='claimprofile_form__head'>Profile Registration</p>
                <div className='claimprofile_email_elem'>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div style={{ display: 'flex', gap: '10px', alignItems: 'flex-end' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <label>
                                    Email:
                                </label>
                                <input
                                    className='feature_form__input-field'
                                    type="email"
                                    value={localEmail}
                                    onChange={(e) => setLocalEmail(e.target.value)}
                                    placeholder="Enter your email"
                                    disabled={loadingOtp || loadingVerify}
                                />
                            </div>
                            <button
                                className="feature_form__btn"
                                style={{ height: '4rem' }}
                                onClick={handleGenerateOtp}
                                disabled={loadingOtp || loadingVerify}
                            >
                                {loadingOtp ? 'Sending...' : 'Generate OTP'}
                            </button>
                        </div>
                        {emailError && <span style={{color: '#e05151'}} className="error-message">{emailError}</span>}
                        {urlError && <span style={{ color: '#e05151' }} className="error-message">{urlError}</span>}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div style={{ display: 'flex', gap: '10px', alignItems: 'flex-end' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <label>
                                    OTP:
                                </label>
                                <input
                                    className='feature_form__input-field'
                                    type="text"
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                    placeholder="Enter OTP"
                                    disabled={loadingOtp || loadingVerify }
                                />
                            </div>
                            <button
                                className="feature_form__btn"
                                style={{ height: '4rem' }}
                                onClick={handleVerifyOtp}
                                disabled={loadingOtp || loadingVerify || !otpGenerated} // Disable button if not generated
                            >
                                {loadingVerify ? 'Verifying...' : 'Verify'}
                            </button>
                        </div>
                        {otpError && <span style={{color: '#e05151'}} className="error-message">{otpError}</span>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClaimEmail;
