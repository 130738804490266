import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setUserInfo } from '../actions/auth';
import { reset } from 'redux-form';

import ProfileDetails from './Contributor/ProfileDetails';
import ContributorDetails from './Contributor/ContributorDetails';
import '../css/MyAccount.css';
import '../css/becomeContributor.css'
import api from '../api';
import { toast } from 'react-toastify';

class MyAccount extends React.Component {
    state = {
        fullName: '',
        email: '',
        organization: '',
        file: null,
        base64URL: "",
        fileName: "",
        fileurl: "",
        profileImageName: "",
        profileImagebase64URL: "",
        phoneNo: '',
        designation: '',

    }

    componentDidMount() {
        // this.becomeContributor();
        this.firstNameRef = React.createRef();
        this.changePassRef = React.createRef();
        this.uploadFileRef = React.createRef();
    }
    getBase64 = file => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
              
                baseURL = reader.result;
               
                resolve(baseURL);
            };
            
        });
    };

    handleFileInputChange = e => { 
        let { file } = this.state;

        file = e.target.files[0];

        this.getBase64(file)
            .then(result => {
                file["base64"] = result;
          
                this.setState({
                    base64URL: result,
                    file,
                    fileName: file.name,
                });
            })
            .catch(err => {
                console.log(err);
            });

        this.setState({
            file: e.target.files[0]
        });
    };

    proFileImage = e => {
        
        let { file } = this.state;

        file = e.target.files[0];

        this.getBase64(file)
            .then(result => {
                file["base64"] = result;
            
                this.setState({
                    profileImagebase64URL: result,
                    file,
                    profileImageName: file.name,
                });
            })
            .catch(err => {
                console.log(err);
            });

        this.setState({
            file: e.target.files[0]
        });
    };


    focusFirstName = () => this.firstNameRef.current.focus();
    focusPassword = () => this.changePassRef.current.focus();
    uploadFile = () => this.uploadFileRef.current.focus();

    becomeContributor = values => {

        const requestBody = {
            fullname: values.fullname,
            organization: values.organization,
            email: values.email,
            designation: values.designation,
            contact_number: values.phoneNo,
            profile_image_file: this.state.base64URL,
            file: this.state.base64URL,
            techarea: "AI",
            type: values.contribution.value,
            file_name: this.state.fileName,
            profile_image_file_name: this.state.fileName
        };
        const url = `${api.becomeContributorApi}`;
        const requestConfig = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "TCRAUTH": this.props.userInfo.token,
            },
            body: JSON.stringify(requestBody),
        };


        fetch(url, requestConfig)
            .then(response => {
                if (response && response.ok) return response.json();
                throw new Error("failed to upload report");
            })
            .then(data => {
             
            })
            .catch(err => {
                console.log(err);
            });

    };




    render() {


        return (
            <div>
                <div className="entityview__top">
                    <div className="entityview__top-desc-wrapper">
                        <div className="entityview__top-breadcrumb"
                        >Home / Contributor</div>

                        <div>
                            <p className="entityview__top-head">Contributor</p>
                        </div>
                    </div>
                </div>

                <main className='my-account'>
                    <div className='my-account__left-nav'>
                        <ul className='my-account__left-nav-ul'>
                            <li
                                onClick={this.focusFirstName}
                                className='my-account__left-nav-li'
                            >Profile Details</li>
                            <li
                                onClick={this.focusPassword}
                                className='my-account__left-nav-li'
                            >Contribution Details</li>
                            <li
                                onClick={this.uploadFile}
                                className='my-account__left-nav-li'
                            >Upload File</li>
                            {/* <li className='my-account__left-nav-li'>Notification Settings</li> */}
                        </ul>
                    </div>

                    <div className='my-account__content'>
                        <section className='my-account__section'>
                            <p className='my-account__section-heading'
                            >Profile Details</p>
                            <ProfileDetails
                                myRef={this.firstNameRef}
                                ImageName={this.state.profileImageName}
                                onChangeFile={this.proFileImage}
                                onSubmit={this.becomeContributor} />

                            {/* onSubmit={this.handleChangePassword}*/}
                        </section>

                        <section className='contributor__section'>
                            <p className='my-account__section-heading'
                            >Contribution Details</p>
                            <ContributorDetails
                                myRef={this.changePassRef}
                                 
                                />
                            {/* onSubmit={this.handleChangePassword}*/}
                        </section>

                        <section className='contributor__upload_file_section'>
                            <p className='my-account__section-heading'
                            >Upload File</p>
                            {/* onSubmit={this.handleChangePassword}*/}
                            <input onChange={this.handleFileInputChange}
                                type="file" name="file" id="file" class="inputfile" 
                                accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf"/>
                            <label for="file">{this.state.fileName.length > 0 ? <p>{this.state.fileName}</p> : <p>Choose a file</p>}</label>
                        </section>
                    
                    </div>
                </main>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    userInfo: state.auth.userInfo
});

const mapDispatchToProps = dispatch => ({
    setUserInfo: userInfo => dispatch(setUserInfo({ userInfo: userInfo })),

});

MyAccount = connect(mapStateToProps, mapDispatchToProps)(MyAccount);
export default withRouter(MyAccount);