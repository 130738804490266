import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getSearchPlaceholder } from '../../utils/helpers';
import sprite from '../../img/sprite.svg';

import api from "../../api";

import {
  updateSearchRedirect,
  updateArea,
  updateCapability,
} from "../../actions/search";

class SearchBox extends Component {
  state = {
    searchQuery: this.props.searchQuery,
    showSuggestions: true,
    showSearchIcon: false,
    suggestedEntities: [],
    suggestedLocations: [],
    suggestedTechAreas: [],
  };

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
    this.setState({
      searchQuery: this.props.searchQuery,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchQuery !== this.props.searchQuery) {
      this.setState({
        searchQuery: this.props.searchQuery,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleClickOutside = event => {
    let searchBox = document.getElementById("search-suggestions-box");

    if (searchBox && !searchBox.contains(event.target)) {
      this.hideSearchSuggestions();
    }
  };

  onChangeQuery = e => {
    this.setState(
      {
        searchQuery: e.target.value,
        showSearchIcon: true,
      },
      this.dGetSuggestion
    );
  };

  clearSearchQuery = () => {
    this.setState({
      searchQuery: "",
    });
  };

  showSearchSuggestions = () => {
    this.setState({
      showSuggestions: true,
    });
  };

  hideSearchSuggestions = () => {
    this.setState({
      showSuggestions: false,
    });
  };

  sendSearchQuery = event => {
     
    this.setState({
      showSearchIcon: false,
    });
    if (event) event.preventDefault();
    if (this.state.searchQuery.trim().length) {
      this.props.updateArea("");
      this.props.updateCapability("");
      this.props.updateSearchRedirect(true);
    }
    this.hideSearchSuggestions();
  };

  getSuggestions = () => {
    if (this.state.searchQuery.replace(/\s+/, "").length > 2) {
       
      if (this.props.resultType !== "tech_area") {
        fetch(
          `${api.get_suggestions}?keyword=${
          this.state.searchQuery
          }&filter_type=${this.props.resultType}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            // signal: this.abortController.signal,
          }
        )
          .then(response => {
            if (response.status !== 200) {
              console.log(
                "Looks like there was a problem. Status Code: " +
                response.status
              );
              return;
            }

            // Examine the text in the response
            response.json().then(response => {
              this.setState(prevState => ({
                suggestedEntities:
                  response.data.suggested_entities,
                suggestedLocations:
                  response.data.suggested_locations,
                suggestedAreas: response.data.suggested_areas,
                correctedWord: response.data.corrected_word,
              }));
            });
          })
          .catch(err => {
            console.log("Cannot retrieve Data:", err);
          });
      }
      //  for this.props.resultType === 'tech_area' call a different API for suggestions
      else {
        fetch(
          `${api.tech_area_search}?keyword=${this.state.searchQuery}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        ).then(response => {
          if (response.status !== 200) {
            console.log(
              "Could not fetch Tech area suggestions",
              response.status
            );
            return;
          }

          response.json().then(response => {
           // console.log(response)
            this.setState(prevState => ({
              suggestedTechAreas: response.data,
            }));
          //  console.log(this.state.suggestedTechAreas);
          });
        });
      }
    }
  };

  debounced = (delay, fn) => {
    let timerId;
    return function (...args) {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        fn(...args);
        timerId = null;
      }, delay);
    };
  };

  dGetSuggestion = this.debounced(400, this.getSuggestions);

  render() {
    return (
      <div id="search-suggestions-box" className="search__query-wrapper">
        {this.props.searchRedirect && (
          <Redirect
            push
            to={{
              pathname: "/search/",
              search: `?keyword=${["academia","r&d centers","researchers","unique products","companies"].includes(this.state.searchQuery) ? this.state.searchQuery[0].toUpperCase() +this.state.searchQuery.slice(1):this.state.searchQuery.replace(/ /g,"_")}&resultType=${this.props.resultType}`,
           
            }}
            //to={{
            //  pathname: "/searchresults",
            //  search: `?q=${encodeURIComponent(
            //    JSON.stringify({
            //      search_type: "search",
            //      keyword: ["academia","r&d centers","researchers","unique products","companies"].includes(this.state.searchQuery) ? this.state.searchQuery[0].toUpperCase() +this.state.searchQuery.slice(1)  : this.state.searchQuery,
            //      resultType: this.props.resultType,
            //    })
            //  )}`,
            //}}
          />
        )}

        <form
          className="search__query-form"
          onSubmit={this.sendSearchQuery}
          tabIndex="-1"
          onFocus={this.showSearchSuggestions}
          autoComplete="off"
        >
          <input
            type="text"
            name="searchQuery"
            id=""
            className="search__query-input"
            placeholder={getSearchPlaceholder(this.props.resultType)}
            value={this.state.searchQuery}
            onChange={this.onChangeQuery}
          />
          {this.state.searchQuery !== "" && (
            <div
              className="search__query-clear-btn"
              onClick={this.clearSearchQuery}
            >
              <svg className='searchResults__search-clear-icon'>
                <use href={sprite+"#clear"} />
              </svg>
              {/* <img src={clearIcon} alt="Clear Search" /> */}
            </div>
          )}
        </form>
        {(this.state.suggestedEntities.length > 0 ||
          this.state.suggestedLocations.length > 0 ||
          this.state.suggestedTechAreas.length > 0) && (
            <div
              className={`search__suggestions ${
                this.state.showSuggestions
                  ? "search_results__search-suggestions--open"
                  : "home__search-suggestions"
                }`}
            >
              {this.props.resultType !== "tech_area" &&
                this.state.suggestedEntities.map(
                  (entity, index) => (
                    <div
                      key={index}
                      className="home__search-suggestions-item"
                    >
                      <Link
                       
                        style={{ display: "block" }}
                        to={{
                          pathname: `/${this.props.resultType}/${entity.name.replace("%", "percentage").replace(/ /g, "_")}`,
                          state : `${entity.id}`
                          // search: `?area=${props.area.name}`,
                          // search: `?area=${props.area.oldName}`,
                      }}
                      >
                        {entity.name}
                      </Link>
                    </div>
                  )
                )}

              {/* {this.props.resultType !== "tech_area" && (
                            <div className="home__search-suggestions-type">
                                Locations
                            </div>
                        )} */}
              {/* {this.props.resultType !== "tech_area" &&
                            this.state.suggestedLocations.map(
                                (location, index) => (
                                    <div
                                        key={index}
                                        className="home__search-suggestions-item"
                                        onClick={() => {
                                            this.setState(
                                                {
                                                    searchQuery: location,
                                                    showSuggestions: false,
                                                },
                                                this.sendSearchQuery
                                            );
                                        }}
                                    >
                                        {location}
                                    </div>
                                )
                            )} */}

              {this.props.resultType === "tech_area" &&
                this.state.suggestedTechAreas.map(
                  (techSuggestion, index) => (
                    <div
                      key={index}
                      className="home__search-suggestions-item"
                      onClick={() => {
                        this.setState(
                          {
                            searchQuery:
                              techSuggestion.name,
                            showSuggestions: false,
                          },
                          this.sendSearchQuery
                        );
                      }}
                    >
                      {techSuggestion.name}
                    </div>
                  )
                )}
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  searchRedirect: state.search.searchRedirect,
  searchQuery: state.search.query,
});

const mapDispatchToProps = dispatch => ({
  updateSearchRedirect: redirect =>
    dispatch(updateSearchRedirect({ redirect: redirect })),
  updateArea: area => dispatch(updateArea({ area: area })),
  updateCapability: capability =>
    dispatch(updateCapability({ capability: capability })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBox);
