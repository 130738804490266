import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Field, FieldArray, reduxForm, change } from "redux-form";
import { toast } from "react-toastify";

import FormInputField from "../form-elements/FormInputField";
// import TechAreaCapTag from "../form-elements/TechAreaCapTag";

import validate from "../utils/validate";

import saperatorImg from "../../../img/form-imgs/object-separator.svg";

import api from '../../../api';

class FormPart8 extends Component {
   state = {
      filePaper: Array(9).fill({ status: false, text: 'Browse' })
   };


   fileUpload = (e, type, section, index) => {
      // Retrieve file and if there is not any, return
      const file = e.target.files[0];
      if (!file) return;

      // Compute file size and if it is > 15 MB, return
      const filesize = (file.size / 1024 / 1024).toFixed(4);
      if (filesize > 15) {
         toast.error("The size of file should be less than 15 MB!");
         return;
      }

      // file${type} computes to 'filePaper' which is an object in the state
      this.setState(prevState => {
         // Create updated state
         const updatedState = prevState[`file${type}`].map((paper, idx) => {
            if (idx !== index) return paper;
            return { status: true, text: 'Uploading' };
         });

         // Return the updated state object
         return { [`file${type}`]: updatedState };
      });


      const fileReader = new FileReader();
      const fileName = file.name;
      let fileUri;

      fileReader.readAsDataURL(file);
      fileReader.onloadend = () => {
         if (fileReader.readyState === 2) {
            fileUri = fileReader.result;
         }

         const fileuUploadData = {
            company_name: this.props.entityName,
            type: type,
            filename: fileName,
            content: fileUri,
         };
         const requestConfig = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(fileuUploadData)
         };


         fetch(api.companyFileUpload, requestConfig)
            .then(response => {
               if (!response || !response.ok) throw Error(response.staus);
               return response.json();
            })
            .then(({ data }) => {
               const { dispatch } = this.props;
               dispatch(change("feature_yourself_old", `${section}[${index}].url`, data.url));

               // Update state to show status of the uploaded files
               this.setState(prevState => {
                  const updatedState = prevState[`file${type}`].map((paper, idx) => {
                     if (idx !== index) return paper;
                     return { status: false, text: fileName };
                  });

                  // Return the updated state object
                  return { [`file${type}`]: updatedState };
               });
            })
            .catch(err => {
               console.log('Error uploading document', err);
               this.setState(prevState => {
                  const updatedState = prevState[`file${type}`].map((paper, idx) => {
                     if (idx !== index) return paper;
                     return { status: false, text: 'Try Again' };
                  });

                  // Return the updated state object
                  return { [`file${type}`]: updatedState };
               });
            });
      };      // end of fileReader.onloadend
   };

   addField = () => this.setState(prevState => ({
      filePaper: prevState.filePaper.concat({ status: false, text: 'Browse' })
   }));

   render() {
      const { handleSubmit } = this.props;
      const onKeyPress = event => {
         if (event.key === "Enter") {
            event.preventDefault();
         }
      };

      return (
         <Fragment>
            <p className="feature_form__head">
               Research Papers/ White Papers
                </p>
            <form
               onSubmit={handleSubmit(this.props.onSubmit)}
               onKeyPress={onKeyPress}
            >
               <div className="feature_form__inner-wrapper">
                  <FieldArray
                     name="research_papers"
                     section="research_papers"
                     type="Paper"
                     fileUpload={this.fileUpload}
                     addField={this.addField}
                     filePaper={this.state.filePaper}
                     component={renderPapers}
                     technologyAreas={this.props.technologyAreas}
                  />
               </div>
               <div className="feature_form__btn-wrapper">
                  <button
                     className="feature_form__btn feature_form__btn-back"
                     onClick={this.props.prevPage}
                  >
                     GO BACK
                        </button>
                  <button type="submit" className="feature_form__btn">
                     CONTINUE
                        </button>
               </div>
            </form>
         </Fragment>
      );
   }
}

const renderPapers = ({
   fields,
   fileUpload,
   filePaper,
   addField,
   section,
   type,
   // technologyAreas,
}) => (
      <div className="feature_form__papers">
         {fields.map((paper, index) => (
            <div key={index} className="form__row">
               <Field
                  name={`${paper}.title`}
                  component={FormInputField}
                  widthClass="form__field-wrapper--40"
                  label="Title"
                  tabable={true}
               />
               <Field
                  name={`${paper}.url`}
                  component={FormInputField}
                  widthClass="form__field-wrapper--60"
                  label="URL"
                  tabable={true}
               />
               {/* <TechAreaCapTag
                  technologyAreas={technologyAreas}
                  arrayName={`${paper}.technology_type`}
               /> */}
               <FieldArray
                  name={`${paper}.authors`}
                  component={renderPaperAuthors}
               />

               <div className="feature_form__upload-fields">
                  <div className="feature_form__upload-fields-text">
                     Upload Paper
                    </div>
                  <div className="feature_form__upload-fields-files">
                     <input
                        className="feature_form__upload-input"
                        id={`paper${index}`}
                        type="file"
                        accept="application/pdf"
                        index={index}
                        onChange={e => fileUpload(e, type, section, index)}
                     />
                     <label
                        className="feature_form__upload-label"
                        htmlFor={`paper${index}`}
                     >
                        <div className="feature_form__upload-label-icon-wrapper">
                           <div className="feature_form__upload-label-icon">
                              <i className="fa fa-file-text-o" />
                           </div>
                           {filePaper[index].status && (
                              <i className="fa fa-circle-o-notch fa-spin" />
                           )}
                        </div>
                        <div className="feature_form__upload-label-text">
                           {filePaper[index].text.substr(0, 22)}
                        </div>
                        <i className="fa fa-upload" />
                     </label>
                  </div>
               </div>
               {fields.length > 1 && (
                  <div className="feature_form__field-array-remove">
                     <span onClick={() => fields.remove(index)}>
                        - Remove Paper
                        </span>
                  </div>
               )}
               {fields.length > index + 1 && (
                  <div className="feature_form__saperator-img-wrapper">
                     <img src={saperatorImg} alt="Saperator" />
                  </div>
               )}
            </div>
         ))}

         <div className="feature_form__field-array-add">
            <span
               onClick={() => {
                  addField();
                  fields.push({ technology_type: [{}] });
               }}
            >
               + Add Another Paper
            </span>
         </div>
      </div>
   );

const renderPaperAuthors = ({ fields }) => (
   <Fragment>
      {fields.map((author, index) => (
         <Fragment key={index}>
            <div className="feature_form__papers-authors">
               <Field
                  name={`${author}.name`}
                  component={FormInputField}
                  widthClass="col-lg-6 feature_form__author-name"
                  label="Author Name"
                  tabable={true}
               />
               <Field
                  name={`${author}.url`}
                  component={FormInputField}
                  widthClass="col-lg-6 feature_form__author-url"
                  label="Author Website"
                  tabable={true}
               />
            </div>
            {fields.length > 1 && (
               <div
                  className="feature_form__field-array-remove"
                  style={{ textAlign: "right" }}
               >
                  <span onClick={() => fields.remove(index)}>
                     Remove Author
                        </span>
               </div>
            )}
         </Fragment>
      ))}
      <div
         className="feature_form__field-array-add"
         style={{ textAlign: "right", marginRight: "5px" }}
      >
         <span
            onClick={() => {
               fields.push({});
            }}
            style={{ display: "block" }}
         >
            + Add More Authors
            </span>
      </div>
   </Fragment>
);

const mapStateToProps = state => ({
   entityName: state.form.feature_yourself_old.values.name,
});

FormPart8 = connect(mapStateToProps)(FormPart8);

export default reduxForm({
   form: "feature_yourself_old", // <------ same form name
   destroyOnUnmount: false, // <------ preserve form data
   forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
   validate,
})(FormPart8);
