import React, { Component } from 'react';
import api from '../api';
import { toast } from "react-toastify";
import IdleTimeOutModal from '../modal/IdleTimeOutModal';
import IdleTimer from 'react-idle-timer';




export default class UserTrack extends Component {
    constructor(props) {
        super(props)

        this.state = {
            timeout: 1000 * 10 * 600,
            isTimedOut: false
        }

        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onIdle = this._onIdle.bind(this)
    }

    componentDidMount() {
        if (this.props.user) {
            this.liveUserStatusInterval = setInterval(this.trackUserStatus, 1000 * 5);

        }
    }

    componentWillUnmount() {
        this.liveUserStatusInterval && clearInterval(this.liveUserStatusInterval);
    }


    trackUserStatus = () => {
        if (!this.props.user) {
            return;
        }
        // let userDetails = localStorage.getItem('user')
        // console.log(this.props.user.user_track_id)
        const requestPostConfig = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                TCRAUTH: this.props.user.token
            },
            body: JSON.stringify({
                user_track_id: this.props.user.user_track_id,
                user_checked_in_time: this.props.user.user_checked_in_time,
                email: this.props.user.email
            })
        };

        fetch(api.activeInactiveUserStatus, requestPostConfig)

            .then(response => {
                if (!response || !response.ok) throw new Error('Status code: ' + response.status);
                return response.json();
            })
            .then(response => {
                // this.setState({ showModal: false })
                // if (response)
               // console.log(response.data, 'data')
                if (response.data['status']) {
                    if (this.state.isTimedOut) {
                        this.handleLogout()
                    }
                    else {
                        localStorage.setItem("reloading", "true");
                        localStorage.removeItem("user");
                        this.props.setUserInfo(null);
                        this.props.history.push('/')
                        this.liveUserStatusInterval && clearInterval(this.liveUserStatusInterval);
                        toast.success("You're Logged Out!");
                        window.location.reload();
                        


                    }
                }

            })
            .catch(err => {
                console.log('Error fetching notification records', err);
            })
    }



    handleLogout = () => {
        const requestPostConfig = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                TCRAUTH: this.props.user.token
            }
        };

        fetch(api.consumerLogout, requestPostConfig).then(response => {
            if (!response || !response.ok) throw new Error('Status code: ' + response.status);
            return response.json();
        }).then(response => {
            localStorage.removeItem("user");
            this.props.setUserInfo(null);
            this.props.history.push('/')
            this.liveUserStatusInterval && clearInterval(this.liveUserStatusInterval);
            window.location.reload();
            toast.success("You're Logged Out!");
        }).catch(err => {
            console.log('Error fetching notification records', err);
        })

    }

    _onAction(e) {
       // console.log('user doing something', e)
        this.setState({ isTimedOut: false })
    }


    _onIdle(e) {
        //console.log('user is idle', e)
        this.setState({ isTimedOut: true })

    }



    render() {
        if (!this.props.user) {
            return false
        }
        return (
            <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce={250}
                timeout={this.state.timeout} />
        )
    }
}
